import { render, staticRenderFns } from "./PrRewardProgram.vue?vue&type=template&id=fab83fc0&"
import script from "./PrRewardProgram.vue?vue&type=script&lang=js&"
export * from "./PrRewardProgram.vue?vue&type=script&lang=js&"
import style0 from "./PrRewardProgram.vue?vue&type=style&index=0&id=fab83fc0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports