<template>
    <div class="bg-layout time-keeping-content-container">
      <div class="header-switch-date">
          <div class="header-left-layout">
            <div class="d-flex-center">
              <div class="icon-switch-date" @click="prevMonth">
                <md-icon class="material-symbols-outlined">chevron_left</md-icon>
              </div>
              <div class="text-500-semibold">Tháng {{ currentDate.format('M') }} - {{ currentDate.format('YYYY') }}</div>
              <div class="icon-switch-date" @click="nextMonth">
                <md-icon class="material-symbols-outlined">chevron_right</md-icon>
              </div>
            </div>
            <div class="timekeeping-penalty" @click="showDetailTimekeeping">
              Chi tiết khấu trừ
            </div>
          </div>
          <div class="header-right-layout">
            <div class="md-layout md-gutter md-alignment-space-between-center">
              <div class="md-layout-item">
                <div class="layout-money income">
                  <div class="title-money">
                      <span>Ngày công tính lương</span>
                  </div>
                  <div class="total-money">
                      <span>{{finalWorkInMonth()}}</span>
                  </div>
                </div>
              </div>
              <div class="md-layout-item">
                <div class="layout-money income">
                  <div class="title-money">
                      <span>Tổng công</span>
                  </div>
                  <div class="total-money">
                      <span>{{ totalWorkInMonth() }}</span>
                  </div>
                </div>
              </div>
              <div class="md-layout-item">
                <div class="layout-money income">
                  <div class="title-money">
                    <span>Phạt công</span>
                  </div>
                  <div class="total-money">
                      <span>{{minusShiftInMonth()}}</span>
                  </div>
                </div>
              </div>
              <div class="md-layout-item">
                <div class="layout-money income">
                  <div class="title-money">
                    <span>Khấu trừ</span>
                  </div>
                  <div class="total-money">
                      <span>{{ minusMoneyInMonth() }}</span>
                  </div>
                </div>
              </div>
          </div>
          </div>
      </div>
      <!--  -->

      <div class="md-layout md-gutter md-layout-item md-size-100 md-alignment-space-between-space-between layout-index-container">
        <div class="md-layout-item">
        <div class="revenue-index__title">
          <span>Ngày công</span>
        </div>
        <div class="revenue-index__number paid">
          <span>{{ timekeep_total.work_day}}</span>
        </div>
      </div>
      <div class="md-layout-item">
        <div class="revenue-index__title">
          <span>Nửa công</span>
        </div>
        <div class="revenue-index__number paid">
          <span>{{ timekeep_total.half_day }}</span>
        </div>
      </div>
      <div class="md-layout-item paid">
        <div class="revenue-index__title">
          <span>Nghỉ phép</span>
        </div>
        <div class="revenue-index__number">
          <span>{{ timekeep_total.leave_accepted }}</span>
        </div>
      </div>
      <div class="md-layout-item">
        <div class="revenue-index__title">
          <span>Nghỉ không phép</span>
        </div>
        <div class="revenue-index__number">
          <span>{{ timekeep_total.leave_without_pay }}</span>
        </div>
      </div>
      <div class="md-layout-item paid">
        <div class="revenue-index__title">
          <span>Đi muộn</span>
        </div>
        <div class="revenue-index__number">
          <span>{{ timekeep_rule.late_total.count }}</span>
        </div>
      </div>
      <div class="md-layout-item paid">
        <div class="revenue-index__title">
          <span>Về sớm</span>
        </div>
        <div class="revenue-index__number">
          <span>{{ timekeep_rule.early_total.count }}</span>
        </div>
      </div>
      <div class="md-layout-item paid">
        <div class="revenue-index__title">
          <span>Quên chấm công</span>
        </div>
        <div class="revenue-index__number">
          <span>{{ timekeep_total.forgot_ckout}}</span>
        </div>
      </div>
      <div class="md-layout-item">
        <div class="revenue-index__title">
          <span>Phép tháng</span>
        </div>
        <div class="revenue-index__number paid">
          <span>{{ timekeep_total.monthly_leave_days }}</span>
        </div>
      </div>
      </div>

      <!--  -->
      <!-- <p>Số ngày muộn {{ timekeep_rule.late_30m }}</p>
      <p>tổng số ngày muộn bị phạt thêm {{ timekeep_rule.late_30m }}</p> -->
      <!-- <p>Số ngày về sớm {{ timekeep_rule.early }}</p>
      <p>Số tiền bị trừ về sớm {{ formatCurrency(timekeep_rule.early_penalty_amount) }}đ</p> -->
      <!--  -->
      <div class="md-layout md-gutter label-container">
          <div class="md-layout-item label-container_left">
              <div class="label-title day-full-work">
                  <span class="__box-icon"></span>
                  <span class="__title">Ngày đủ công</span>
              </div>
              <div class="label-title day-leave">
                  <span class="__box-icon"></span>
                  <span class="__title">Nghỉ không phép</span>
              </div>
              <div class="label-title half-day">
                  <span class="__box-icon"></span>
                  <span class="__title">Ngày nửa công</span>
              </div>
          </div>
          <div class="md-layout-item label-container_right">
              <div class="label-title">
                  <span class="__box-icon">
                      <img src="/img/svg/Check.png" alt="aza" />
                  </span>
                  <span class="__title">Ca đủ công</span>
              </div>
              <div class="label-title">
                  <span class="__box-icon">
                      <img src="/img/svg/leave&early.svg" alt="aza" />
                  </span>
                  <span class="__title">Ca đi sớm / về muộn</span>
              </div>
              <div class="label-title">
                  <span class="__box-icon">
                      <img src="/img/svg/shiff_off.svg" alt="aza" />
                  </span>
                  <span class="__title">Ca nghỉ</span>
              </div>
              <div class="label-title">
                  <span class="__box-icon">
                      <img src="/img/svg/umbrella.png" alt="aza" />
                  </span>
                  <span class="__title">Ca nghỉ lễ</span>
              </div>
              <div class="label-title">
                  <span class="__box-icon">
                      <img src="/img/svg/Check1.png" alt="aza" />
                  </span>
                  <span class="__title">Ca quên chấm công</span>
              </div>
          </div>
      </div>
      <!-- Calendar -->
      <div class="container-calendar">
          <div class="container-calendar-table">
              <div class="week-days">
                  <div v-for="day in weekDays" :key="day" class="day text-300-medium">{{ day }}</div>
              </div>
              <div class="calendar-grid">
                <div class="week" v-for="(week, weekIndex) in calendar" :key="weekIndex">
                  <div v-for="(day, dayIndex) in week"
                      :key="dayIndex"
                      :class="{ 'today': isToday(day.date), 'prev-month': day.prevMonth, 'next-month': day.nextMonth }" class="day">
                    <template v-if="day.is_sunday || day.date == null">
                      <div class="empty-calendar text-300-medium">
                                        <span>
                                            {{ day.dayLabel }}
                                        </span>
                      </div>
                    </template>
                    <template v-else>
                      <div class="event-calendar cursor-pointer" :class="day.class" @click="showDetail(day)">
                        <div class="event-calendar-header d-flex-center-between">
                          <div class="event-header_day">
                            <template v-if="day.s1.is_checkin_online || day.s2.is_checkin_online"><md-icon class="check-online">wifi</md-icon></template>
                            {{ day.date.format('DD / MM') }}
                          </div>
                          <div class="event-header_time">
                            <template v-if="day.daily_timesheet.checkin_online_time || day.daily_timesheet.checkout_online_time">
                              {{ day.daily_timesheet.checkin_online_time ? day.daily_timesheet.checkin_online_time : '--:--' }}
                                -
                              {{ day.daily_timesheet.checkout_online_time ? day.daily_timesheet.checkout_online_time : '--:--' }}
                            </template>
                            <template v-else>
                              {{ day.daily_timesheet.checkin_time ? day.daily_timesheet.checkin_time : '--:--' }}
                                -
                              {{ day.daily_timesheet.checkout_time ? day.daily_timesheet.checkout_time : '--:--' }}
                            </template>
                          </div>
                        </div>
                        <div class="event-calendar-body d-flex-center-between">
                          <div class="event-body_left">
                            {{ day.day_point }}
                          </div>
                          <div class="event-body_right">
                            <div class="left_time" v-if="day.s1.start_time && day.s1.end_time">
                              <img :src="day.s1.icon" alt="aza" />
                              <span>
                                  S: {{ day.s1.start_time + ' - ' + day.s1.end_time }}
                              </span>
                            </div>
                            <div class="right_time" v-if="day.s2.start_time && day.s2.end_time">
                              <img :src="day.s2.icon" alt="aza" />
                              <span>
                                  C: {{ day.s2.start_time + ' - ' + day.s2.end_time }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="event-calendar-footer">
                          <span>
                              {{ day.title }}
                          </span>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
          </div>
      </div>
      <ShowDetailTimeKeepingModal />
      <DetailWorkTimeModal />
    </div>
  </template>

  <script>
  import moment from 'moment';
  moment.locale('vi');
  import DashboardService from "@/store/services/DashboardService";
  import {helpers} from "../../../../helper/helpers";
  import ShowDetailTimeKeepingModal from "../../../Components/Modal/ShowDetailTimeKeepingModal"
  import DetailWorkTimeModal from "../../../Components/Modal/DetailWorkTimeModal";

  import {
    EARLY_COUNT_PENALTY,
    LATE_COUNT_PENALTY, LEAVE_INVALID_COUNT_PENALTY,
    MISS_CHECKIN_COUNT_PENALTY
  } from "./penalty"
  import {LEAVE_TYPE} from "../../../../const/const";

  const icons = {
    dot: '/img/svg/dot.png',
    leaveValid: '/img/svg/dot2.png',
    full: '/img/svg/Check.png',
    leaveEarly: '/img/svg/leave&early.svg',
    leaveInvalid: '/img/svg/shiff_off.svg',
    holiday: '/img/svg/umbrella.png',
    missCheckout: '/img/svg/Check1.png',
  };

  export default {
    name: 'time-keeping',
    data() {
      return {
        currentDate: moment(),
        calendar: [],
        employeeTimesheet : {
          holiday: [],
          user_leave: [],
          workdays: []
        },
        timesheetByUser: {},
        timekeep_total: {
          work_day: 0,
          late_early_day: 0,
          late: 0,
          early: 0,
          forgot_ckout: 0,
          forgot_ckin_out: 0,
          leave_accepted: 0,
          half_day: 0,
          leave_has_salary: 0,
          leave_without_pay: 0,
          total_holiday: 0,
          monthly_leave_days: 0
        },
        timekeep_rule: {
          late_total : { count: 0, shift: 0, money: 0, date : [] },
          late_30m : { count: 0, shift: 0, money: 0, date : [] },
          late_1h : { count: 0, shift: 0, money: 0, date : []},
          early_total : { count: 0, shift: 0, money: 0, date : [] },
          early_1h: { count: 0, shift: 0, money: 0, date : [] },
          miss_checkout : {count: 0, shift: 0, money: 0, date : [] },
          leave_invalid: { count: 0, pen_day: 0,  shift: 0, money: 0, date : [] },
        }
      };
    },
    components: {
      ShowDetailTimeKeepingModal,
      DetailWorkTimeModal
    },
    computed: {
      currentMonth() {
        return this.currentDate.format('MMMM YYYY');
      },
      weekDays() {
        return moment.weekdays(true);
      },
      firstDayOfMonth: function () {
        return firstDay.weekday();
      }
    },
    async created(){
      await this.getEmployeeTimesheet()
    },
    methods: {
      async prevMonth() {
        this.currentDate = this.currentDate.subtract(1, 'month');
        await this.getEmployeeTimesheet();
      },
      async nextMonth() {
        let maxMonth = moment().subtract(1, 'month');
        if (maxMonth > this.currentDate) {
          this.currentDate = this.currentDate.add(1, 'month');
          await this.getEmployeeTimesheet();
        }
      },
      isToday(date) {
        return moment().isSame(date, 'day');
      },
      selectDate(date) {
        // Handle date selection here
      },
      generateCalendar() {
        // reset
        this.clearTimeKeepingTotal();

        this.timekeep_total.monthly_leave_days = this.employeeTimesheet?.schedule?.monthly_leave_days ?? 0;

        const daysInMonth = this.currentDate.daysInMonth();
        const firstDay = moment(this.currentDate).date(1).day();

        const lastDayMonthPrev = parseInt(moment(this.currentDate).subtract(1,'months').endOf('month').format('DD'));
        const startMonthByDay = moment(this.currentDate).startOf('month').day() - 1;

        const maxDay = 31;
        let calendar = [];
        let day = 1;
        let dayNextMonth = 1;
        let dayMonthPrev = lastDayMonthPrev - startMonthByDay + 1;
        let currentDataDate = moment(this.employeeTimesheet?.schedule?.ts_function?.day_point);

        for (let i = 1; i < 6; i++) {
          const week = [];

          for (let j = 1; j < 8; j++) {
            let data = {
              day: '',
              dayLabel: '',
              date: null,
              prevMonth: day <= firstDay,
              nextMonth: day > daysInMonth,
              work_day: null,
              is_sunday: false,
              holiday: null,
              s1_leave_valid: false,
              s2_leave_valid: false,
              s1_leave_valuable_vacation: false,
              s2_leave_valuable_vacation: false,
              early_valid: false,
              no_data: false,
              is_checkonline: false
            };
            
            if (i === 1 && j < firstDay) {
              data.dayLabel = dayMonthPrev + '/' + moment(this.currentDate).subtract(1,'months').format('MM');
              dayMonthPrev++;
            } else if (day <= daysInMonth) {
              // console.log(this.employeeTimesheet);return;
              let currentDate = moment(this.currentDate)
                .date(day)
                .hour(0)
                .minute(0)
                .second(0)
                .millisecond(0);
              data.dayLabel = day <= maxDay ? currentDate.format('DD/MM') : '';
              this.timesheetByUser.collect_work.find((item) => {
                if(currentDate.format('YYYY-MM-DD') === item.date){
                  data = item;
                  item.date = moment(item.date);
                  data.class = this.setClassWork(item.day_type);
                  data.s1.icon = this.setIcon(item.s1.icon_type);
                  data.s2.icon = this.setIcon(item.s2.icon_type);
                }
              });
              day++;
            } else { // Tháng tiếp theo
              dayNextMonth = dayNextMonth < 10 ? '0' + dayNextMonth : dayNextMonth;
              data.dayLabel = `${dayNextMonth}/${moment(this.currentDate).add(1,'months').format('MM')}`;
              dayNextMonth++;
            }
            week.push(data);
          }
          calendar.push(week);
        }
        // this.sumUpPenalty();
        this.calendar = calendar;
      },

      calculatorCalendar(calendar){
        // console.log(calendar);
        if(calendar && (calendar.date == null || calendar.is_sunday)){
          return calendar;
        }

        let day_point = calendar.time_data.ts_shift_calendar.reduce(
          (accumulator, currentValue) => accumulator + currentValue.ts_shift_category.day_point,
          0,
        ) ?? 0;

        let title = '';
        let obj_opotion = {
          is_leave_afternoon: false,
          is_leave_morning: false,
        }
        let start_time_morning = moment(calendar.time_data.start_time_morning, 'HH:mm');
        let end_time_morning = moment(calendar.time_data.end_time_morning, 'HH:mm');

        let start_time_afternoon = moment(calendar.time_data.start_time_afternoon, 'HH:mm');
        let end_time_afternoon = moment(calendar.time_data.end_time_afternoon, 'HH:mm');

        let checkin_time = calendar.work_day?.checkin_time ? moment(calendar.work_day.checkin_time, 'HH:mm') : null;
        if (helpers.isEmpty(checkin_time)) checkin_time = null;
        let checkout_time = calendar.work_day?.checkout_time ? moment(calendar.work_day.checkout_time, 'HH:mm') : null;
        if (helpers.isEmpty(checkout_time)) checkout_time = null;
        if (!checkin_time && checkout_time) {
          checkin_time = checkout_time;
          checkout_time = null;
        }

        if(calendar.no_data){
          return {...calendar, ...{
              class: 'calendar-future',
              day_point: 0,
              icon: icons.dot
            }
          }
        }

        // xin nghỉ có công cả ngày
        if (calendar.s1_leave_valuable_vacation && calendar.s2_leave_valuable_vacation){
          this.timekeep_total.leave_has_salary++;
          return {
            ...calendar,
            ...{
              title: "Nghỉ có công",
              is_leave_valuable: true,
              class: "calendar-holiday",
              day_point: 1,
              icon: icons.full,
              ...obj_opotion
            }
          }
        }

        // không có ca làm việc được setup
        if(!calendar.time_data.has_time_day){
          return {...calendar, ...{
              class: 'calendar-holiday',
              day_point: 0,
              icon: icons.dot
            }
          }
        }

        // nghỉ lễ
        if(calendar.holiday){
          this.timekeep_total.total_holiday++;
          return {...calendar, ...{
              title: calendar.holiday.name,
              class: 'calendar-holiday',
              day_point: day_point,
              icon: icons.holiday
            }
          }
        }

        // xin nghỉ phép cả ngày
        if (calendar.s1_leave_valid && calendar.s2_leave_valid){
          this.timekeep_total.leave_accepted++;
          return {
            ...calendar,
            ...{
              title: "Nghỉ phép",
              class: "calendar-day-leave",
              day_point: 0,
              icon: icons.shiffOff,
              ...obj_opotion
            }
          }
        }

        // Không có log chấm công - nghỉ có phép hay không phép đều 0 công
        if(!checkin_time && !checkout_time) {
          this.calcWorkByTimeRule('leave_invalid', 1, calendar.day);
          return {
            ...calendar,
            ...{
              title: "Nghỉ không phép",
              class: "calendar-leave-without-pay",
              is_leave_invalid: true,
              day_point: 0,
              icon_morning: icons.leave,
              icon_afternoon: icons.leave
            }
          }
        }

        // Không có log checkout tính là quên chấm công - mặc định 1 công
        if(checkin_time && !checkout_time) {
          this.timekeep_total.forgot_ckout++;
          this.calcWorkByTimeRule('miss', 0, calendar.day);

          title = "Quên chấm công";
          let icon_morning = icons.missCheckout;
          let icon_afternoon = icons.missCheckout;

          let early = end_time_afternoon.diff(checkin_time, 'minutes');
          if(calendar.is_checkonline) {
            early = checkin_time.diff(end_time_afternoon, 'minutes'); // tính checkin muộn chiều so với cuối ca chiều
          }
          let late = checkin_time.diff(start_time_morning, 'minutes');
          if(calendar.is_checkonline) {
            late = checkin_time.diff(end_time_morning, 'minutes'); // tính checkin muộn sáng so với cuối ca sáng
          }

          // tính đi muộn hoặc về sớm theo giờ checkin
          if (checkin_time < start_time_afternoon && late > 0) {
            icon_morning = icons.leaveEarly;
            if (calendar.is_checkonline) {
              title += ` / Muộn sáng: ${late}p`;
            } else {
              title += ` / Muộn: ${late}p`;
            }
            this.calcWorkByTimeRule('late', late, calendar.day);
          } else if (checkin_time > end_time_morning && early > 0) {
            icon_afternoon = icons.leaveEarly;
            if (calendar.is_checkonline) {
              title += ` / Muộn chiều: ${early}p`;
              this.calcWorkByTimeRule('late', late, calendar.day);
            } else {
              title += ` / Về sớm: ${early}p`;
              this.calcWorkByTimeRule('early', early, calendar.day, true);
            }
          }
          return {
            ...calendar,
            ...{
              title: title,
              miss_checkout: true,
              late: late,
              early: early,
              class: 'calendar-miss-checkin',
              day_point: 1,
              icon_morning: icon_morning,
              icon_afternoon: icon_afternoon,
            }
          }
        }

        // Tính công
        if(checkin_time && checkout_time){
          title = "";

          obj_opotion.is_leave_morning = checkin_time > "12:00" || calendar.s1_leave_valid || calendar.s1_leave_valuable_vacation; // làm chiều, nghỉ sáng
          obj_opotion.is_leave_afternoon = checkout_time <=  "12:00" || calendar.s2_leave_valid || calendar.s2_leave_valuable_vacation; // làm sáng, nghỉ chiều

          // có check in-out là nghỉ sáng hoặc chiều mà không có đơn phép thì tính là Nghỉ không phép
          if (!calendar.s1_leave_valid && obj_opotion.is_leave_morning) {
            this.calcWorkByTimeRule('leave_invalid', 0.5, calendar.day);
            title = `Nghỉ không phép`;
            return {
              ...calendar,
              ...{
                title: title,
                class: "calendar-leave-without-pay",
                is_leave_invalid: true,
                day_point:  0,
                icon_morning: icons.leave,
                icon_afternoon: icons.full,
              }
            }
          }

          if (!calendar.s2_leave_valid && obj_opotion.is_leave_afternoon) {
            this.calcWorkByTimeRule('leave_invalid', 0.5, calendar.day);
            title = `Nghỉ không phép`;
            return {
              ...calendar,
              ...{
                title: title,
                class: "calendar-leave-without-pay",
                is_leave_invalid: true,
                day_point:  0,
                icon_morning: icons.full,
                icon_afternoon: icons.leave,
              }
            }
          }

          let time_cal_morn = obj_opotion.is_leave_morning ? start_time_afternoon : start_time_morning;   // chỉ làm chiều thì tính đầu ca chiều else đầu ca sáng
          let time_cal_aft = obj_opotion.is_leave_afternoon ? end_time_morning : end_time_afternoon;      // chỉ làm sáng thì tính cuối ca sáng else cuối ca chiều

          let late = checkin_time.diff(time_cal_morn, 'minutes');
          let early = time_cal_aft.diff(checkout_time, 'minutes');

          if(calendar.is_checkonline) {
            // checkin online đúng khung giờ thì k tính muộn sáng - else tính checkin muộn sáng theo cuối ca sáng
            if (checkin_time >= start_time_morning && checkin_time <= end_time_morning) late = 0;
            else late = checkin_time.diff(end_time_morning, 'minutes');

            // checkin online đúng khung giờ thì k tính muộn chiều - else tính checkin muộn chiều theo cuối ca chiều
            if (checkout_time >= start_time_afternoon && checkout_time <= end_time_afternoon) early = 0;
            else early = checkout_time.diff(end_time_afternoon, 'minutes');
          }

          let is_late_or_early = late > 0 || early > 0;

          if (late > 0) {
            if (calendar.is_checkonline) {
              title += `${title !== '' ? ` / ` : '' }Muộn sáng: ${late}p`;
            } else {
              title += `${title !== '' ? ` / ` : '' }Muộn: ${late}p`;
            }
            this.calcWorkByTimeRule('late', late, calendar.day);
          }

          if (early > 0) {
            if (calendar.is_checkonline) {
              title += `${title !== '' ? ` / ` : '' }Muộn chiều: ${early}p`;
              this.calcWorkByTimeRule('late', late, calendar.day);
            } else if (!calendar.early_valid && !obj_opotion.is_leave_afternoon) {
              title += `${title !== '' ? ` / ` : '' }Về sớm - KP`
              this.calcWorkByTimeRule('early', early, calendar.day, calendar.early_valid);
            } else {
              title += `${title !== '' ? ` / ` : '' }Về sớm: ${early}p`;
              this.calcWorkByTimeRule('early', early, calendar.day, calendar.early_valid);
            }
          }

          // TH: in sáng, out sáng
          if(obj_opotion.is_leave_afternoon && (calendar.s2_leave_valid || calendar.s2_leave_valuable_vacation)){
            this.timekeep_total.half_day++;
            this.timekeep_total.leave_accepted += 0.5;
            return {
              ...calendar,
              ...{
                title: title,
                class: 'calendar-half-day',
                late: late,
                early: early,
                day_point:  0.5,
                icon_morning: is_late_or_early ? icons.leaveEarly : icons.full,
                icon_afternoon: icons.shiffOff,
              }
            }
          }

          // TH: in chiều, out chiều
          if (obj_opotion.is_leave_morning && (calendar.s1_leave_valid || calendar.s1_leave_valuable_vacation)) {
            this.timekeep_total.half_day++;
            this.timekeep_total.leave_accepted += 0.5;
            return {
              ...calendar,
              ...{
                title: title,
                class: 'calendar-half-day',
                late: late,
                early: early,
                day_point:  0.5,
                icon_morning: icons.shiffOff,
                icon_afternoon: is_late_or_early ? icons.leaveEarly : icons.full,
              }
            }
          }

          // TH: in sáng, out chiều
          if(is_late_or_early){
            this.timekeep_total.late_early_day++;
            return {
              ...calendar,
              ...{
                title: title,
                class: 'calendar-early',
                day_point: day_point,
                icon_morning: calendar.s1_leave_valid ? icons.leaveHasSalary : (late > 0 ? icons.leaveEarly : icons.full),
                icon_afternoon: calendar.s2_leave_valid ? icons.leaveHasSalary : (early > 0 ? icons.leaveEarly : icons.full),
                ...obj_opotion
              }
            }
          }
        }
        this.timekeep_total.work_day++;
        return {
          ...calendar,
          ...{
            title: '',
            class: 'calendar-full-work',
            day_point: day_point,
            icon_morning: icons.full,
            icon_afternoon: icons.full,
            ...obj_opotion
          }
        }
      },

      getEmployeeTimesheet(month){
          DashboardService.employeeTimesheet({
            month: this.currentDate.format("YYYY/MM"),
          },
          this.$store.state.auth.user.id
        ).then(res => {
          this.employeeTimesheet = res;
          this.getTimesheetByUser();
        })
      },

      getTimesheetByUser(){
          DashboardService.getTimesheetByUser({
            month: this.currentDate.format("YYYY/MM"),
          },
          this.$store.state.auth.user.id
        ).then(res => {
          res = res.data;
          // this.calendar = res.collect_work;
          this.timesheetByUser = res;
          this.$nextTick(() => {
            this.timekeep_total = {
              work_day: res.final_work_data.total_workday,
              late_early_day: res.final_work_data.late_early_day,
              late: res.final_work_data.late_count,
              early: res.final_work_data.early_count,
              forgot_ckout: res.final_work_data.forgot_ckout,
              forgot_ckin_out: 0,
              leave_accepted: res.final_work_data.leave_accepted,
              half_day: res.final_work_data.half_day,
              leave_has_salary: res.final_work_data.leave_accepted,
              leave_without_pay: res.final_work_data.leave_without_pay,
              total_holiday: res.final_work_data.total_holiday,
              monthly_leave_days: res.final_work_data.monthly_leave_days
            }

            this.timekeep_rule = {
              late_total : { 
                count: res.final_work_data.late_count,
                shift: 0,
                money: 0,
                date : res.working_rule.late.days
              },
              late_30m : { count: res.final_work_data.late_30m, shift: 0, money: 0, date : res.working_rule.late['30m'] },
              late_1h : { count: res.final_work_data.late_60m, shift: 0, money: 0, date : res.working_rule.late['60m']},
              early_total : { count: res.final_work_data.early_count, shift: 0, money: 0, date : res.working_rule.late.days },
              early_1h: { count: res.final_work_data.early_60m, shift: 0, money: 0, date : res.working_rule.late['60m'] },
              miss_checkout : {count: res.working_rule.miss_checkout.count, shift: 0, money: 0, date : res.working_rule.days },
              leave_invalid: { count: res.working_rule.leave_invalid.count, pen_day: res.working_rule.leave_invalid.pen_day, shift: 0, money: 0, date : res.working_rule.leave_invalid.days },
            }
            this.sumUpPenalty();
          });

          this.generateCalendar();
        })
      },

      clearTimeKeepingTotal(){
        this.timekeep_total = {
          work_day: 0,
          late_early_day: 0,
          late: 0,
          early: 0,
          forgot_ckout: 0,
          forgot_ckin_out: 0,
          leave_accepted: 0,
          half_day: 0,
          leave_has_salary: 0,
          leave_without_pay: 0,
          total_holiday: 0
        };
        this.timekeep_rule = {
          late_total : { count: 0, shift: 0, money: 0, date : [] },
          late_30m : { count: 0, shift: 0, money: 0, date : [] },
          late_1h : { count: 0, shift: 0, money: 0, date : []},
          early_total : { count: 0, shift: 0, money: 0, date : [] },
          early_1h: { count: 0, shift: 0, money: 0, date : [] },
          leave_invalid: { count: 0, pen_day: 0, shift: 0, money: 0, date : [] },
          miss_checkout : {count: 0, shift: 0, money: 0, date : [] }
        };
      },

      calcWorkByTimeRule(key, value, day, isEarlyAccepted){
        // đi muộn
        if (key === 'late') {
          this.timekeep_total.late++;
          this.timekeep_rule.late_total.count++;
          this.timekeep_rule.late_total.date.push(day);
          // đi muộn từ 1 tiếng
          if (value > 60) {
            this.timekeep_rule.late_1h.count++;
            this.timekeep_rule.late_1h.shift += 1;
            this.timekeep_rule.late_1h.date.push(day);
          }

          // muộn sau 30p phạt nửa công
          if (value >= 30 && value < 60) {
            this.timekeep_rule.late_30m.count++;
            this.timekeep_rule.late_30m.shift += 0.5;
            this.timekeep_rule.late_30m.date.push(day);
          }
        }

        if(key === 'early'){
          this.timekeep_total.early++;
          this.timekeep_rule.early_total.count++;
          this.timekeep_rule.early_total.date.push(day);
          // về sớm hơn 60p
          if (!isEarlyAccepted || value > 60){
            this.timekeep_rule.early_1h.money += 100000;
            this.timekeep_rule.early_1h.count++;
            this.timekeep_rule.early_1h.date.push(day);
          }
        }

        if (key === 'miss') {
          this.timekeep_rule.miss_checkout.count++;
          this.timekeep_rule.miss_checkout.date.push(day);
        }

        if (key === 'leave_invalid') {
          this.timekeep_total.half_day++;
          this.timekeep_total.leave_without_pay++;
          this.timekeep_rule.leave_invalid.count ++;
          this.timekeep_rule.leave_invalid.pen_day += value;
          this.timekeep_rule.leave_invalid.date.push(day);
        }

      },

      sumUpPenalty() {
        this.timekeep_rule.early_total.shift = EARLY_COUNT_PENALTY[this.timekeep_rule.early_total.count][0];
        this.timekeep_rule.early_total.money = EARLY_COUNT_PENALTY[this.timekeep_rule.early_total.count][1];
        this.timekeep_rule.late_total.shift = LATE_COUNT_PENALTY[this.timekeep_rule.late_total.count][0];
        this.timekeep_rule.late_total.money = LATE_COUNT_PENALTY[this.timekeep_rule.late_total.count][1];
        this.timekeep_rule.miss_checkout.shift = MISS_CHECKIN_COUNT_PENALTY[this.timekeep_rule.miss_checkout.count][0];
        this.timekeep_rule.miss_checkout.money = MISS_CHECKIN_COUNT_PENALTY[this.timekeep_rule.miss_checkout.count][1];
        this.timekeep_rule.leave_invalid.shift = this.timekeep_rule.leave_invalid.pen_day * 2;
        this.timekeep_rule.leave_invalid.money = LEAVE_INVALID_COUNT_PENALTY[this.timekeep_rule.leave_invalid.count][1];

      },

      formatCurrency(amount){
        return helpers.formatCurrency(amount);
      },

      showDetailTimekeeping() {
        this.sumUpPenalty();
        this.$modal.show('show-detail-timekeeping-modal', {
          data: this.timekeep_rule
        });
      },

      finalWorkInMonth(){
        // return this.totalWorkInMonth() - this.minusShiftInMonth();
        return this.timesheetByUser?.final_work_data?.final_work_in_month ?? 0;
      },

      totalWorkInMonth(){
        return this.timesheetByUser?.final_work_data?.total_work ?? 0;
      },

      minusShiftInMonth(){
        return this.timesheetByUser?.final_work_data?.minus_in_month ?? 0;
      },

      minusMoneyInMonth(){
        return helpers.formatCurrency(this.timesheetByUser?.final_work_data?.money_penalty ?? 0);
      },

      showDetail(day){
        this.$modal.show('detail-worktime-modal', {
          data: day,
          user: this.$store.state.auth.user
        });
      },

      setClassWork(type){
        return {
          1: 'calendar-full-work',
          2: 'calendar-day-leave',//calendar-early',
          3: 'calendar-miss-checkin',
          4: 'calendar-miss-checkin',
          5: 'calendar-leave-without-pay',
          6: 'calendar-half-day',
          7: 'calendar-holiday',
        }[type] ?? '';
      },

      setIcon(type){
        return {
          1: icons.full,
          2: icons.leaveEarly,
          3: icons.missCheckout,
          4: icons.leaveInvalid,
          5: icons.leaveValid,
          6: icons.holiday,
        }[type] ?? '';
      }
    }
  };
  </script>
  
<style lang="scss" scoped>
.time-keeping-content-container {
  .header-switch-date {
    text-align: center;
    position: relative;
    width: 100%;
    padding: 15px 0px 10px;
    display: inline-block;
    .header-left-layout{
      float: left;
    }
    .header-right-layout{
      float: right;
      .md-layout{
        grid-gap: 10px;
        display: inline-flex;
      }
      .md-layout-item{
        height: 56px;
        padding: 6px 0;
        border-radius: 8px;
        border: 1px solid rgba(252, 175, 23, 1);
        background: #FFFDF2;
        width: 180px;
        .title-money{
          color: rgba(16, 24, 64, 1);
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: center;
        }
        .total-money{
          color: rgba(232, 0, 0, 1);
          font-weight: 500;
        }
      }
    }
    .icon-switch-date .md-icon {
      &:hover {
        cursor: pointer;
      }
      color: #1f1f1f;
      height: 28px;
      width: 28px;
    }
    .timekeeping-penalty{
        &:hover{
          cursor: pointer;
        }
        font-size: 12px;
        font-weight: 500;
        color: rgba(55, 107, 251, 1);
        text-decoration: underline;
      }
  }
  .layout-index-container {
    border: 0.5px solid #c1c1c1;
    border-radius: 8px;
    max-height: 60px;
    text-align: center;
    min-height: max-content;
    .md-layout-item {
      min-height: 100%;
      margin: 2px 5px;
      padding: 0 !important;
      position: relative;
      &:not(:last-child):after {
        position: absolute;
        content: "";
        width: 0.5px;
        height: 40px;
        background: #d8dae5;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
      }
      .revenue-index__title {
        color: rgba(16, 24, 64, 1);
        font-size: 13px;
      }
      .revenue-index__number {
        color: rgb(219, 71, 71);
        font-size: 16px;
        font-weight: 600;
      }
      .paid {
        color: #c140d5 !important;
      }
    }
  }

  //
  .label-container {
    margin-top: 20px;
    margin-bottom: 10px;
    .md-layout-item {
      display: flex;
      padding: 2px 15px;
      justify-content: start;
      align-items: center;
      gap: 10px;
    }
    .label-container_left {
      .__box-icon {
        width: 12px;
        height: 12px;
      }
      .day-full-work {
        /*background: rgba(46, 136, 220, 0.1);*/
        .__box-icon {
          background: rgb(46, 136, 220);
        }
      }
      .day-leave {
        /*background: rgba(243, 83, 83, 0.1);*/
        .__box-icon {
          background: rgba(243, 83, 83, 1);
        }
      }
      .half-day {
        /*background: rgba(145, 84, 170, 0.1);*/
        .__box-icon {
          background: rgba(145, 84, 170, 1);
        }
      }
    }
    .label-container_right {
        .label-title {
            padding: 0px 5px;
        }
        .__box-icon img {
            width: 14px;
            height: 14px;
        }
    }
    .label-title {
      width: max-content;
      display: flex;
      padding: 5px 10px;
      justify-content: center;
      align-items: center;
      gap: 5px;
      .__title {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .container-calendar {
    width: 100%;
    text-align: center;
    .calendar-day {
      border-bottom: 0.5px solid #d8dae5;
      background: rgba(223, 230, 255, 0.33);
      display: flex;
      width: 100%;
      height: 31px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-transform: uppercase;
    }
    .week-days {
      display: flex;
      justify-content: space-between;
      display: flex;
      width: 100%;
      height: 31px;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      grid-gap: 5px;
      margin-bottom: 8px;
      .day {
        flex: 1;
        text-align: center;
        border-bottom: 0.5px solid #d8dae5;
        background: rgba(223, 230, 255, 0.33);
      }
    }

    .calendar-grid {
      .empty-calendar {
        padding: 2px 5px;
      }
      .day {
        display: flex;
        width: 100%;
        align-items: start;
        padding: 0;
        min-height: 100px;
      }
      .event-calendar {
        width: 100%;
        height: 100%;
      }
      .event-calendar-header {
        padding: 0 6px;
        background: #9154aa;
        color: #fff;
        font-size: 12px;
        .event-header_day .md-icon {
          font-size: 14px !important;
          color: #8cff00;
          margin-right: 4px;
          width: auto;
          min-width: auto;
        }
      }
      .calendar-future {
        background: #f0f0f0;
        .event-calendar-header {
            background: #9c9c9c;
        }
        .event-body_right {
          border-left: 0.5px solid #d4d4d4 !important;
        }
        .event-calendar-footer {
          border-top: 0.5px solid #d4d4d4;
        }
        .event-body_left span {
          display: none;
        }
      }
      .calendar-day-leave {
        background: #f0f0f0;
        .event-calendar-header {
          background: #9c9c9c;
        }
      }
      .calendar-half-day {
         background: rgba(145, 84, 170, 0.05);
        .event-calendar-header {
            background: rgb(169, 94, 199);
        }
        .event-body_right {
          border-left: 0.5px solid rgba(145, 84, 170, 0.40) !important;
        }
        .event-calendar-footer {
          border-top: 0.5px solid rgba(145, 84, 170, 0.40);
        }
      }
      .calendar-holiday,
      .calendar-full-work {
        background: #ebf4fc;
        .event-calendar-header {
          background: rgb(46, 136, 220);
        }
      }
      .calendar-shift-times {
        background: #fff5eb;
        .event-calendar-header {
          background: #f69336;
        }
      }
      .calendar-leave-without-pay {
        background: rgb(243 83 83 / 5%);
        .event-calendar-header {
          background: rgb(243 83 83 / 100%);
        }
        .event-body_right {
          border-left: 0.5px solid rgb(243 83 83 / 40%) !important;
        }
        .event-calendar-footer {
          border-top: 0.5px solid rgb(243 83 83 / 40%);
        }
      }
      .calendar-day-leave {
        background: #f0f0f0;
        .event-calendar-header {
          background: #9c9c9c;
        }
      }
      .calendar-early,
      .calendar-late {
        background: rgba(246, 147, 54, 0.05);
        .event-body_right {
          border-left: 0.5px solid rgba(246, 147, 54, 0.4) !important;
        }
        .event-calendar-footer {
          border-top: 0.5px solid rgba(246, 147, 54, 0.4);
        }
        .event-calendar-header {
          background: rgb(46, 136, 220);
        }
      }

      .calendar-miss-checkin {
        background: rgb(255 0 0 / 5%);
        .event-body_right {
          border-left: 0.5px solid rgb(255 0 0 / 10%) !important;
        }
        .event-calendar-footer {
          border-top: 0.5px solid rgb(255 0 0 / 10%);
        }
        .event-calendar-header {
          background: rgb(46, 136, 220);
        }
      }

      .event-calendar-body {
        .event-body_left {
          display: flex;
          width: 45px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 17px;
          font-weight: 500;
        }
        .event-body_right {
          font-size: 11px;
          padding: 0 0 0 6px;
          width: 100%;
          text-align: start;
          border-left: 0.5px solid rgba(46, 136, 220, 0.4);
          min-height: 48px;
          .left_time, .right_time {
            display: flex;
            gap: 4px;
            align-items: center;
            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
      .event-calendar-footer {
        padding: 3px 5px;
        border-top: 0.5px solid rgba(46, 136, 220, 0.4);
        font-size: 11px;
        font-weight: 500;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .week {
      display: flex;
      justify-content: space-between;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 5px;
      margin: 5px 0;
    }

    .day {
      flex: 1;
      padding: 3px 5px;
      background-color: #f0f0f0;
      border: 1px solid #ccc;
      text-transform: uppercase;
    }

    // .today {
    //   background-color: #007BFF;
    //   color: #fff;
    // }

    // .today:hover {
    //   background-color: #0056b3;
    // }

  }
}
</style>
  