<template>
  <div class="md-layout text-center login-fix-page">
    <div class="md-layout-item md-size-100">
    </div>

    <div
        class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100 login"
    >
      <form @submit.prevent="login">
        <login-card header-color="white">
          <img slot="image-logo" src="https://azagroup.asia/images/logo2.svg" style="height: 80px">
          <md-field
              class="form-group md-invalid"
              slot="inputs"
              style="margin-bottom: 28px"
          >
            <md-icon>email</md-icon>
            <label>Tài khoản</label>
            <md-input v-model="username" type="text"/>
            <validation-error :errors="apiValidationErrors.username"/>
          </md-field>
          <md-field class="form-group md-invalid" slot="inputs">
            <md-icon>lock_outline</md-icon>
            <label>Mật khẩu</label>
            <md-input v-model="password" type="password"/>
            <validation-error :errors="apiValidationErrors.password"/>
          </md-field>
          <div slot="inputs">
            <div class="g-recaptcha mt-20px" data-sitekey="6LdkmHkmAAAAAHmzzpGhAWSuxienaBSKGNFFxDeK"></div>
          </div>
          <md-button
              slot="footer"
              type="submit"
              class="md-success login-button"
          >
            Đăng nhập
          </md-button>
        </login-card>
      </form>
    </div>
  </div>
</template>

<script>
import {LoginCard, ValidationError} from "@/components";
import formMixin from "@/mixins/form-mixin";
import {helpers} from "../../../helper/helpers";
import ProfileService from "../../../store/services/ProfileService";

export default {
  components: {
    LoginCard, ValidationError
  },

  mixins: [formMixin],

  mounted() {
    localStorage.setItem('router_name', this.$route.name);
    const captchaContainer = document.getElementById('captcha');
    // grecaptcha.render(captchaContainer, {
    //   sitekey: this.sitekey,
    //   // callback: this.onSubmitForm
    // });
  },

  data: () => ({
    username: "",
    password: "",
    sitekey: '6LdkmHkmAAAAAHmzzpGhAWSuxienaBSKGNFFxDeK'
  }),

  created() {
    const accessToken = helpers.getCookie("access_token");
    if (accessToken !== null && accessToken !== '') {
      this.$store.commit("isAuthenticated", {isAuthenticated: true});
      if (this.$store.state.auth.user === {}) {
        const userId = JSON.parse(helpers.getCookie("user_id"))
        if (userId == null) {
          this.$router.push({name: "Login"});
        } else {
          this.getUserProfile(userId);
        }
      }
      this.$router.push({name: "Dashboard"});
    }
  },

  methods: {
    async login() {
      const dataForm = {
        username: this.username,
        password: this.password
      };

      if (grecaptcha.getResponse().length === 0) {
        this.$store.dispatch("alerts/error", "Vui lòng xác thực reCAPTCHA trước khi gửi đăng nhập.");
      } else {
        const data = await this.$store.dispatch("login", dataForm);

        if (!data.status) {
          await this.$store.dispatch("alerts/error", data.message);
          this.setApiValidation(data.data);
        }
      }


    },

    async getUserProfile(userId) {
      await ProfileService.getProfile({user_id: userId}).then(res => {
        if (res) {
          this.$store.commit("updateUser", res.data);
        }
      });
    },

    async onSubmitForm() {
      // grecaptcha.ready(() => {
      //   grecaptcha.execute(this.sitekey, { action: 'submit' }).then((token) => {
      //     // Gửi token reCAPTCHA và dữ liệu form đi
      //     // token là giá trị bạn sẽ gửi kèm với dữ liệu form để xác thực
      //     // console.log(token);
      //   });
      // });
    }
  }
};
</script>
<style scoped lang="scss">
  .login-fix-page {
    padding-bottom: 7em;
    padding-top: 4em;
    .login-button {
      font-size: 15px;
      width: 100%;
      margin-bottom: 20px;
    }
  }
</style>