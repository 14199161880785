<template>
  <div
      class="sidebar sidebar-custom"
      :data-color="activeColor"
      :data-background-color="backgroundColor"
  >
    <!--left-content-->
    <div class="sidebar-wrapper sidebar-workplace">
      <md-list-item class="cursor-pointer workplace-icon"
                    v-bind:class="tab === 'workplace' ? 'icon-active' : ''"
                    v-on:click="changeMenu('workplace')">
        <i class="material-icons social-network background-no-repeat"></i>
      </md-list-item>
      <md-list-item class="cursor-pointer workplace-icon"
                    v-bind:class="tab === 'admin' ? 'icon-active' : ''"
                    v-on:click="changeMenu('admin')">
        <i class="material-icons analytics background-no-repeat"></i>
      </md-list-item>

      <md-image v-popover:foo.right class="avatar"></md-image>
      <popover name="foo">
        <md-list class="p-0px popover-avatar">
          <md-list-item v-on:click="logout()">
            <md-icon>logout</md-icon>
            <span class="md-list-item-text">Logout</span>
          </md-list-item>
        </md-list>
      </popover>
    </div>
  </div>
</template>
<script>
import GroupService from "@/store/services/GroupService";
import {GROUP_TYPE} from "../../../const/const";
export default {
  name: "sidebar",
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`
      };
    }
  },
  props: {
    activeColor: {
      type: String,
      default: "green",
    },
    backgroundImage: {
      type: String,
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: value => {
        let acceptedValues = ["", "black", "white", "red"];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.getGroups();
  },
  created() {
    this.$sidebar.toggleMinimize();
  },
  data() {
    return {
      tab: 'workplace',
      workGroups: [],
      branchGroups: [],
      companyWallGroups: [],
      pinnedGroups: [],
      groupImageDefault: '/img/group/group_image_low.jpg'
    }
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },

    changeMenu(type) {
      this.tab = type;
    },
    async logout() {
      await this.$store.dispatch("logout");
    },
    openDialog() {
      this.$modal.show('create-group-modal')
    },

    getGroups() {
      GroupService.getGroups().then(res => {
        if (res && res.data.length !== 0) {
          let groups = res.data;
          let workGroups = [];
          let branchGroups = [];
          let companyWallGroups = [];
          let pinnedGroups = [];
          for (let i = 0; i < groups.length; i++) {
            let group = groups[i];
            if (group.pinned) {
              pinnedGroups.push(group);
            }
            switch (group.type) {
              case GROUP_TYPE.WORK_GROUP:
                workGroups.push(group);
                break;
              case GROUP_TYPE.COMPANY_WALL_GROUP:
                companyWallGroups.push(group);
                break;
              case GROUP_TYPE.BRANCH_GROUP:
                branchGroups.push(group);
                break;
            }
          }
          this.pinnedGroups = pinnedGroups;
          this.branchGroups = branchGroups;
          this.workGroups = workGroups;
          this.companyWallGroups = companyWallGroups;
        }
      });
    }
  },

  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
  .title-sidebar{
    margin: 10px 0 23px;
  }
.subtitle-sidebar{
  margin: 22px 0 12px;
}
</style>
