import BaseService from "./BaseService";

export default class TimeSheetManagementService extends BaseService {
    static async createLeaveRequest(params = {}) {
        const res = await this.post(`/storeLeaveRequest`, params, 'TIMESHEET');
        return res.data;
    }

    static async getLeaves(params = {}) {
        const res = await this.get(`/leaves`, params, 'TIMESHEET');
        return res.data;
    }

    static async getLeaveDetail(params = {}) {
        const res = await this.get(`/leaves/${params.id}`, {}, 'TIMESHEET');
        return res.data;
    }

    static async approveLeaveRequest(params = {}) {
        const res = await this.post(`/leaves/approve-request`, params, 'TIMESHEET');
        return res.data;
    }

    static async getRoles(params = {}) {
        const res = await this.get(`/roles`, {}, 'ADMIN');
        return res.data;
    }
}

