<template>
  <div>

    <!-- count like + comment -->
    <div class="post-count">
      <md-card-actions md-alignment="space-between" >
        <span class="text-400-medium light_blue_400">{{ likeCount}} like</span>
        <span class="text-400-medium neutral_3">{{ commentCount }} lượt bình luận</span>
      </md-card-actions>
    </div>

    <!-- action like + comment -->
    <md-card-actions class="post-action">
      <md-bottom-bar class="list-action">
        <md-bottom-bar-item :class="{is_like: liked}" to="" md-label="Thích" md-icon="thumb_up"></md-bottom-bar-item>
        <md-bottom-bar-item to="" md-label="Bình luận" md-icon="chat"></md-bottom-bar-item>
<!--        <md-bottom-bar-item to="" md-label="Chia sẻ" md-icon="redo"></md-bottom-bar-item>-->
      </md-bottom-bar>
    </md-card-actions>

    <!-- user comment -->
    <md-list-item class="post-comment">
      <md-avatar>
        <img class="avatar" :src="userPost.avatar">
      </md-avatar>

      <div class="md-list-item-text">
        <md-field md-inline>
          <md-input class="comment" placeholder="Viết bình luận..."></md-input>
        </md-field>
      </div>
    </md-list-item>

    <!-- watch more comment -->
    <span class="text-400-medium secondary_b400 p-15px cursor-pointer" v-if="commentCount > 5">Xem thêm bình luận...</span>

    <!-- another comment -->
<!--    <template v-for="comment in comments">-->
      <md-list-item class="post-another-comment" v-for="(comment, index) in comments" :key="index">
        <md-avatar>
          <img :src="comment.user.avatar">
        </md-avatar>
        <div class="md-list-item-text">
          <span class="text-400-medium neutral_1">{{ comment.user.name }}</span>
          <p class="text-300-default white-space-normal" v-html="comment.content"></p>
          <p class="neutral_3 text-300-default">{{ fromNow(comment.created_at) }}</p>
        </div>
      </md-list-item>
<!--    </template>-->
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "post-footer",
  props: {
    userPost: {
      type: Object,
      default: () => {
      },
    },
    liked: {
      type: Boolean,
      default: false
    },
    likeCount: {
      type: Number,
      default: 0
    },
    commentCount: {
      type: Number,
      default: 0
    },
    comments: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
  },
  methods:{
    fromNow(date){
      return moment(date).locale("vi").fromNow();
    }
  }
};
</script>

<style lang="css"></style>
