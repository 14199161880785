<template>
  <div :style="styles">
    <Doughnut 
      :chart-data="chartData" 
      :chart-options="chartOptions" 
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey" 
      :css-classes="cssClasses"
      :width="width" 
      :height="height"
      class="chart-data"
     />
    </div>
  </template>
  
  <script>
  import { Doughnut } from 'vue-chartjs/legacy'

  export default {
    name: 'App',
    components: {
      Doughnut
    },
    props: {
      chartData:{
        type: Object,
        default: () => {}
      },
      chartOptions:{
          type: Object,
          default: () => {}
      },
      chartId: {
        type: String,
        default: 'doughnut-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 400
      },
      height: {
        type: Number,
        default: 250
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return{
      }
    }
  }
  </script>
  