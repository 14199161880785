<template>
  <div class="post-layout">
    <md-card v-for="(post, index) in posts" :key="index">
      <div class="post-header">
        <post-header :userPost=post.user :group=post.group></post-header>
      </div>

      <div class="post-content">
        <post-content :post=post.post></post-content>
      </div>

      <div class="post-footer">
        <post-footer :comments=post.comments
                     :userPost=post.user
                     :liked=post.post.liked
                     :likeCount=post.post.like_count
                     :commentCount=post.post.comment_count
        ></post-footer>
      </div>
    </md-card>
  </div>
</template>
<script>
import PostHeader from "../../Components/Post/postHeader";
import PostContent from "../../Components/Post/postContent";
import PostFooter from "../../Components/Post/postFooter";

export default {
  name: "post-layout",
  components: {PostFooter, PostContent, PostHeader},
  props: {
    posts: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
  }
};
</script>
