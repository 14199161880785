<template>
  <md-card-content>
    <text-content :content=Post.html_content></text-content>
    <image-content :mediaList=Post.medias></image-content>
    <file-content :content=Post.file_content></file-content>
  </md-card-content>
</template>

<script>
import TextContent from "./Content/TextContent";
import FileContent from "./Content/FileContent";
import ImageContent from "./Content/ImageContent";
export default {
  name: "post-content",
  components: {ImageContent, FileContent, TextContent},
  props: {
    Post: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="css"></style>
