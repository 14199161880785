export default {
  data() {
    return {
      lang: {
        formatLocale: {
            // MMMM
            months: ["Tháng Một", "Tháng Hai", "Tháng Ba", "Tháng Tư", "Tháng Năm", "Tháng Sáu", "Tháng Bảy", "Tháng Tám", "Tháng Chín", "Tháng Mười", "Tháng Mười Một", "Tháng Mười Hai"],
            // MMM
            monthsShort: ["Th.1", "Th.2", "Th.3", "Th.4", "Th.5", "Th.6", "Th.7", "Th.8", "Th.9", "Th.10", "Th.11", "Th.12"],
            // dddd
            weekdays: ["Thứ Hai", "Thứ Ba", "Thứ Tư", "Thứ Năm", "Thứ Sáu", "Thứ Bảy", "Chủ Nhật"],
            // ddd
            weekdaysShort: ["T2", "T3", "T4", "T5", "T6", "T7", "CN"],
            // dd
            weekdaysMin: ["T2", "T3", "T4", "T5", "T6", "T7", "CN"],
        }
      }
    };
  },
  methods: {
  }
};
