export const GROUP_TYPE = {
    //0: Nhóm làm việc - 1: Nhóm kín - 2: Nhóm bí mật - 3: Tường công ty - 4: Group
    WORK_GROUP: 0,
    PRIVATE_GROUP: 1,
    SECRET_GROUP: 2,
    COMPANY_WALL_GROUP: 3,
    BRANCH_GROUP: 4,
};
export const POST_TYPE = {
    TEXT: 'TEXT',                   //Bài viết chỉ có text
    IMAGE: 'IMAGE',                 //Bài viết có chứa 1 ảnh
    ALBUM: 'ALBUM',                 //Bài viết chứa nhiều ảnh
    SUB_ALBUM: 'SUB_ALBUM',         //Bài viết con cho từng ảnh trong bài viết ALBUM
    FILE: 'FILE',                   //Bài viết chứa file
    CREATE_GROUP: 'CREATE_GROUP',   //Bài viết khi tạo 1 group
};

export const LEAVE_REQUEST_STATUS = {
    APPROVED: 1,
    REJECT: 2,
    WAITING_APPROVE: 3,
};

export const LEAVE_TYPE = {
    LEAVE_APPLICATION: 1, // Đơn xin nghỉ
    LEAVE_EARLY: 2, // Xin về sớm
    ADJUSTMENT_OF_ATTENDANCE: 3, // Kháng cáo bảng công
    VALUABLE_VACATION: 4, // nghi co cong
};