import BaseService from "./BaseService";

export default class DashboardService extends BaseService {

  static async getTopRevenue(params = {}) {
    const res = await this.get(`/getTopMarketing`, params);
    return res.data;
  }

  static async getRevenueOverviewOfUser(params = {}) {
    const res = await this.get(`/getRevenueOverviewOfUser`, params);
    return res.data;
  }

  static async getRewardListOfUser(params = {}) {
    const res = await this.get(`/getRewardListOfUser`, params);
    return res.data;
  }

  static async getRoadMapPromotion(params = {}) {
    const res = await this.get(`/getUserPromotionList`, params);
    return res.data;
  }

  static async exportBanner(params = {}) {
    const res = await this.post(`/media/add-text-image`, params, 'AZAGROUP-BANNER');
    return res.data;
  }
  //
  static async getTimesheetByUser(params = {}, user_id) {
    const res = await this.get(`/timesheetByUser/${user_id}te`, params, 'TIMESHEET');
    return res.data;
  }

  static async employeeTimesheet(params = {}, user_id) {
    const res = await this.get(`/${user_id}`, params, 'TIMESHEET');
    return res.data;
  }

  static async getPrProgram(params = {}) {
    const res = await this.get(`/pr-program/list`, params);
    return res.data;
  }

  static async getCompanyRewardList(params = {}) {
    const res = await this.get('/getCompanyRewardUser', params);
    return res.data;
  }

}

