import BaseService from "./BaseService";

export default class PostService extends BaseService {

  static async getPosts(params = {}) {
    const res = await this.get(`/posts`, params);
    return res.data;
  }

  // static async getPost(params = {}) {
  //   const res = await this.get(`/login`, params);
  //   return res;
  // }
  //
  static async createPost(params = {}) {
    const res = await this.post(`/posts`, params);
    return res.data;
  }
  //
  // static async updatePost(params = {}) {
  //   const res = await this.get(`/login`, params);
  //   return res.data;
  // }

  static async getWorkProcess(params = {}) {
    const res = await this.get(`/work-process`, params);
    return res.data;
  }


}

