<template>
    <div class="bg-layout md-layout md-gutter personal-layout-container">
       <div class="personal-information">
            <div class="avatar-personal">
                <template v-if="profileUser && profileUser.avatar">
                    <img :src="profileUser.avatar" alt="aza">
                </template>
            </div>
            <div class="infomation">
                <div class="infomation-name">
                    <div class="name">
                        <p>{{ profileUser.fullname }}</p>
                    </div>
                    <div class="subname">
                        <span>{{ profileUser.username}}</span>
                    </div>
                </div>
                <div class="work-infomation">
                    <div class="work-box" v-if="profileUser && profileUser.role && profileUser.role.display_name">
                        <div class="work-position">
                            Vị trí / Chức vụ
                        </div>
                        <div class="work-detail">
                            {{  profileUser.role.display_name }}
                        </div>
                    </div>
                    <div class="work-box">
                        <div class="work-position">
                            Chi nhánh
                        </div>
                        <div class="work-detail">
                            {{ profileUser && profileUser.team && profileUser.team.agency ? profileUser.team.agency  : '------' }}
                        </div>
                    </div>
                    <div class="work-box" v-if="profileUser && profileUser.team && profileUser.team.department">
                        <div class="work-position">
                            Phòng
                        </div>
                        <div class="work-detail">
                            {{profileUser.team.department}}
                        </div>
                    </div>
                    <div class="work-box" v-if="profileUser.team && profileUser.team.team">
                        <div class="work-position">
                            Team
                        </div>
                        <div class="work-detail">
                            {{ profileUser.team.team }}
                        </div>
                    </div>
                </div>
                <div class="work-infomation-salary">
                    <div class="work-box">
                        <div class="work-position">
                        Lương hiện tại
                        </div>
                        <div class="work-detail cursor-pointer">
                            <template v-if="show_salary.includes(1)">
                                <div class="money-salary">
                                    {{ formatCurrency(profileUser.real_salary, true) }}
                                </div>
                            </template>
                            <template v-else>
                                <div class="icon-to-see">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <g clip-path="url(#clip0_4666_13520)">
                                        <path d="M14.8168 1.18241C14.6668 1.03243 14.4633 0.948181 14.2512 0.948181C14.0391 0.948181 13.8356 1.03243 13.6856 1.18241L1.184 13.6856C1.10759 13.7594 1.04665 13.8477 1.00472 13.9453C0.962792 14.0429 0.940723 14.1479 0.9398 14.2541C0.938877 14.3603 0.959118 14.4657 0.999343 14.564C1.03957 14.6623 1.09897 14.7516 1.17408 14.8267C1.2492 14.9018 1.33852 14.9612 1.43684 15.0015C1.53515 15.0417 1.6405 15.0619 1.74672 15.061C1.85294 15.0601 1.95792 15.038 2.05552 14.9961C2.15313 14.9542 2.2414 14.8932 2.3152 14.8168L14.816 2.31361C14.966 2.16359 15.0502 1.96014 15.0502 1.74801C15.0502 1.53588 14.9668 1.33243 14.8168 1.18241ZM2.4864 10.7984L4.5344 8.75041C4.476 8.5045 4.44568 8.25276 4.444 8.00001C4.444 6.09681 6.036 4.55281 8 4.55281C8.2288 4.55281 8.4512 4.57841 8.668 4.61841L9.6304 3.65681C9.0915 3.56753 8.54625 3.52205 8 3.52081C2.752 3.52001 0 7.38481 0 8.00001C0 8.33841 0.8456 9.67281 2.4864 10.7984ZM13.516 5.20401L11.4664 7.25201C11.5216 7.49361 11.5552 7.74241 11.5552 8.00001C11.5552 9.90321 9.964 11.4456 8 11.4456C7.7728 11.4456 7.552 11.42 7.3368 11.3808L6.3736 12.3432C6.8872 12.4264 7.4264 12.4792 8 12.4792C13.248 12.4792 16 8.61281 16 8.00001C16 7.66081 15.1552 6.32801 13.516 5.20401Z" fill="#376BFB"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4666_13520">
                                        <rect width="16" height="16" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                </div>
                                <div class="click-to-see" @click="toggleElement(1)">
                                    Click để xem
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="work-box">
                        <div class="work-position">
                            Lương cơ bản
                        </div>
                        <div class="work-detail cursor-pointer">
                        <template v-if="show_salary.includes(2)">
                            <div class="money-salary">
                                {{ formatCurrency(profileUser.insurancy_salary, true) }}
                            </div>
                        </template>
                        <template v-else>
                                <div class="icon-to-see">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <g clip-path="url(#clip0_4666_13520)">
                                        <path d="M14.8168 1.18241C14.6668 1.03243 14.4633 0.948181 14.2512 0.948181C14.0391 0.948181 13.8356 1.03243 13.6856 1.18241L1.184 13.6856C1.10759 13.7594 1.04665 13.8477 1.00472 13.9453C0.962792 14.0429 0.940723 14.1479 0.9398 14.2541C0.938877 14.3603 0.959118 14.4657 0.999343 14.564C1.03957 14.6623 1.09897 14.7516 1.17408 14.8267C1.2492 14.9018 1.33852 14.9612 1.43684 15.0015C1.53515 15.0417 1.6405 15.0619 1.74672 15.061C1.85294 15.0601 1.95792 15.038 2.05552 14.9961C2.15313 14.9542 2.2414 14.8932 2.3152 14.8168L14.816 2.31361C14.966 2.16359 15.0502 1.96014 15.0502 1.74801C15.0502 1.53588 14.9668 1.33243 14.8168 1.18241ZM2.4864 10.7984L4.5344 8.75041C4.476 8.5045 4.44568 8.25276 4.444 8.00001C4.444 6.09681 6.036 4.55281 8 4.55281C8.2288 4.55281 8.4512 4.57841 8.668 4.61841L9.6304 3.65681C9.0915 3.56753 8.54625 3.52205 8 3.52081C2.752 3.52001 0 7.38481 0 8.00001C0 8.33841 0.8456 9.67281 2.4864 10.7984ZM13.516 5.20401L11.4664 7.25201C11.5216 7.49361 11.5552 7.74241 11.5552 8.00001C11.5552 9.90321 9.964 11.4456 8 11.4456C7.7728 11.4456 7.552 11.42 7.3368 11.3808L6.3736 12.3432C6.8872 12.4264 7.4264 12.4792 8 12.4792C13.248 12.4792 16 8.61281 16 8.00001C16 7.66081 15.1552 6.32801 13.516 5.20401Z" fill="#376BFB"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4666_13520">
                                        <rect width="16" height="16" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                </div>
                                <div class="click-to-see" @click="toggleElement(2)">
                                    Click để xem
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="work-infomation">
                    <div class="work-box">
                        <div class="work-position">
                            Ngày bắt đầu làm việc
                        </div>
                        <div class="work-detail">
                            {{ first_working_day ? first_working_day : "------" }}
                        </div>
                    </div>
                    <!-- <div class="work-box">
                        <div class="work-position">
                            Ngày chính thức
                        </div>
                        <div class="work-detail">
                            {{ profileUser.first_working_day }}
                        </div>
                    </div> -->
                    <!-- <div class="work-box">
                        <div class="work-position">
                            Khu vực / Bộ phận
                        </div>
                        <div class="work-detail">
                            {{ profileUser && profileUser.team && profileUser.team.department ? profileUser.team.department : '------' }}
                        </div>
                    </div> -->
                </div>
            </div>
       </div>
       <!-- LỊCH SỬ PHÁT TRIỂN SỰ NGHIỆP -->
       <div class="history-container">
            <div class="history-title">
                LỊCH SỬ PHÁT TRIỂN SỰ NGHIỆP
            </div>
            <div class="history-content md-layout">
                <div class="history-chart md-layout-item md-size-60">
                <LineChartData 
                    :chartData="lineChart.chartData" 
                    :chartOptions="lineChart.chartOptions"
                    :styles="{height: '300px'}"
                />
            </div>
            <div class="history-infomation md-layout-item md-size-40">
                <div class="work-box">
                        <div class="work-position">
                        Lương khi tuyển
                        </div>
                        <template v-if="show_salary.includes(3)">
                            <div class="money-salary">
                                {{ formatCurrency(roadMapPromotion[roadMapPromotion.length - 1].new_salary, true) }}
                            </div>
                        </template>
                        <template v-else>
                            <div class="work-detail cursor-pointer">
                                <div class="icon-to-see">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <g clip-path="url(#clip0_4666_13520)">
                                        <path d="M14.8168 1.18241C14.6668 1.03243 14.4633 0.948181 14.2512 0.948181C14.0391 0.948181 13.8356 1.03243 13.6856 1.18241L1.184 13.6856C1.10759 13.7594 1.04665 13.8477 1.00472 13.9453C0.962792 14.0429 0.940723 14.1479 0.9398 14.2541C0.938877 14.3603 0.959118 14.4657 0.999343 14.564C1.03957 14.6623 1.09897 14.7516 1.17408 14.8267C1.2492 14.9018 1.33852 14.9612 1.43684 15.0015C1.53515 15.0417 1.6405 15.0619 1.74672 15.061C1.85294 15.0601 1.95792 15.038 2.05552 14.9961C2.15313 14.9542 2.2414 14.8932 2.3152 14.8168L14.816 2.31361C14.966 2.16359 15.0502 1.96014 15.0502 1.74801C15.0502 1.53588 14.9668 1.33243 14.8168 1.18241ZM2.4864 10.7984L4.5344 8.75041C4.476 8.5045 4.44568 8.25276 4.444 8.00001C4.444 6.09681 6.036 4.55281 8 4.55281C8.2288 4.55281 8.4512 4.57841 8.668 4.61841L9.6304 3.65681C9.0915 3.56753 8.54625 3.52205 8 3.52081C2.752 3.52001 0 7.38481 0 8.00001C0 8.33841 0.8456 9.67281 2.4864 10.7984ZM13.516 5.20401L11.4664 7.25201C11.5216 7.49361 11.5552 7.74241 11.5552 8.00001C11.5552 9.90321 9.964 11.4456 8 11.4456C7.7728 11.4456 7.552 11.42 7.3368 11.3808L6.3736 12.3432C6.8872 12.4264 7.4264 12.4792 8 12.4792C13.248 12.4792 16 8.61281 16 8.00001C16 7.66081 15.1552 6.32801 13.516 5.20401Z" fill="#376BFB"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4666_13520">
                                        <rect width="16" height="16" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                </div>
                                <div class="click-to-see" @click="toggleElement(3)">
                                    Click để xem
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="work-box work-box-salary">
                        <div class="work-position">
                            Lương hiện tại
                        </div>
                        
                            <div class="work-detail cursor-pointer">
                                <div class="percert-box d-flex-center-between">
                                        <template v-if="show_salary.includes(4)">
                                <div class="money-salary">
                                    {{ formatCurrency(roadMapPromotion[0].new_salary, true) }}
                                </div>
                            </template>
                                        <template v-else>
                                            <div class="icon-to-see">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <g clip-path="url(#clip0_4666_13520)">
                                                <path d="M14.8168 1.18241C14.6668 1.03243 14.4633 0.948181 14.2512 0.948181C14.0391 0.948181 13.8356 1.03243 13.6856 1.18241L1.184 13.6856C1.10759 13.7594 1.04665 13.8477 1.00472 13.9453C0.962792 14.0429 0.940723 14.1479 0.9398 14.2541C0.938877 14.3603 0.959118 14.4657 0.999343 14.564C1.03957 14.6623 1.09897 14.7516 1.17408 14.8267C1.2492 14.9018 1.33852 14.9612 1.43684 15.0015C1.53515 15.0417 1.6405 15.0619 1.74672 15.061C1.85294 15.0601 1.95792 15.038 2.05552 14.9961C2.15313 14.9542 2.2414 14.8932 2.3152 14.8168L14.816 2.31361C14.966 2.16359 15.0502 1.96014 15.0502 1.74801C15.0502 1.53588 14.9668 1.33243 14.8168 1.18241ZM2.4864 10.7984L4.5344 8.75041C4.476 8.5045 4.44568 8.25276 4.444 8.00001C4.444 6.09681 6.036 4.55281 8 4.55281C8.2288 4.55281 8.4512 4.57841 8.668 4.61841L9.6304 3.65681C9.0915 3.56753 8.54625 3.52205 8 3.52081C2.752 3.52001 0 7.38481 0 8.00001C0 8.33841 0.8456 9.67281 2.4864 10.7984ZM13.516 5.20401L11.4664 7.25201C11.5216 7.49361 11.5552 7.74241 11.5552 8.00001C11.5552 9.90321 9.964 11.4456 8 11.4456C7.7728 11.4456 7.552 11.42 7.3368 11.3808L6.3736 12.3432C6.8872 12.4264 7.4264 12.4792 8 12.4792C13.248 12.4792 16 8.61281 16 8.00001C16 7.66081 15.1552 6.32801 13.516 5.20401Z" fill="#376BFB"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_4666_13520">
                                                <rect width="16" height="16" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        </div>
                                        <div class="click-to-see" @click="toggleElement(4)">
                                            Click để xem
                                        </div>
                                        </template>
                                    </div>
                                <div class="percent-detail">
                                    <div class="compare-percent">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M9.05864 6.07503L3.06364 12.9263C2.35614 13.735 2.93114 15 4.00489 15H15.9949C16.2352 15.0002 16.4705 14.9312 16.6725 14.8011C16.8746 14.671 17.0349 14.4855 17.1342 14.2667C17.2335 14.0478 17.2677 13.805 17.2325 13.5673C17.1974 13.3296 17.0945 13.107 16.9361 12.9263L10.9411 6.07628C10.8238 5.94202 10.6791 5.83441 10.5168 5.76068C10.3544 5.68695 10.1782 5.6488 9.99989 5.6488C9.82159 5.6488 9.64535 5.68695 9.48301 5.76068C9.32066 5.83441 9.17597 5.94202 9.05864 6.07628V6.07503Z" fill="#4FA00B"/>
                                        </svg>
                                        <span class="up-percent">{{ percent_compare }}%</span>
                                    </div>
                                    <div class="compare-percent-desc">
                                        (so với lương khởi điểm)
                                    </div>
                                </div>
                            </div>
                    </div>
                <div class="work-box">
                    <div class="work-position">
                        Ngày làm việc
                    </div>
                    <div class="work-detail">
                        <!-- 1 năm 5 tháng 23 ngày -->
                        {{ formatDateDifference() }}
                    </div>
                </div>
                <div class="work-box">
                    <div class="work-position">
                        Phát triển sự nghiệp
                    </div>
                    <div class="work-detail">
                        {{ roadMapPromotion.length > 0 ? roadMapPromotion.length + ' bản ghi' : 'Chưa có dữ liệu' }} 
                    </div>
                </div>
            </div>
            </div>
        </div>
        <!-- Time - Position -->
        <div class="time-position-work-container" v-if="roadMapPromotion.length > 0">
            <div class="time-position-header md-layout">
                <div class="md-layout-item md-layout left-container md-size-50">
                    <div class="header-title-time">
                        Thời gian
                    </div>
                    <div class="header-title-position">
                        Vị trí
                    </div>
                </div>
                <div class="md-layout-item md-layout right-container md-size-50">
                    <div class="header-title-policy">
                        Chính sách
                    </div>
                    <div class="header-title-salary">
                        Lương
                    </div>
                    <div class="header-title-status">
                        Trạng thái
                    </div>
                </div>
            </div>
            <div class="position-content">
                <div class="md-layout position-history" v-for="(item, index) in roadMapPromotion" :key="index">
                    <div class="md-layout-item md-layout left-container">
                        <div class="position-content-title-time">
                            {{ item.apply_date }}
                        </div>
                        <div class="position-content-title-position">
                            <div class="position">
                                <span class="apppoint">{{ item.hrm_policy.hrm_policy_category.name }}</span>
                                <span class="position-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2189 6.969C15.2885 6.89915 15.3713 6.84374 15.4624 6.80593C15.5535 6.76812 15.6512 6.74866 15.7499 6.74866C15.8485 6.74866 15.9462 6.76812 16.0373 6.80593C16.1284 6.84374 16.2112 6.89915 16.2809 6.969L20.7809 11.469C20.8507 11.5387 20.9061 11.6214 20.9439 11.7125C20.9818 11.8037 21.0012 11.9013 21.0012 12C21.0012 12.0986 20.9818 12.1963 20.9439 12.2874C20.9061 12.3786 20.8507 12.4613 20.7809 12.531L16.2809 17.031C16.14 17.1718 15.949 17.2509 15.7499 17.2509C15.5507 17.2509 15.3597 17.1718 15.2189 17.031C15.078 16.8902 14.9989 16.6992 14.9989 16.5C14.9989 16.3008 15.078 16.1098 15.2189 15.969L19.1894 12L15.2189 8.031C15.149 7.96133 15.0936 7.87857 15.0558 7.78745C15.018 7.69633 14.9985 7.59865 14.9985 7.5C14.9985 7.40135 15.018 7.30366 15.0558 7.21255C15.0936 7.12143 15.149 7.03867 15.2189 6.969Z" fill="#101840"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 12C3 11.8011 3.07902 11.6103 3.21967 11.4697C3.36032 11.329 3.55109 11.25 3.75 11.25H19.5C19.6989 11.25 19.8897 11.329 20.0303 11.4697C20.171 11.6103 20.25 11.8011 20.25 12C20.25 12.1989 20.171 12.3897 20.0303 12.5303C19.8897 12.671 19.6989 12.75 19.5 12.75H3.75C3.55109 12.75 3.36032 12.671 3.21967 12.5303C3.07902 12.3897 3 12.1989 3 12Z" fill="#101840"/>
                                    </svg>
                                </span>
                                <span class="position-new">{{ item.role.display_name }}</span>
                            </div>
                            <div class="room-position">
                                {{ item.team.agency }}
                                <span v-if="item.team.department">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                        <path d="M9.105 6.56474L4.9935 10.1617C4.509 10.5862 3.75 10.2412 3.75 9.59699V2.40299C3.74988 2.2588 3.79132 2.11764 3.86936 1.9964C3.9474 1.87517 4.05873 1.77899 4.19002 1.7194C4.32131 1.65981 4.467 1.63932 4.60963 1.6604C4.75227 1.68148 4.8858 1.74322 4.99425 1.83824L9.10425 5.43524C9.18481 5.50563 9.24937 5.59245 9.29361 5.68986C9.33785 5.78726 9.36073 5.89301 9.36073 5.99999C9.36073 6.10697 9.33785 6.21271 9.29361 6.31012C9.24937 6.40753 9.18481 6.49434 9.10425 6.56474H9.105Z" fill="#101840"/>
                                    </svg>
                                </span>
                                {{ item.team.department }}
                                <span v-if="item.team.team">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                        <path d="M9.105 6.56474L4.9935 10.1617C4.509 10.5862 3.75 10.2412 3.75 9.59699V2.40299C3.74988 2.2588 3.79132 2.11764 3.86936 1.9964C3.9474 1.87517 4.05873 1.77899 4.19002 1.7194C4.32131 1.65981 4.467 1.63932 4.60963 1.6604C4.75227 1.68148 4.8858 1.74322 4.99425 1.83824L9.10425 5.43524C9.18481 5.50563 9.24937 5.59245 9.29361 5.68986C9.33785 5.78726 9.36073 5.89301 9.36073 5.99999C9.36073 6.10697 9.33785 6.21271 9.29361 6.31012C9.24937 6.40753 9.18481 6.49434 9.10425 6.56474H9.105Z" fill="#101840"/>
                                    </svg>
                                </span>
                                {{ item.team.team }}
                            </div>
                        </div>
                    </div>
                    <div class="md-layout-item md-layout right-container md-size-50">
                        <div class="position-content-title-policy">
                            <div>
                                {{ item.hrm_policy.name }}
                            </div>
                        </div>
                        <div class="position-content-title-salary">
                            <template v-if="show_salary.includes('A' + index)">
                                <div class="money-salary"> 
                                    {{ formatCurrency(item.new_salary, true) }}
                                </div>
                            </template>
                            <template v-else>
                                <div class="salary" @click="toggleElement('A' + index)">Click để xem</div>
                            </template>
                        </div>
                        <div class="position-content-title-status">
                            <div class="status-time">
                                <div class="status_active">{{ index === 0 ? 'Hiện tại' : 'Chính thức' }}</div>
                                <div class="time-active">
                                    {{ getTimeAgo(item.apply_date) }} ngày trước
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
   import LineChartData from "./Chart/LineChartData"
   import DashboardService from "@/store/services/DashboardService";
   import EmployeeService from "@/store/services/EmployeeService";
   import {helpers} from "../../../helper/helpers";
   import moment from "moment";

  export default {
    components: {
        LineChartData
    },
    data() {
      return {
        lineChart: {
          chartData: {
            labels: [],
            datasets: [
              {
                label: 'Tổng',
                data: [],
                data_role_name: [],
                fill: false,
                borderColor: '#165BAA',
                backgroundColor: '#FF7B00',
                tooltip: false
              }
            ]
          },
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                display: false, 
              },
            //   title: {
            //     display: true,
            //     text: (ctx) => {
            //     //   const {axis = 'xy', intersect, mode} = ctx.chart.options.interaction;
            //     return '123123';
            //     }
            // },
              tooltip: {
                callbacks: {
                    // footer: (tooltipItems) => {
                    //     return '123123';
                    // },
                    label: ((tooltipItems, data) => {
                        return 'Lương: ' + this.formatCurrency(tooltipItems.dataset.data[tooltipItems.dataIndex]);
                    }),
                }
            },
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        display: false
                    },
                    grid: {
                        display: true
                    }
                }
            }
          },
        },
        show_salary : [],
        roadMapPromotion: [],
        profileUser: {},
        percent_compare: 0,
        first_working_day: ''
      };
    },
    created(){
        this.getProfileUser();
        this.getRoadMapPromotion();
    },
    methods: {
        toggleElement(position){
            if(this.show_salary.indexOf(position) === -1) {
                this.show_salary.push(position);
            }
        },
        getRoadMapPromotion(){
            DashboardService.getRoadMapPromotion({}).then(res => {
                if (res.data) {
                    res.data.sort((a, b) => {
                        const date1 = new Date(a.apply_date);
                        const date2 = new Date(b.apply_date);

                         const millis1 = Date.parse(date1);
                        const millis2 = Date.parse(date2);

                        if (millis1 < millis2) {
                            return -1;
                        } else if (millis2 > millis1) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }).reverse();

                    let percent = ((res.data[0].new_salary - res.data[res.data.length - 1].new_salary) / res.data[res.data.length - 1].new_salary) * 100;
                    this.percent_compare = percent < 0 ? 0 : percent.toFixed(2);
                    this.roadMapPromotion = res.data;

                    let chart = [...res.data].reverse();
                    for (let index = 0; index < chart.length; index++) {
                        const element = chart[index];
                        this.lineChart.chartData.labels.push(moment(element.apply_date, 'DD-MM-YYYY').format('DD/MM'));
                        this.lineChart.chartData.datasets[0].data.push(element.new_salary);
                        this.lineChart.chartData.datasets[0].data_role_name.push(element.role.display_name);
                    }

                    this.first_working_day = chart[0].apply_date;
                }
            });
        },
        getProfileUser(){
            const params= {
                start_date: '2023-01-25',
                end_date: '2023-01-25',
                revenue_type: 1, //1 - DSUT, 2 - DSTT
                good_ids: [1,2,3],
                country: [''],
                marketing_platform: [''],
            }
            EmployeeService.getProfileUser({}).then(res => {
                if (res.data) {
                    if(res.data.avatar){
                        res.data.avatar.replace("https://api.azasystem.shop", "https://azagroup.asia/");
                    }
                    
                    this.profileUser = res.data;
                }
            });
        },
        formatCurrency(currency, useStyle = false){
            return helpers.formatCurrency(currency, useStyle);
        },
        formatDateDifference(){
            if(this.roadMapPromotion.length){
                const startDate = moment(this.roadMapPromotion[this.roadMapPromotion.length-1].apply_date, 'DD-MM-YYYY');
                const endDate = moment();
                return helpers.formatDateDifference(startDate, endDate);
            }
            return '------';
        },
        getTimeAgo(date){
            return moment().diff(moment(date, 'DD-MM-YYYY'), 'days');
        },
        changeLinkAvatar(url){
            return url.replace("https://api.azasystem.shop", "https://azagroup.asia/");
        }
    }
  }
  </script>
  
  <style lang="scss">
  .personal-layout-container{
    margin: 0 !important;
    // padding: 20px;
    grid-gap: 20px;
    height: 100%;
    .avatar-personal {
        width: 250px;
        height: 250px;
        display: flex;
        align-items: center;
        img{
            border-radius: 2px;
        }
    }
    .infomation {
        width: 100%;
        .infomation-name{
            width: 100%;
            border-bottom: 0.5px solid #D8DAE5;
            padding-bottom: 20px;
        }
    }
    .personal-information {
        display: flex;
        grid-gap: 30px;
        width: 100%;
        .name{
            color: #404446;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 116.667% */
            letter-spacing: -0.06px;
            p{
                margin-bottom: 5px;
            }
        }
        .subname{
            color: #6C7072;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
        }

       
        .work-infomation-salary{
            grid-template-columns: repeat(2, 1fr);
        }
        .work-infomation, .work-infomation-salary{
            margin-top: 10px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 10px;
            margin-bottom: 20px;
            .work-box{
                width: max-content;
                .work-position{
                    color: #52576F;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 12px;
                }
                .work-detail{
                    color: #101840;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: -0.05px;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    grid-gap: 10px;
                    .icon-to-see {
                        height: 16px;
                    }
                    .click-to-see{
                        color: #376BFB;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: -0.05px;
                    }
                }
            }
        }
    }
    .history-container{
              width: 100%;
              margin-bottom: 20px;
              .history-chart{
                padding: 0
              }
              .history-title{
                  color: #000;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  letter-spacing: -0.05px;
                  margin-bottom: 20px;
              }
              .history-infomation{
                  display: flex;
                  padding: 10px 15px;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 20px;
                  flex-shrink: 0;
                  background: #FAFAFA;
                  height: max-content;
                  .work-box{
                        width: 100%;
                      margin-bottom: 15px;
                      .work-position{
                          color: #52576F;
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 12px;
                      }
                      .work-detail{
                          color: #101840;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: 20px;
                          letter-spacing: -0.05px;
                          margin-top: 5px;
                          display: flex;
                          align-items: center;
                          grid-gap: 10px;
                          .icon-to-see {
                              height: 16px;
                          }
                          .click-to-see{
                              color: #376BFB;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 500;
                              line-height: 20px;
                              letter-spacing: -0.05px;
                          }
                      }
                  }
                  .work-box-salary{
                    .work-detail{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .percert-box{
                            grid-gap: 10px;
                        }
                    }
                    .percent-detail{
                        text-align: right;
                        .compare-percent{
                            display: flex;
                            justify-content: end;
                            .up-percent{
                                color: #4FA00B;
                            }
                        }
                        .compare-percent-desc{
                            color: #8A8EA0;
                            font-size: 12px;
                        }
                    }
                    
                  }
              }
      }
      
    .time-position-work-container{
        width: 100%;
        .md-layout-item{
            padding: 0;
        }
        .time-position-header{
            color: #376BFB;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.05px;
            padding: 12px 10px;
            text-transform: uppercase;
            .left-container {
                grid-gap: 50px;
            }
            .right-container{
                // grid-gap: 80px;
                justify-content: space-between;
                // .header-title-policy{
                //     max-width: 180px;
                // }
                .header-title-salary{
                    padding-left: 50px;
                }
            }
        }
        .position-content{
            .position-history{
                display: flex;
                padding: 8px 10px;
                justify-content: center;
                align-items: center;
                align-content: center;
                gap: 0px 20px;
                align-self: stretch;
                flex-wrap: wrap;
                border-top: 0.5px solid #D8DAE5;
                .left-container{
                    grid-gap: 40px;
                }
                .right-container{
                    justify-content: space-between;
                    //grid-gap: 75px;
                    color: #101840;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: -0.05px;
                    align-items: center; 
                    .position-content-title-policy{
                        width: 180px;
                    }
                    .position-content-title-salary{
                        color: #52576F;
                        padding: 0 10px;
                        &:hover{
                            cursor: pointer;
                        }
                        .money-salary{
                            color: #101840;
                            font-size: 14px;
                        }
                    }
                    .position-content-title-status{
                        .status-time{
                            text-align: center;
                            max-width: 100px;
                            word-wrap: break-word;
                            .status_active{
                                color: #5CA222;
                            }
                            .time-active{
                                color: #52576F;
                                font-size: 12px;
                            }
                        }
                    }
                    
                }
                .position-content-title-time{
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: -0.05px;
                    display: flex;
                    align-items: center;
                }
                .position-content-title-position{
                    max-width: 350px;
                    .position{
                        color: #101840;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: -0.05px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        grid-gap: 10px;
                        width: max-content;
                        .position-icon{
                            width: 24px;
                            height: 24px;
                        }
                    }
                    .position-new{
                        color: #5CA222;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: -0.05px;
                    }
                    .room-position{
                        color: #101840;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: -0.05px
                    }
                }
            }
        }
    }
    // 
    .money-salary{
        color: #FF7B00;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.05px;
    }
  }
  @media screen and (max-height: 600px) {
        .personal-layout-container {
            height: auto;
        }
    }
  </style>