<template>
  <div class="post-layout create-post">
    <md-card class="post-footer">
      <md-list-item class="post-comment user-post">
        <md-avatar>
          <img class="avatar" :src="user.avatar">
        </md-avatar>
        <div class="md-list-item-text">
          <md-field>
            <md-input class="comment" placeholder="Đăng bài viết"></md-input>
          </md-field>
        </div>
      </md-list-item>
      <span class="hr"></span>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-list-item class="p-10px cursor-pointer">
            <div class="item-image">
              <img src="img/post/create/ic_picture.png">
            </div>
            <span class="item-text text-400-medium">Ảnh/Video</span>
          </md-list-item>
        </div>
        <div class="md-layout-item">
          <md-list-item class="p-0px cursor-pointer">
            <div class="item-image">
              <img src="img/post/create/ic_noti.png">
            </div>
            <span class="item-text text-400-medium">Thông báo</span>
          </md-list-item>
        </div>
        <div class="md-layout-item">
          <md-list-item class="p-0px cursor-pointer">
            <div class="item-image">
              <img src="img/post/create/ic_report.png">
            </div>
            <span class="md-list-item-text item-text text-400-medium">Đăng báo cáo</span>
          </md-list-item>
        </div>
      </div>
    </md-card>
  </div>
</template>

<script>

export default {
  name: "create-post",
  data() {
    return {
      user: this.$store.state.auth.user,
    };
  },
};
</script>

<style lang="css">
.hr{
  border-bottom: 1px solid #EDEFF5;
  margin: 0px 15px;
}
.md-list-item-content {
  justify-content: center;
}
.md-layout-item .item-image{
    width: 28px;
    height: 28px;
    margin: 6px 10px 6px 0;
  }
.md-layout-item .item-text {
  flex: none;
  position: initial;
  color: #65676b;
  width: auto;
}
</style>
