<template>
  <div
    class="wrapper"
    :class="[
      { 'nav-open': $sidebar.showSidebar },
    ]"
  >
    <notifications></notifications>
    <!-- <side-bar
      :active-color="sidebarBackground"
      :data-background-color="sidebarBackgroundColor"
    >
    </side-bar> -->
    <div class="main-panel2">
      <Header></Header>
      <div :class="{ content: !$route.meta.hideContent }" :style="customStyle">
        <!-- <div class="md-layout md-gutter"> -->
          <zoom-center-transition :duration="200" mode="out-in">
            <!-- your content here -->
            <!-- <Dashboard></Dashboard> -->
            <router-view />
          </zoom-center-transition>
        <!-- </div> -->
      </div>
    </div>
    <!-- <create-group-modal></create-group-modal> -->
    <!-- <select-group-modal></select-group-modal> -->
    <v-dialog/>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from "perfect-scrollbar";
  import "perfect-scrollbar/css/perfect-scrollbar.css";



  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
      document.getElementsByClassName(className)[0].scrollTop = 0;
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  function reinitScrollbar() {
    let docClasses = document.body.classList;
    let isWindows = navigator.platform.startsWith("Win");
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar("sidebar");
      initScrollbar("sidebar-wrapper");
      initScrollbar("main-panel");

      docClasses.add("perfect-scrollbar-on");
    } else {
      docClasses.add("perfect-scrollbar-off");
    }
  }

  import Header from "./Header.vue";
  // import CreateGroupModal from "../Components/Modal/CreateGroupModal";
  // import SelectGroupModal from "@/pages/Components/Modal/SelectGroupModal";
  // import Dashboard from "../Workplace/Pages/Dashboard/Dashboard.vue";
  // import ContentFooter from "./ContentFooter.vue";
  // import MobileMenu from "./Extra/MobileMenu.vue";
  // import UserMenu from "./Extra/UserMenu.vue";

  export default {
    components: {
      // SelectGroupModal,
      Header,
      // CreateGroupModal,
      // Dashboard
      // ContentFooter,
      // FixedPlugin,
      // MobileMenu,
      // UserMenu,
    },
    computed: {
      customStyle() {
        let style = {};
        if (this.$route.meta.content_width) {
          style.maxWidth = this.$route.meta.content_width;
        }
        return style;
      }
    },
    data() {
      return {
        sidebarBackgroundColor: "white",
        sidebarBackground: "orange",
        // sidebarBackgroundImage:
        //   process.env.VUE_APP_BASE_URL + "/img/sidebar-2.jpg",
        sidebarMini: true,
        sidebarImg: false,
        image: process.env.VUE_APP_BASE_URL + "/img/laravel-vue.svg",
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      minimizeSidebar() {
        if (this.$sidebar) {
          this.$sidebar.toggleMinimize();
        }
      },
      logout() {
        this.$store.dispatch("logout");
      }
    },
    updated() {
      reinitScrollbar();
    },
    mounted() {
      reinitScrollbar();
    },
    watch: {
      sidebarMini() {
        this.minimizeSidebar();
      },
    },
  };
</script>
<style lang="scss">
.content{
  // margin: 35px 0 10px !important;
  padding: 20px;
  max-width: 1300px;
  margin: auto;
}
.main-panel2{
  font-family: Inter;
}
  $scaleSize: 0.95;
  @keyframes zoomIn95 {
    from {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    to {
      opacity: 1;
    }
  }
  .main-panel .zoomIn {
    animation-name: zoomIn95;
  }
  @keyframes zoomOut95 {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
  }
  .main-panel .zoomOut {
    animation-name: zoomOut95;
  }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
