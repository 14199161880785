<template>
    <div class="md-layout md-gutter statistical-layout">
        <div class="statistical-header" v-if="titleTable">
            <div class="statistical-title">
                <span>{{ titleTable }}</span>
            </div>
            <!-- <div class="statistical-see-detail" v-if="seeMore.title && seeMore.link">
                <a :href="seeMore.link">{{ seeMore.title }} <md-icon>chevron_right</md-icon></a>
            </div> -->
        </div>
        <ve-table border-y
            row-key-field-name="rowKey"
            :columns="columns"
            :table-data="tableData"
            :event-custom-option="eventCustomOption"
            :sort-option="sortOption"
            :cell-style-option="cellStyleOption"
            :checkbox-option="checkboxOption"
            :cell-span-option="cellSpanOption"
        />
    </div>
  </template>
  
  <script>
  export default {
    name: "StatisticalTable",
    components: {},
    props: {
    titleTable: {
        type: String,
        default: () => ''
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    eventCustomOption: {
      type: Object,
      default: () => {},
    },
    sortOption: {
      type: Object,
      default: () => {},
    },
    cellStyleOption: {
      type: Object,
      default: () => {},
    },
    checkboxOption: {
      type: Object,
      default: () => {},
    },
    cellSpanOption: {
      type: Object,
      default: () => {},
    }
  },
    data() {
      return {
      };
    },
    created(){
    },
    methods: {}
  }
  </script>
  
  <style lang="scss">
  .statistical-layout {
    padding: 0 !important;
    .statistical-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .statistical-title {
            font-weight: 600;
            text-transform: uppercase;
            color: rgba(16, 24, 64, 1);
        }
        .statistical-see-detail {
            a{
                color: rgba(23, 101, 252, 1) !important;
            font-size: 14px;
            }
            .md-icon{
                color: rgba(23, 101, 252, 1) !important;
                font-size: 18px !important;
            }
        }
    }
    .vue-table-root{
        width: 100%;
    }
    .total-reward{
        .reward-blue{
            color: rgba(36, 0, 255, 1);
            font-weight: 600;
        }
        .reward-red{
            color: rgba(255, 79, 79, 1);
            font-weight: 600;
            text-decoration: line-through;
        }
    }
}
  </style>