<template>
  <div class="righ-bar">
    <div class="company-info-wrapper background_neutral_white">
      <div class="company-info">
        <div class="company-icon">
          <img :src="headquarters.logo" alt="">
        </div>
        <div class="company-name heading-600 primary_y500">
          {{headquarters.name}}
        </div>
        <div class="company-address text-300-default" style="color: #9E8EE0">
          {{headquarters.address}}
        </div>
      </div>
      <div class="branch-list">
        <a class="branch-item nav-link" v-for="branch in branchList" :key="branch">
          <div class="branch-img-wrapper">
            <img :src="branch.logo ? branch.logo : groupImageDefault" alt="">
          </div>
          <div class="branch-info">
            <div class="name secondary_b400 text-400-medium">
              {{branch.name}}
            </div>
            <div class="text-300-default address neutral_2">
              {{branch.address}}
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="core-value-wrapper background_neutral_white">
      <div class="core-value-title text-400-medium">
        GIÁ TRỊ CỐT LÕI - AZA GROUP
      </div>
      <div class="core-value-item" v-for="(item, index) in coreValues" :key="index">
        <div class="cardinal-number">
          <div class="text-400-medium neutral_white">{{index + 1}}</div>
        </div>
        <div class="value text-400-medium">{{item.value}}</div>
        <div class="more-icon">
          <li class="fa fa-angle-right"></li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingService from "@/store/services/SettingService";

export default {
  name: "company-info",
  data() {
    return {
      headquarters: {},
      branchList: [],
      coreValues: [],
      groupImageDefault: '/img/group/group_image_low.jpg'
    }
  },

  mounted() {
    this.getCompanyInfo();
  },

  methods: {
    getCompanyInfo() {
      SettingService.getCompanyInfo().then(res => {
        if (res) {
          this.headquarters = res.company.headquarters;
          this.branchList = res.company.branch;
          this.coreValues = res.core_values;
        }
      });
    }
  }
};
</script>

<style lang="scss">

</style>
