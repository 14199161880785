import axios from 'axios';
// import Api from '../config/Api';
import Cookies from 'js-cookie';
const domain = process.env.VUE_APP_API_BASE_URL_WORK + '/v1';
const domainLogin = process.env.VUE_APP_API_BASE_URL + '/v1';
const domainTimeSheet = process.env.VUE_APP_API_TIMESHEET_URL + '/v1';
const domainTimeSheetManagement = process.env.VUE_APP_API_TIMESHEET_MANAGEMENT_URL + '/v1';

export default class BaseService {
    /*constructor() {
        this.setAuth();
    }

    setAuth() {
        axios.interceptors.request.use(function (config) {
            console.log('axios.interceptors');
            const access_token = localStorage.getItem("access_token");
            const XsrfToken = localStorage.getItem("X_XSRF_TOKEN");

            if (access_token) {
                config.headers.Authorization = `Bearer ${access_token}`;
                config.headers.X_XSRF_TOKEN = XsrfToken;
            }

            return config;
        });
    }*/

    static async get(uri, params = {}, domain = 'AZAWORK') {
        try {
            return await axios.get(this.getUrl(uri, domain), {params: params});
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async post(uri, params = {}, domain = 'AZAWORK') {
        try {
            return await axios.post(this.getUrl(uri, domain), params);
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async put(uri, params = {}) {
        try {
            return await axios.put(this.getUrl(uri, domain), params);
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async patch(uri, params = {}) {
        try {
            return await axios.patch(this.getUrl(uri, domain), params);
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async show(uri) {
        try {
            return await axios.get(this.getUrl(uri, domain));
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static async delete(uri) {
        try {
            return await axios.delete(this.getUrl(uri, domain));
        } catch (e) {
            return this.errorMsg(e);
        }
    }

    static getUrl(uri, type) {
        let domainUrl = domain + uri;
        switch (type) {
            case 'AZAGROUP':
                domainUrl = domainLogin + uri;
                break;
            case 'ADMIN':
                domainUrl = domain.replace("/aza-work", "/admin") + uri;
                break;
            case 'AZAGROUP-BANNER':
                domainUrl = domainLogin.replace("/v1", "") + uri;
                break;
            case 'TIMESHEET':
                domainUrl = domainTimeSheet + uri;
                break;
            case 'TIMESHEET-MANAGEMENT':
                domainUrl = domainTimeSheetManagement + uri;
                break;
            case 'MEDIA':
                domainUrl = domain.replace('/aza-work/v1', '/media') + uri;
                break;
        }

        return domainUrl;
    }

    static errorMsg(e) {
        if(e.message === 'Network Error'){
            window.app.$store.dispatch("alerts/error", "Đã có lỗi xảy ra. Vui long thử lại!");
            return {data: {status: false}}
        }
        if (!e.response && Cookies.get('access_token') && Cookies.get('user_id')) {
            throw e;
        }

   
        if((e.response.status === 422 && window.location.pathname !== '/login')){
            window.app.$store.dispatch("alerts/error", "Phiên làm việc đã hết hạn. Chuyển đến màn hình đăng nhập");
            setTimeout(() => {
                window.app.$store.dispatch("logout");
            }, 5000);
            return;
        }
        if (e.response.status === 401) {
            window.app.$store.dispatch("logout");
            return;
        } else if(e.response.status === 500){
            window.app.$store.dispatch("alerts/error", "Đã có lỗi xảy ra. Vui long thử lại!");
        }
        return {data: e.response.data};
    }
}
