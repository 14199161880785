<template>
  <div class="md-layout">
    <template  v-if="loading">
      <div class="loader"></div>
    </template>
    <template v-else>
      <div class="md-layout-item" style="padding: 0 25px 0 10px">
        <create-post></create-post>
        <post-layout :posts=posts></post-layout>
      </div>
      <div class="" style="width: 312px; float: right">
        <CompanyInfo>

        </CompanyInfo>
      </div>
    </template>
  </div>
</template>
<script>

import PostLayout from "../../Layout/WorkplaceLayout/PostLayout";
import PostService from "../../../store/services/PostService";
import { mapState } from "vuex";
import CreatePost from "../../Components/Post/UserCreatePost/createPost";
import CompanyInfo from "../../Components/RightBar/CompanyInfo";

export default {
  components: {
    CreatePost,
    PostLayout,
    CompanyInfo
  },
  mounted() {
    this.getPosts();
  },

  data() {
    return {
      posts: [],
      loading: true,
    };
  },

  methods: {
   async getPosts() {
    await PostService.getPosts().then(res => {
       this.loading = false;
       if (res) {
         this.posts = res.data;
       }
     });
   },
  }
};
</script>
<style lang="css">
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #232d34; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
