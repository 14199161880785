<template>
  <div>
    <modal name="member-list-modal"
           :click-to-close="false"
           width="620px"
           height="auto"
           class="create-group-modal modal-custom member-list-modal"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
    >

      <md-card class="modal-header-custom">
        <div class="modal-header-title">
          <span class="neutral_1 heading-500 text-header-modal">Danh sách thành viên</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('member-list-modal')">close</span>
        </div>
      </md-card>

      <div style="width: 100%; height: auto">
        <div>
          <div class="input-auto-complete">
            <input class="input-search-field" type="text" placeholder="Tìm kiếm" name="search-group" @keyup="searchUser()" v-model="search">
          </div>

          <div class="content my-15px mx-5px p-0px">
            <div v-for="member in memberList" :key="member.id">
                <md-list-item class="cursor-pointer chooseMember user-detail" @click="filterByUser(member)">
                  <md-avatar>
                    <img class="avatar" :src="member.avatar ? member.avatar : '/img/profile/avatar.png'">
                  </md-avatar>
                  <div class="md-list-item-text">
                    <div class="text-400-medium mb-10px fullname">{{ member.fullname }}</div>
                    <div class="detail-info" v-if="member.agency">
                      <span class="material-icons">schema</span>
                      <span class="text-2-line text-info-name">
                        <span>{{ member.agency.name }}</span>
                        <span class="ml-5px text-300-default" v-if="member.team">></span>
                        <span class="ml-5px text-300-default" v-if="member.team">{{ member.team.name }}</span>
                    </span>
                    </div>
                    <div class="detail-info" v-else>
                      <span class="material-icons">schema</span>
                      <span class="text-2-line text-info-name">AZA GROUP</span>
                    </div>
                    <div class="detail-info" v-if="member.position">
                      <span class="material-icons">account_box</span>
                      <span class="text-info-name">{{ member.position.name }}</span>
                    </div>
                    <div class="detail-info">
                      <span class="material-icons">email</span>
                      <span class="text-info-name">{{ member.email }}</span>
                    </div>
                  </div>
                </md-list-item>
              </div>
          </div>
        </div>
      </div>

    </modal>
  </div>
</template>

<script>

import EmployeeService from "../../../store/services/EmployeeService";

export default {
  name: 'member-list-modal',
  computed: {},
  mounted() {
    this.getEmployees(this.member_params);
  },
  watch: {
    items(newValue, oldValue) {
      this.listItems = this.memberListFilter.filter(item => newValue.includes(item.id));
    },
  },
  data() {
    return {
      tabActive: 1,
      member_params: {
        page: 1,
        per_page: 20,
        keyword: "",
        status: 1
      },
      member_last_page: 1,
      memberList: [],
      memberListFilter: [],
      items: [],
      listItems: [],
      search: ''
    };
  },
  methods: {
    beforeOpened(event) {
    },

    beforeClosed(event) {
    },

    async getEmployees(params = {}) {
      params.page = this.member_params.page;
      await EmployeeService.getEmployees(params).then(res => {
        if (res && res.data) {
          this.memberList = res.data
          this.memberListFilter = this.memberListFilter.concat(this.memberList);
          const unique = [...new Map(this.memberListFilter.map((m) => [m.id, m])).values()];
          this.memberListFilter = unique;
          this.member_last_page = res.meta.last_page;
        }
      });
    },
    searchUser(){
      // clear timeout variable
      clearTimeout(this.timeout);
      const self = this;
      this.timeout = setTimeout(function () {
            // enter this block of code after 1 second
            // handle stuff, call search API etc.
            self.member_params.keyword = self.search;
            self.getEmployees(self.member_params);
        }, 800);
    },

    filterByUser(member){
      this.$emit('filterByUser', member);
    }

  }

}
</script>

<style lang="scss">
.chooseMember:hover {
    background: #dedfe2;
    border-radius: 5px;
}
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
}
.member-list-modal{
  .modal-header-title{
    padding: 10px;
    border: 1px solid #EDEFF5;
    margin-bottom: 0px;
    line-height: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.user-detail {
  .md-ripple {
    padding: 6px 10px !important;
    align-items: start;
  }
  .fullname {
    margin-bottom: 5px !important;
  }
  .detail-info {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    .material-icons {
      width: auto;
      font-size: 16px;
      color: #4b4b4b;
    }
    .text-info-name {
      margin-left: 5px;
      font-size: 12px;
      color: #4b4b4b;
    }
  }
}

.member-list-modal {

  .content {
    height: 350px;
    overflow-y: scroll;

    .info-text {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  }

  .md-list-item:focus {
    border-radius: 8px;

    .check-icon {
      width: 20px;
      opacity: 1;
    }
  }

  .check-icon {
    width: 20px;
    opacity: 0;
    color: rgb(48, 169, 96);
  }

  .input-auto-complete {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    border: none;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    max-height: 90px;
    overflow-y: auto;
    padding: 8px 12px;
    min-height: 44px;
    border-bottom: 1px solid #F0F0F0;

    .item-select {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      height: 24px;
      margin: 5px;
      background-color: rgb(236, 247, 231);
      border: none;
      border-radius: 6px;
      box-sizing: content-box;
      padding: 4px 8px;
      outline: 0px;
      overflow: hidden;
      color: rgb(48, 169, 96);
      font-size: 12px;
      line-height: 16px;
      cursor: pointer;
      width: fit-content;

      .close-icon {
        display: flex;
        width: 16px;
        height: 16px;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        border: none;
        border-radius: 50%;
        background: white;
        color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        margin-left: 8px;
      }
    }

    .input-search-field {
      font-size: 14px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      height: 30px;
      box-sizing: border-box;
      padding: 4px 6px;
      width: 0px;
      min-width: 30px;
      -webkit-box-flex: 1;
      flex-grow: 1;
      border: 0px;
      margin: 0px;
      outline: 0px;
    }
  }

  .create-post-btn {
    width: 100% !important;
  }
}


</style>
