import BaseService from "./BaseService";

export default class EmployeeService extends BaseService {
    static async getEmployees(params = {}) {
        const res = await this.get(`/employee`, params, "AZAGROUP");
        return res.data;
    }

    static async getProfileUser(params = {}) {
        const res = await this.get(`/profile`, params);
        const res2 = {
            "status": true,
            "message": null,
            "data": {
                "username" : 'hungnt3',
                "fullname" : 'Nguyễn Trung Hùng',
                "avatar" : '/img/faces/card-profile1-square.jpg',
                "real_salary" : '13000000',					// Lương hiện tại
                "insurancy_salary" : '5630000',			// Lương cơ bản
                "first_working_day" : '2023-01-01',
                "team": {
                    "agency": 'CHI NHÁNH TITANS',
                    "department": 'PHÒNG TITANS',
                    "team": 'MAOFI TEAM'
                },
                "role" : {							// Chức vụ
                    "name" : 'Giám đốc',
                    "department" : {				// Khu vực / bộ phận
                        "name" : 'CHI NHÁNH DIỆP LỤC'
                    },
                    "position" : {					// Loại nhân viên
                        "name" : 'Giám đốc chi nhánh'
                    }
                }
            }
        };
        return res.data;
    }
    // async create(params = {}) {
    //     const res = await this.post(`/api/v1/users`, params);
    //     return res.data;
    // }
    //
    // async update(id, params = {}) {
    //     const res = await this.post(`/api/v1/users/${id}`, params);
    //     return res.data;
    // }
    //
    // async show(id, params = {}) {
    //     const res = await this.get(`/api/v1/users/${id}`, params);
    //     return res.data;
    // }
    //
    // async destroy(id) {
    //     const res = await this.delete(`/api/v1/users/${id}`);
    //     return res.data;
    // }
}
