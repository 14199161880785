<template>
  <md-toolbar
      md-elevation="0"
      class="md-transparent p-0px header-theme"
      :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <md-card class="p-0px m-0px border-radius-0px">
      <div class="header-bar">
          <div class="left-topbar">
            <div class="logo-aza-work">
              <img src="/logo_128.png" alt="aza">
            </div>
            <div class="slogan-logo">
              AZA <br/> WORK+
            </div>
          </div>
          <div class="middle-topbar md-offset">
            <!-- <div class="menu-header menu-active">
                <a href="#" class="text">Live Board</a>
            </div> -->
            <div class="menu-header cursor-pointer" :class="{'menu-active' : navigation.meta.tab === $route.meta.tab}" 
              v-for="(navigation, index) in navigations " :key="index"
              @click="toPath(navigation.path)"
                 v-show="!navigationAllowed.includes(navigation.path) || (navigationAllowed.includes(navigation.path) && userTest.includes(user.id))"
            >
              <!-- <a class="text" @click="activeMenu(navigation, index)">{{ navigation.title }}</a> -->
              <span :to="navigation.path" class="text">
                {{ navigation.meta.tab == 'dashboard' ? 'Live Board' : navigation.name }}
              </span>
            </div>
          </div>
          <div class="right-topbar">
            <md-list-item v-popover:dropdown_header.bottom class="cursor-pointer">
              <div class="md-list-item-text text-right user-loged">
                <p><span class="text-400-medium fullname">{{ user.fullname }}</span></p>
                <p class="user-team">
                  {{ 
                    user.team && user.team.team ? user.team.team : 
                      user.team && user.team.agency ?  user.team.agency :
                        user.team && user.team.department ?  user.team.department : 'Aza'
                  }}
                </p>
              </div>
              <md-avatar class="logo-toolbar">
                <img class="logo" :src="user.avatar ? changeLinkAvatar(user.avatar) : '/img/toolbar/logo_toolbar.svg'">
              </md-avatar>
          </md-list-item>
          </div>
          <popover name="dropdown_header" :pointer="true">
            <div class="list-item cursor-pointer" v-on:click="logout()">
              <md-icon>logout</md-icon>
              <span class="md-list-item-text">Logout</span>
            </div>
          </popover>
          <!-- <md-list-item>
            <md-avatar class="logo-toolbar">
              <img class="logo" src="/img/toolbar/logo_toolbar.svg">
            </md-avatar>
            <div class="md-list-item-text">
                <span class="heading-600 neutral_1">Bảng tin</span>
                <p>Xin chào, <span class="text-400-medium">{{ user.fullname}}</span></p>
            </div>
          </md-list-item> -->
        <!-- <div class="md-toolbar-section-end">
          <md-button
              class="md-just-icon md-round md-simple md-toolbar-toggle"
              :class="{ toggled: $sidebar.showSidebar }"
              @click="toggleSidebar"
          >
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </md-button>
        </div> -->
      </div>

    </md-card>
  </md-toolbar>
</template>

<script>
import routes from "../../router/routes";
import { helpers } from "../../helper/helpers";

export default {
  data() {
    return {
      user: this.$store.state.auth.user,
      navigations: [],
      navigationAllowed: ['cham-cong', 'timeoff'],
      userTest: [1230, 1005, 119, 806, 845],
    };
  },
  created(){
    this.navigations = routes[routes.length - 1].children;
  },
  mounted() {
   
  },
  watch:{
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    goToNotifications() {
      this.$router.push({name: "Notifications"});
    },
    goToUsers() {
      this.$router.push({name: "User Profile"});
    },
    toPath(path){
      this.$router.push(path);
    },
    changeLinkAvatar(url){
      return helpers.changeLinkAvatar(url)
    },
    async logout() {
      await this.$store.dispatch("logout");
    },
  },
};
</script>
<style lang="scss">
.md-toolbar {
  height: auto;
  padding-left: 2px;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 10%);
}
.logo-toolbar{
  width: 51px;
  height: 51px;
  left: 0px;
  top: 0px;
  background: #EDEFF5;
  border: 1px solid #D8DAE5;
  border-radius: 100%;
}
.logo-toolbar .logo{
  width: 75%;
}

.header-bar{
  background: #fff;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  .left-topbar, .right-topbar{
    height: 100%;
    position: relative;
    background: linear-gradient(258.17deg, rgba(202, 177, 255, 0.6) 28.79%, #B1FFE3 98.93%);
  }
  .left-topbar{
    width: 250px;
    display: inline-flex;
    align-items: center;
    gap: 15px;
    padding-left: 20px;
    &:before{
      content: '';
      top: 0%;
      right: 0;
      border-style: solid;
      border-width: 0 56px 56px 0;
      border-color: transparent transparent #ffffff transparent;
      position: absolute;
      transform: rotate(180deg);
      z-index: 1;
    }
    .slogan-logo{
      color: #FCAF17;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 112.5% */
      letter-spacing: -0.05px;
    }
  }
  .right-topbar{
    padding-left: 60px;
    .user-loged{
      text-align: right;
      .fullname{
        color: rgba(55, 107, 251, 1);
      }
      .user-team {
        font-size: 12px !important;
      }
    }
    .md-list-item{
      height: 100%;
    }
    .md-list-item-default{
      height: 100%;
      .md-list-item-content{
        grid-gap: 10px;
      }
    }
    &:before{
      content: '';
      top: 0%;
      left: 0;
      border-style: solid;
      border-width: 0 56px 56px 0;
      border-color: transparent transparent #ffffff transparent;
      position: absolute;
      z-index: 1;
    }
  }
  .middle-topbar{
    height: 100%;
    grid-gap: 50px;
    .menu-active{
      &:after{
      background: rgba(255, 123, 0, 1);
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 4px;
      border-radius: 4px 4px 0 0;
      content: '';
    }
      .text{
        color: rgba(255, 123, 0, 1) !important;
      }
    }
    .menu-header{
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      .text{
        color: rgba(48, 48, 48, 1);
        font-family: Inter;
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.05000000074505806px;
        text-align: left;
        padding: 0 10px;
      }
    }
  }
}
[data-popover='dropdown_header']{
    // width: max-content !important;
    // top: 60px !important;
    // margin-left: 40px;
    &:before{
      border: none !important;
    }
    .list-item {
      display: flex;
      align-items: center;
      grid-gap: 10px;
    }

  }
</style>
