import moment from "moment";

export const helpers = {
    fromNow(date) {
        return moment(date).locale("vi").fromNow();
    },

    createSlug(str) {
        str = this.convertViToEn(str);
        str = str.trim().toLowerCase();
        str = str.replace('/[^a-z0-9-]/', '-');
        str = str.replace('/-+/', "_");
        return str;
    },

    convertViToEn(str, checkNumber = false) {
        str = str.replace(" ", "_");
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");

        if (checkNumber) {
            str = str.replace(/0/g, "");
            str = str.replace(/1/g, "");
            str = str.replace(/2/g, "");
            str = str.replace(/3/g, "");
            str = str.replace(/4/g, "");
            str = str.replace(/5/g, "");
            str = str.replace(/6/g, "");
            str = str.replace(/7/g, "");
            str = str.replace(/8/g, "");
            str = str.replace(/9/g, "");
        }

        return str;
    },

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },

    isEmpty(text) {
        return text == null || (typeof text === "string" && text.trim().length === 0);
    },

    formatCurrency(currency, useStyle = false){
        let style = {};
        if(useStyle){
            style = { style: 'currency', currency: 'VND' };
        }
        return new Intl.NumberFormat('vi-VN', style).format(currency);
    },

    convertToText(number) {
        if (number < 1000) {
            return number.toString();
          } else if (number < 1000000) {
            return Math.ceil(number / 1000) + " ng";
          } else if (number < 1000000000) {
            return Math.ceil(number / 1000000) + " tr";
          } else {
            return (number / 1000000000).toFixed(3) + " tỷ";
          }
      },

    formatDateDifference(startDate, endDate) {
        const timeDifference = endDate - startDate;
      
        const millisecondsPerSecond = 1000;
        const millisecondsPerMinute = millisecondsPerSecond * 60;
        const millisecondsPerHour = millisecondsPerMinute * 60;
        const millisecondsPerDay = millisecondsPerHour * 24;
        const millisecondsPerMonth = millisecondsPerDay * 30.44; // Trung bình số ngày trong một tháng
        const millisecondsPerYear = millisecondsPerMonth * 12;
      
        const years = Math.floor(timeDifference / millisecondsPerYear);
        const months = Math.floor((timeDifference % millisecondsPerYear) / millisecondsPerMonth);
        const days = Math.floor((timeDifference % millisecondsPerMonth) / millisecondsPerDay);
      
        let content = '';
        if(years > 0){
            content += years + ' năm ';
        }

        if(months > 0){
            content += months + ' tháng ';
        }

        if(days > 0){
            content += days + ' ngày';
        }
        return content;
    },

    changeLinkAvatar(url){
        if(/(http(s?)):\/\//i.test(url)) {
            return url.replace("https://api.azasystem.shop", "https://azagroup.asia/");
        } else {
            return 'https://azagroup.asia' + url;
        }
    },

    convertSecondsToHours(seconds){
        return moment.utc(moment.duration(parseInt(seconds),'seconds').as('milliseconds')).format('HH:mm')
    },

};
