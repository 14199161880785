<template>
  <div>
    <md-list>
<!--      <md-list-item class="post-file">-->
<!--        <img src="/img/post/content/file.svg" class="file-image">-->
<!--        <span class="file-text">Đồng hồ New Smart Watch.docx</span>-->
<!--        <div class="file-option">-->
<!--          <span class="file-type p-15px">.docx</span>-->
<!--          <span class="file-size p-15px">100kb</span>-->
<!--          <span class="file-btn-download">-->
<!--          <img src="/img/post/content/dowload.svg" class="file-image">-->
<!--          </span>-->
<!--        </div>-->
<!--      </md-list-item>-->
    </md-list>
  </div>
</template>

<script>
export default {
  name: "file-content",

};
</script>

<style lang="scss">
</style>
