<template>
  <div v-html=Content></div>
</template>

<script>
export default {
  name: "text-content",
  props: {
    Content: {
      type: String,
      default: '',
    },
  },

};
</script>

<style lang="css"></style>
