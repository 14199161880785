<template>
 <div :class="{'image-content-wrapper1': mediaList.length === 1, 'image-content-wrapper2': mediaList.length !== 1}" v-if="mediaList.length > 0">
   <div v-if="mediaList.length > 0" class="wrapper-image" :class="{'wrapper-image1': mediaList.length ===1,
    'wrapper-image2': mediaList.length ===2,
    'wrapper-image3': mediaList.length ===3,
    'wrapper-image4': mediaList.length ===4,
    'wrapper-image5': mediaList.length > 4}">
     <md-card-media class="card-media" v-for="(media, index) in mediaList" :key="index" v-if="index <= 4">
       <div v-if="index === 4" class="total-image-other-wrapper">
       </div>
       <span v-if="index === 4" class="total-image-other">+{{mediaList.length - 4}}</span>
       <img :src="media.path">
     </md-card-media>
   </div>
 </div>
</template>

<script>
export default {
  name: "image-content",
  props: {
    mediaList: {
      type: Array,
      required: true,
    }
  }
};
</script>

<style lang="scss" scoped>
  .image-content-wrapper1 {
    max-height: 500pt;
  }
  .image-content-wrapper2 {
    height: 500pt;
  }

  .wrapper-image {
    height: 100%;
    cursor: pointer;
  }
  .wrapper-image1 {
    .card-media {
      height: 100%;
      img {
        margin: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  .wrapper-image2, .wrapper-image3, .wrapper-image4, .wrapper-image5 {
    .card-media {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      display: inline-block;
    }
  }

  .wrapper-image2 {
    .card-media:nth-child(1), .card-media:nth-child(2){
      padding-bottom: 1px;
    }
    .card-media {
      height: 50%;
      width: 100%;
    }
  }

  .wrapper-image3 {
    .card-media:nth-child(1) {
      width: 100%;
      height: 60%;
    }

    .card-media:nth-child(2), .card-media:nth-child(3) {
      height: 40%;
      width: 50%;
    }
    .card-media:nth-child(1), .card-media:nth-child(2), .card-media:nth-child(3) {
      padding-bottom: 1px;
      padding-right: 1px;
    }
  }

  .wrapper-image4 {
    .card-media:nth-child(1), .card-media:nth-child(2), .card-media:nth-child(3), .card-media:nth-child(4) {
      width: 50%;
      height: 50%;
    }

    .card-media:nth-child(1), .card-media:nth-child(2), .card-media:nth-child(3), .card-media:nth-child(4) {
      padding-bottom: 1px;
      padding-right: 1px;
    }
  }

  .wrapper-image5 {
    .card-media:nth-child(1), .card-media:nth-child(2) {
      width: 50%;
      height: 60%;
    }

    .card-media:nth-child(3), .card-media:nth-child(4), .card-media:nth-child(5) {
      width: calc(100% / 3);
      height: 40%;
    }
    .card-media:nth-child(1), .card-media:nth-child(2), .card-media:nth-child(3), .card-media:nth-child(4), .card-media:nth-child(5) {
      padding-bottom: 1px;
      padding-right: 1px;
    }

    .card-media:nth-child(5) {
      position: relative;

      .total-image-other-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.5;
        display: flex;
      }

      .total-image-other {
        position: absolute;
        top: 50%;
        align-self: center;
        text-align: center;
        width: 100%;
        color: white;
        font-size: 2rem;
        transform: translateY(-50%);
      }
    }
}
</style>
