<template>
  <md-card-header>
    <md-list-item>
      <md-avatar>
        <img class="avatar" :src="userPost.avatar">
      </md-avatar>

      <div class="md-list-item-text">
        <div class="text-400-medium">
          <span class="text-400-medium">{{ userPost.nam }}</span>

          <template v-if="group && group.type === GROUP_TYPE.COMPANY_WALL_GROUP">
            <span> đã đăng lên</span>
            <span class="text-400-medium">Tường công ty</span>
          </template>
          <template v-if="group &&
          (group.type === GROUP_TYPE.PRIVATE_GROUP || group.type === GROUP_TYPE.PRIVATE_GROUP || group.type === GROUP_TYPE.PRIVATE_GROUP)">
            <template v-if="userPost.type === POST_TYPE.FILE">
              <span> đã tải 4 file lên nhóm:</span>
              <span class="text-400-medium">Marketing. No1</span>
            </template>
            <template v-else>
              <img src="/img/post/header/triangle.svg" class="triangle-post">
              <span class="text-400-medium">Ăn chơi không sợ mưa rơi</span>
            </template>
          </template>
        </div>
        <p class="neutral_2 text-300-default">{{ fromNow(userPost.created_at) }}</p>
      </div>

      <template v-if="group">
          <img v-if="!userPost.pinned" class="cursor-pointer bookmark" src="/img/post/header/bookmark.svg">
      </template>
      <md-icon class="cursor-pointer">more_horiz</md-icon>
    </md-list-item>
  </md-card-header>
</template>

<script>
import moment from "moment";
import {GROUP_TYPE, POST_TYPE} from "../../../const/const";

export default {
  name: "post-header",
  props: {
    userPost: {
      type: Object,
      default: () => {
      },
    },
    group: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      GROUP_TYPE: GROUP_TYPE,
      POST_TYPE: POST_TYPE,
    };
  },
  methods: {
    fromNow(date) {
      return moment(date).locale("vi").fromNow();
    }
  }

};
</script>

<style lang="css">
.triangle-post {
  width: 9px;
  margin: 0px 5px 3px 5px;
}

.avatar {
  background: url(/img/faces/avatar_default.png) no-repeat;
  background-size: cover;
  width: 40px !important;
  height: 40px !important;
}

.bookmark{
  width: 28px !important;
  height: 28px !important;
}
</style>
