<template>
  <div>
    <modal name="reward-regulation-modal"
           :click-to-close="false"
           width="95%"
           height="90%"
           class="reward-regulation-modal modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
           >

      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500">Quy chế thưởng</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('reward-regulation-modal')">close</span>
        </md-dialog-title>
      </md-card>

     
        <div class="settings-container">
          <div class="menu-card">
            <div class="menu-item" :class="{'menu-item-active': type === 2}" @click="type = 2">Thưởng Daily Deals</div>
            <div class="menu-item" :class="{'menu-item-active': type === 3}" @click="type = 3">Thưởng sản phẩm mới</div>
            <!-- <div class="menu-item" :class="{'menu-item-active': type === 1}" @click="type = 1">Thưởng cá nhân</div>
            <div class="menu-item" :class="{'menu-item-active': type === 4}" @click="type = 4">Thưởng khác</div> -->
          </div>
          <!-- <TableEasy 
            :columns="columns"
            :tableData="tableData"
            :checkboxOption="checkboxOption"
          /> -->
          <md-card-content>
            <vue-element-loading :active="isLoading" spinner="bar-fade-scale" />
            <ve-table
              :border-x="true"
              :border-y="true"
              :border-around="true"
              row-key-field-name="rowKey"
              :columns="columns"
              :table-data="tableData"
            />
          </md-card-content>
        </div>

    </modal>
  </div>
</template>

<script>
import DashboardService from "../../../store/services/DashboardService";
import {helpers} from "../../../helper/helpers";

export default {
  name: 'reward-regulation-modal',
  data(){
    return {
      type: 2,
      closeOnClick: true,
      columns: [
          { 
            field: "name",
            key: "b",
            title: "Tên thưởng",
            align: "left",
            width: 250,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              return (
                <div class="label">
                  {/* <span class={`status-employee ${row.status === 1 ? 'active' : 'non-active'}`}>
                  </span> */}
                  <div class=''>{row.name}</div>
                </div>
              );
            }
          },
          { 
            field: "amount",
            key: "d",
            title: "Mức thưởng",
            width: 110,
            align: "right",
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              return (
                <div class="amount">
                  {helpers.formatCurrency(row.amount)}
                </div>
              );
            }
          },
          { 
            field: "condition",
            key: "d1",
            title: "Điều kiện",
            align: "left",
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              return (
                <div class="condition">
                  {`DS > ${helpers.formatCurrency(row.revenue)+' đ'}, %CPQC < ${row.cost_percent}%`}
                </div>
              );
            }
          },
          { 
            field: "team",
            key: "d3",
            title: "Chi nhánh",
            align: "left",
            width: 150,
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              return (
                <div class="address">
                  {row?.team?.agency ?? 'Toàn công ty'}
                </div>
              );
            }
          },
          { 
            field: "apply_date",
            key: "d4",
            title: "Ngày áp dụng",
            width: 150,
            align: "center"
          },
          { 
            field: "description",
            key: "d5",
            title: "Mô tả",
            align: "left"
          },
      ],
      tableData: [],
      checkboxOption: {
          // row select change event
          selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {
              // console.log(row, isSelected, selectedRowKeys);
          },
          // selected all change event
          selectedAllChange: ({ isSelected, selectedRowKeys }) => {
              // console.log(isSelected, selectedRowKeys);
          },
      },
      compnayRewardList: [],
      isLoading: false
    }
  },
  watch: {
      'type'(newVal) {
        this.getCompanyRewardList();
      }
    },
    created () {
      window.enableScrollBody();
    },
    mounted(){
      // this.fixHeightVeTable();
    },
    methods: {
      fixHeightVeTable(){
        const clientHeightTopbar = document.getElementById('menu-toolbar').clientHeight;
        const paddingMainConent = 60; //margin top 30 - bottom - 30
        const paddingContent= 40;// top-bottom
        const heightWidthScrollBottom = 6;
        const tabHeaderHieght = 0;//document.getElementsByClassName('tab-header')[0].clientHeight;
        const veTableHeader = document.getElementsByClassName('ve-table-header')[0].clientHeight;
        
        const heightByScreen = clientHeightTopbar + paddingMainConent + tabHeaderHieght + veTableHeader - paddingContent - heightWidthScrollBottom;
        
        document.getElementsByClassName('ve-table-container')[0].style.height = `calc(100vh - ${heightByScreen}px)`;
        document.getElementsByClassName('ve-table-container')[0].style.maxHeight = '100%';
      },

      async getCompanyRewardList(){
        this.isLoading = true;
        const params = {
          type : this.type
        };
        await DashboardService.getCompanyRewardList(params).then(res => {
          this.tableData = res.data;
        });
        this.isLoading = false;
      },

      beforeOpened(event) {
        this.getCompanyRewardList();
        window.disableScrollBody();
      },

      beforeClosed(){
        window.enableScrollBody();
      }
    }
}
</script>

<style lang="scss">
.reward-regulation-modal {
  max-height: calc(100vh - 145px) !important;
}
.menu-card {
  display: flex;
  width: max-content;
  /* padding: 0px 20px; */
  align-items: center;
  gap: 4px;
  margin-bottom: 10px;

  .menu-item {
    padding: 0 15px 10px;
    gap: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-top: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  .menu-item-active {
    border-bottom: 3px solid #ff7b00;
    color: #ff7b00;
  }
}
.md-menu-content {
  z-index: 9999;
}

.settings-container {
  background: #fff;
  padding: 10px;
  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f4f3f4;
    font-size: 14px;
    .actions {
      display: flex;
      grid-gap: 20px;
      padding: 10px;
      .tab-name {
        font-weight: 500;
        color: #898989;
        position: relative;
        font-size: 14px;
        &:hover {
          cursor: pointer;
        }
        .tab-active {
          color: #1200ff;
          margin-left: 15px;
          &:after {
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            top: 50%;
            left: 0;
            background: #1200ff;
            transform: translateY(-50%);
          }
        }
      }
    }
    .pagination {
      display: flex;
      padding-left: 0;
      list-style: none;
      border-radius: 0.25rem;
      align-items: center;
      grid-gap: 10px;
      .total-records-pagination {
        font-size: 13px;
        .total {
          color: #000;
          font-weight: 500;
        }
      }
      .md-menu {
        align-items: center;
        display: flex;
      }
      .md-button {
        background-color: unset !important;
        color: #000 !important;
        height: max-content;
        box-shadow: unset;
        .md-ripple {
          padding: 0 !important;
          .md-button-content {
            text-transform: capitalize;
          }
        }
      }
    }
  }
  .ve-table-container {
      .ve-table-fixed-right {
        width: 100px;
        .action-button {
          &:hover {
            cursor: pointer;
          }
          font-size: 13px;
          .edit {
            color: rgba(0, 74, 219, 1);
            margin-right: 10px;
            border-bottom: 1px solid rgba(0, 74, 219, 1);
          }
          .stop {
            color: rgba(232, 0, 0, 1);
            border-bottom: 1px solid rgba(232, 0, 0, 1);
          }
          .open {
            color: #1fb800;
            border-bottom: 1px solid #1fb800;
          }
        }
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #8888887a;
        border-radius: 5px;
        &:hover {
          background: #555;
        }
      }
    }
    .ve-table-body {
      .amount {
        color: rgba(55, 107, 251, 1);
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.05000000074505806px;
      }
      .address {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .employee-avatar {
        .md-avatar {
          border-radius: 100%;
          border: 2px solid #fff;
          width: 30px;
          height: 30px;
          min-width: 30px;
          background: #f3f3f3;
          margin-right: 5px;
          z-index: 1;
        }
      }
      .number-employee {
        color: #1fb800;
        font-weight: 500;
      }
      .status-employee {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        margin: auto;
      }
      .non-active {
        border: 1px solid #1fb800;
        background: #1fb8001a;
      }
      .active {
        background: #1fb800;
      }
    }
}
</style>
