<template>
  <div class="leave-request-detail-modal__container">
    <modal name="leave-request-detail-modal"
           :click-to-close="false"
           width='900px'
           height="auto"
           class="modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
    >
      <div class="modal-content" v-if="leaveRequest !== null">
        <md-card class="modal-header-custom">
          <md-dialog-title>
            <div>
              <div class="title heading-600">{{leaveRequest.name}}</div>
              <div class="d-flex-center">
                <md-icon class="text-400-semibold">schedule</md-icon>
                <span class="text-300-default neutral_2" style="white-space: pre">Tạo lúc : </span>
                <span class="text-300-medium neutral_1">{{leaveRequest.created_at}}</span>
              </div>
            </div>
            <div class="btn-wrapper" v-if="leaveRequest.created_by.id !== user.id && leaveRequest.status === 3">
              <div class="approve-btn btn btn-green" @click="approveLeaveRequest(leaveRequest.id, 1)">PHÊ DUYỆT</div>
              <div class="reject-btn btn btn-red ml-10px" @click="approveLeaveRequest(leaveRequest.id, 2)">TỪ CHỐI</div>
            </div>

          </md-dialog-title>
        </md-card>
        <md-card-content>
          <div class="content__wrapper">
            <div class="left-content">
              <div class="text-400-semibold header-title">
                THÔNG TIN CHUNG
              </div>
              <div class="row-content">
                <div class="text-400-default">
                  Loại đề xuất:
                </div>
                <div class="secondary_g400 text-400-medium">
                  <!-- {{leaveRequest ? leaveRequest.ts_leave_category.name : ''}} -->
                  {{ leaveRequest.label_type }}
                </div>
              </div>
              <!-- =============Xin về sớm================= -->
              <template v-if="LEAVE_TYPE.ADJUSTMENT_OF_ATTENDANCE === leaveRequest.type">
              </template>
              <!-- =============Xin về sớm================= -->
              <!-- =============Xin về sớm================= -->
              <template v-if="LEAVE_TYPE.LEAVE_EARLY === leaveRequest.type">
                <div class="row-content">
                  <div class="text-400-default">
                    Thời gian về sớm:
                  </div>
                  <div class="secondary_g400 text-400-medium">{{ formatMoment(leaveRequest.request_data.time, 'HH:mm, dddd, DD/MM/YYYY') }}</div>
                </div>
              </template>
              <!-- =============Xin về sớm================= -->
              <!-- =============Đơn nghỉ phép============== -->
              <template v-if="LEAVE_TYPE.LEAVE_APPLICATION === leaveRequest.type">
                <div class="row-content">
                  <div class="text-400-default">
                    Số công:
                  </div>
                  <div class="secondary_g400 text-400-medium">{{totalDayOff(leaveRequest.request_data.shift_from, leaveRequest.request_data.shift_to, leaveRequest.request_data.date_from, leaveRequest.request_data.date_to)}}</div>
                </div>
                <div class="row-content">
                  <div class="text-400-default">
                    Bắt đầu:
                  </div>
                  <div class="text-400-medium">{{`Ca ${leaveRequest.request_data.shift_from} - ${formatDate(leaveRequest.request_data.date_from)}`}}</div>
                </div>
                <div class="row-content">
                  <div class="">
                    Kết thúc:
                  </div>
                  <div class="text-400-medium">{{`Ca ${leaveRequest.request_data.shift_to} - ${formatDate(leaveRequest.request_data.date_to)}`}}</div>
                </div>
              </template>
              <!-- =============Đơn nghỉ phép============== -->
              <div class="row-content text-400-default">Người tạo: </div>
              <div class="row-content mt-10px">
                <div class="d-flex-center">
                  <md-avatar>
                    <img :src="leaveRequest.created_by.avatar">
                  </md-avatar>
                  <div class="ml-5px">
                    <div class="text-400-medium">{{leaveRequest.created_by.fullname}}</div>
                    <div class="text-300-default">{{leaveRequest.created_by.role.name}}</div>
                  </div>
                </div>
                <div>
                  <div class="">{{leaveRequest.created_by.team.team}}</div>
                  <div class="text-300-default">{{leaveRequest.created_by.team.agency}}</div>
                </div>
              </div>
              <div class="row-content">
                <div class="">Lý do cụ thể:</div>
              </div>
              <div class="neutral_1 mt-10px">
                {{leaveRequest.reason}}
              </div>
              <!-- =============Tệp tin tải lên============== -->
              <div class="row-content mt-10px">
                <div class="">Tài liệu đính kèm:</div>
              </div>
              <div class="mt-10px">
                <template v-if="leaveRequest.medias.length > 0">
                  <div class="files-upload">
                    <div class="file-item cursor-pointer" v-for="(item, index) in leaveRequest.medias" :key="item.id" :title="item.filename" @click="openMedia(item, index)">
                    <img class="image-file" v-if="item.media_type === 'image' && item.is_image" :src="item.thumbnails.small_thumb" alt="">
                    <video v-else-if="item.media_type === 'video'" class="file-img" style="margin: auto">
                      <source :src="item.path">
                      Sorry, your browser doesn't support embedded videos.
                    </video>
                    <div class="fileData" v-else>
                      <div class="file-thumb">
                        <md-icon>description</md-icon>
                      </div>
                      <div class="file-content">
                        <div class="heading-400 filename">{{ item.filename }}</div>
                      </div>
                    </div>
                  </div>
                  </div>
                </template>
                <template v-else>
                  <div class="text-400-default neutral_2">Không có tài liệu đính kèm</div>
                </template>
              </div>
              <!-- =============Tệp tin tải lên============== -->
            </div>
            <div class="right-content">
              <div class="w_100 approve-content__wrapper">
                <div class="text-400-semibold header-title">
                  PHÊ DUYỆT
                </div>
                <div class="row-content mt-20px">
                  <div class="text-400-default">Trạng thái</div>
                  <div class="badge badge-warning" v-if="leaveRequest.status === leaveRequestStatus.WAITING_APPROVE">CHỜ DUYỆT</div>
                  <div class="badge badge-success" v-if="leaveRequest.status === leaveRequestStatus.APPROVED">Đã duyệt</div>
                  <div class="badge badge-danger" v-if="leaveRequest.status === leaveRequestStatus.REJECT">Từ chối</div>
                </div>
                <div class="row-content mt-15px">
                  <div class="text-400-default">Chế độ duyệt</div>
                  <div class="text-400-medium" v-if="leaveRequest.ts_leave_category && leaveRequest.ts_leave_category.decision_mode !== 0">
                    {{ leaveRequest.ts_leave_category.decision_mode === 2 ? 'DUYỆT ĐỒNG THỜI' : 'SINGLE APPROVE'  }}
                  </div>
                </div>
                <div class="text-400-default mt-15px">Người phê duyệt</div>
                <div class="row-content mt-15px" v-for="approver in leaveRequest.ts_user_leave_decisions" :key="approver.id + 'abc'">
                  <div class="w_100" style="display: flex">
                    <md-avatar>
                      <img :src="approver.user && approver.user.avatar ? approver.user.avatar : '/img/profile/avatar.png'" alt="achievements">
                    </md-avatar>
                    <div class="ml-10px w_100">
                      <div class="text-400-medium">{{approver.user ? approver.user.fullname : ''}}</div>
                      <div class="text-300-default" style="line-height: normal">{{approver.user ? approver.user.role.display_name : ''}}</div>
                      <div class="text-300-default secondary_b400 mt-5px" style="line-height: normal" v-if="approver.description">Mô tả: {{approver.description ? approver.description : ''}}</div>
                    </div>
                    <div class="mt-5px">
                      <md-icon class="secondary_g400" v-if="approver.status === leaveRequestStatus.APPROVED">check_circle</md-icon>
                      <md-icon class="secondary_r400" v-if="approver.status === leaveRequestStatus.REJECT">cancel</md-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-15px histories__wrapper">
                <div class="text-400-semibold header-title">
                  LỊCH SỬ
                </div>
                <div class="history-item mt-10px" v-for="history in leaveRequest.history" :key="history.time">
                  <md-icon>fiber_manual_record</md-icon>
                  <div>
                    <div class="text-300-default mt-2px">
                      {{formatDateTime(history.time)}}
                    </div>
                    <div>{{generateHistoryContent(history)}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </md-card-content>

        <md-dialog-actions>
          <div class="btn-stroke md-button" @click="$modal.hide('leave-request-detail-modal')">Đóng</div>
        </md-dialog-actions>
      </div>
    </modal>
    <!-- <slide-show-modal /> -->
  </div>
</template>

<script>

import moment from "moment";
import {LEAVE_REQUEST_STATUS, LEAVE_TYPE} from "../../../../const/const";
import {eventBus} from "../../../../config/event-bus";
import TimeSheetService from "../../../../store/services/TimeSheetService";
// import SlideShowModal from "../../../Components/Modal/SlideShowModal";

export default {
  name: 'leave-request-detail-modal',
  computed: {
  },
  components: {
    // SlideShowModal
  },
  data() {
    return {
      leaveRequest: null,
      leaveRequestStatus: LEAVE_REQUEST_STATUS,
      user: this.$store.state.auth.user,
      LEAVE_TYPE: LEAVE_TYPE
    };
  },
  mounted() {
  },
  created() {
    eventBus.$on('approve-leave-request', this.getLeaveRequestDetail);
  },
  beforeDestroy() {
    eventBus.$off('approve-leave-request', this.handleCustomEvent);
  },
  methods: {
    beforeOpened(event) {
      // sắp xếp thời gian giảm dần
      let history = event.params.leaveRequest.history.sort(function (a, b) {
        return new Date(b?.time) - new Date(a?.time);
      });
      event.params.leaveRequest.history = history;
      this.leaveRequest = event.params.leaveRequest;
          },
    formatDate(dateString) {
      // Parse the date string into parts
      const [year, month, day] = dateString.split('-');

      // Create a new Date object (months are 0-indexed in JavaScript Date)
      const date = new Date(year, month - 1, day);

      // Vietnamese locale for 'vi'
      const options = {
        weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'
      };

      // Format the date to the desired format
      return date.toLocaleDateString('vi-VN', options);
    },
    formatDateTime(originalDateString) {
      const originalMoment = moment(originalDateString, "YYYY-MM-DD HH:mm:ss");
      return originalMoment.format("HH:mm DD/MM/YYYY");
    },
    generateHistoryContent(history) {
      let content = history.user.fullname;
      switch (history.type) {
        case 'CREATE':
          content += ' đã tạo đơn ';
          break;
          case 'APPROVE':
            content += ' đã duyệt đơn ';
            break;
        case 'REJECT':
          content += ' đã từ chối đơn ';
          break;
      };
      return content += this.leaveRequest.label_type;
    },
    totalDayOff(startShift, endShift, startDate, endDate, ) {
      // Parse the dates
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Calculate the difference in days
      const diffTime = Math.abs(end - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      // Calculate the partial days based on the shifts
      const shiftsPerDay = 2; // as given there are 2 shifts per day
      let partialDay = startShift !== endShift ? 1 : 0.5;

      // Calculate total days off
      let totalDaysOff = diffDays + partialDay;

      // Return the total days off
      return totalDaysOff;
    },
    approveLeaveRequest(requestId, status) {
      // status 1: Approve 2: Reject
      // TimeSheetService.approveLeaveRequest({leave_id: requestId, status: status}).then(res => {
      //   if (res && res.data) {
      //     this.leaveRequest = res.data;
      //     this.$emit('approve-request')
      //   }
      // });
      this.$modal.show("approval-reason-modal", {leaveRequestData: this.leaveRequest, status: status});
    },
    getLeaveRequestDetail() {
      TimeSheetService.getLeaveDetail({id: this.leaveRequest.id}).then(res => {
        if (res && res.data) {
          this.leaveRequest = res.data;
        }
      });
    },
    formatMoment(valueFormat, format = 'YYYY-MM-DD'){
      moment.locale('vi');
      return moment(valueFormat).format(format);
    },
    openMedia(files) {
      // this.$modal.show('slide-show-modal', {items: files, activeId: 1});
      window.open(files.path , '_blank');
    },
  }
}
</script>

<style lang="scss">
.leave-request-detail-modal__container {
  .content__wrapper {
    display: flex;
    padding-bottom: 90px;
    .row-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      color: #212121;
    }
    .files-upload{
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 5px;
      display: grid;
        .file-item{
          max-width: 150px;
          margin-right: 10px;
          height: 100px;
          object-fit: contain;
          border: 1px solid #e2e2e2;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          .fileData{
            margin: auto;
            text-align: center;
            word-break: break-all;
          }
        }
      }
    .header-title {
      border-bottom: 1px solid #e2e2e2;
      padding: 10px 0px 6px 0px;
    }
    .left-content {
      width: 65%;
      padding-right: 20px;
    }
    .right-content {
      width: 35%;
      .md-icon {
        margin: initial !important;
      }
      .approve-content__wrapper, .histories__wrapper {
        padding: 0px 10px 20px;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
      }
      .histories__wrapper {
        .history-item {
          display: flex;
          .md-icon {
            color: #7A64FF !important;
            font-size: 13px !important;
          }
        }
      }
    }
  }
  .btn-wrapper {
    display: flex;
    align-items: center;
  }

  .md-dialog-title {
    line-height: initial !important;
    .md-icon {
      margin: initial !important;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #555;
    }
    .material-icons {
      font-size: 15px;
      color: #999;
    }
  }
  .button-wrapper {
    padding-top: 30px;
    border-top: 1px dashed #ddd;
    .button {
      padding: 7px 0px;
      width: 48%;
      cursor: pointer;
      text-align: center;
      border-radius: 3px;
    }
    .btn-cancel {
      background-color: #ddd;
      color: #666;
    }
    .btn-success {
      background-color: #42b814;
      color: #fff;
    }
    .btn-success:hover {
      background-color: #3ba710;
    }
  }
}
</style>
