<template>
  <div>
    <modal name="create-group-modal"
           :click-to-close="false"
           width='600px'
           height="auto"
           class="modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
    >
      <form class="" @submit.prevent="save()">

        <md-card class="modal-header-custom">
          <md-dialog-title>
            <span class="neutral_1 heading-500">Tạo nhóm mới</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('create-group-modal')">close</span>
          </md-dialog-title>
        </md-card>

        <md-card-content>
          <div class="md-gutter">
            <label class="required heading-400 neutral_3">Tên nhóm</label>
            <input type="text" placeholder="Tên nhóm" class="form-control input-custom" v-model="form.name" required name="name">
          </div>

          <div class="md-gutter pt-15px">
            <label class="required heading-400 neutral_3">Chọn loại nhóm</label>
            <v-select :options="typeOptions" label="title" placeholder="Chọn loại nhóm"
                      :reduce="(option) => option.id"
                      v-model="form.type">
              <template #search="{attributes, events}">
                <input
                    class="vs__search"
                    :required="form.type == null"
                    v-bind="attributes"
                    v-on="events"
                />
              </template>
              <template slot="option" slot-scope="origin">
                <div class="flex">
                  <div class="col text-overflow-ellipsis">
                    <span class="text-400-medium neutral-1">{{ origin.title }}</span>
                  </div>
                  <div class="col text-overflow-ellipsis">
                    <span class="text-300-default neutral_2">{{ origin.description }}</span>
                  </div>
                </div>
              </template>
            </v-select>
          </div>

          <div class="md-gutter pt-15px" v-if="form.type == 0">
            <label class="required heading-400 neutral_3">Chọn quy trình</label>
            <v-select :options="blogOptions" label="title" placeholder="Chọn quy trình"
                      :reduce="(option) => option.id"
                      v-model="form.blog_id">
              <template #search="{attributes, events}" v-if="form.type == 0">
                <input
                    class="vs__search"
                    :required="form.blog_id == null"
                    v-bind="attributes"
                    v-on="events"
                />
              </template>
              <template slot="option" slot-scope="origin">
                <div class="flex">
                  <div class="col">
                    <span class="text-400-medium neutral-1">{{ origin.title }}</span>
                  </div>
                  <div class="col">
                    <span class="text-300-default neutral_2">{{ origin.icon }}</span>
                  </div>
                </div>
              </template>
            </v-select>
          </div>
          <div class="md-gutter pt-15px pb-15px">
            <label class="heading-400 neutral_3">Thêm thành viên</label>
            <v-select :options="memberOptions"
                      label="username"
                      placeholder="Thêm thành viên"
                      :reduce="(option) => option.id"
                      multiple v-model="form.members"
                      :filterable="false"
                      @search="onSearchUser">
              <template slot="no-options">
                Chưa có dữ liệu
              </template>
              <template slot="option" slot-scope="origin">
                <md-list-item>
                  <md-avatar>
                    <img class="avatar" :src=origin.avatar_url>
                  </md-avatar>

                  <div class="md-list-item-text">
                    <span>{{ origin.fullname }}</span>
                    <p>{{ origin.team.name }} / {{ origin.team.slogan }}</p>
                  </div>
                </md-list-item>
              </template>
            </v-select>
          </div>
        </md-card-content>

        <md-dialog-actions>
          <button type="submit" class="btn-active md-button">Tạo nhóm</button>
          <button class="btn-stroke md-button" @click="$modal.hide('create-group-modal')">Huỷ</button>
        </md-dialog-actions>
      </form>
    </modal>
  </div>
</template>

<script>
import PostService from "../../../store/services/PostService";
import EmployeeService from "../../../store/services/EmployeeService";
import GroupService from "../../../store/services/GroupService";

export default {
  name: 'create-group-modal',
  computed: {},
  mounted() {
    this.getWorkProcess();
    this.getEmployees();
  },
  data: () => ({
    form: {
      name: null,
      type: null,
      blog_id: null,
      members: [],
      description: null,
    },
    typeOptions: [
      {
        id: 0,
        title: 'Nhóm làm việc',
        description: 'Nhóm làm việc và báo cáo hằng ngày của từng phòng ban chuyên biệt'
      },
      {
        id: 1,
        title: 'Nhóm Kín',
        description: 'Bất kỳ ai trong Công ty cũng có thể tìm được nhóm và xem thành viên nhóm. Chỉ thành viên có thể xem bài viết.'
      },
      {
        id: 2,
        title: 'Nhóm Bí mật',
        description: 'Chỉ thành viên mới tìm được nhóm, xem thành viên và những gì họ đăng.'
      }
    ],
    memberOptions: [],
    blogOptions: [],
  }),
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    save() {
      this.createGroup(this.form);
    },
    onSearchUser(search, loading) {
      this.getEmployees({keyword: search});
      // this.memberOptions = this.memberOptions.filter(function (item) { return item.fullname.includes(search); })
    },
    onSearchBlog(search, loading) {
      this.getWorkProcess({keyword: search});
    },

    beforeOpened(event) {
      this.form.name = null;
      this.form.type = null;
      this.form.blog_id = null;
      this.form.members = [];
      this.form.description = null;
    },

    async createGroup(data) {
      await GroupService.createGroup(data).then(res => {
        if (res && res.status) {
          this.$store.dispatch("alerts/success", 'Bạn tạo nhóm thành công.');
          this.$modal.hide('create-group-modal');
        } else {
          let html = ''
          if (res.data) {
            for (let i = 0; i < res.data.length; i++) {
              html += '<p>' + res.data[i][0] + '</p>';
            }
          }
          this.$store.dispatch("alerts/error", 'Bạn tạo nhóm không thành công. Nguyên nhân : ' + res.message + html);
        }
      });
    },
    async getWorkProcess(params = {}) {
      await PostService.getWorkProcess(params).then(res => {
        if (res) {
          this.blogOptions = res.data;
        }
      });
    },
    async getEmployees(params = {}) {
      await EmployeeService.getEmployees(params).then(res => {
        if (res) {
          this.memberOptions = res.data;
        }
      });
    },
  }
}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
}
</style>
