<template>
    <div class="chart-data-container" :style="styles">
      <LineChartGenerator :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
      :dataset-id-key="datasetIdKey" :css-classes="'chart-data'" :width="width" :height="height" />
    </div>
  </template>
  <script>
  import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
  // import {
  //   Chart as ChartJS,
  //   Title,
  //   Tooltip,
  //   Legend,
  //   LineElement,
  //   LinearScale,
  //   CategoryScale,
  //   PointElement
  // } from 'chart.js'
  // ChartJS.register(
  //   Title,
  //   Tooltip,
  //   Legend,
  //   LineElement,
  //   LinearScale,
  //   CategoryScale,
  //   PointElement
  // )
  export default {
    name: 'LineChartData',
    components: {
      LineChartGenerator
    },
    props: {
      chartData:{
        type: Object,
        default: () => {}
      },
      chartOptions:{
        type: Object,
        default: () => {}
      },
      chartId: {
        type: String,
        default: 'line-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 400
      },
      height: {
        type: Number,
        default: 250
      },
      styles: {
      type: Object,
      default: () => {}
    },
      plugins: {
        type: Array,
        default: () => []
      }
    },
    watch: {
    },
    mounted(){
    },

    data() {
      return {
      }
    }
  }
  </script>
<style lang="scss" scoped>
  .chart-data-container{
    width: 100%;
    .chart-data{
      height: 100% !important;
    }
  }
</style>