<template>
  <div>
    <modal name="show-detail-timekeeping-modal"
           :click-to-close="false"
           width='700px'
           height="auto"
           class="modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
    >
      <md-card class="modal-header-custom">
        <md-dialog-title>
            <span class="neutral_1 heading-500">Chi tiết phạt đi sớm/về muộn</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('show-detail-timekeeping-modal')">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content>
        <!-- <TableEasy 
        class="table-easy"
            :columns="columns" 
            :tableData="data" 
          /> -->
        <template v-if="timekeeping_data.leave_invalid.count >= 3">
          <div class="mb-5px" style="color: red">Bạn đã <strong>NGHỈ KHÔNG PHÉP</strong> trên 3 lần. Chú ý quy định <strong>SA THẢI</strong> của công ty.</div>
        </template>
        <template v-if="timekeeping_data.miss_checkout.count >= 5">
          <div class="mb-5px" style="color: red">Bạn đã <strong>ĐI MUỘN</strong> trên 5 lần. Công ty sẽ áp dụng các hình thức cảnh cáo theo quy định đối với bạn.</div>
        </template>
        <template v-if="timekeeping_data.late_total.count >= 5">
          <div class="mb-5px" style="color: red">Bạn đã <strong>ĐI MUỘN</strong> trên 5 lần. Công ty sẽ áp dụng các hình thức cảnh cáo theo quy định đối với bạn.</div>
        </template>
        <table class="mt-5px">
          <thead>
            <tr>
              <th class="left">Loại phạt</th>
              <th class="right">Số buổi</th>
              <th class="right">Phạt công</th>
              <th class="right">Phạt tiền</th>
              <th class="right">Ngày phát sinh</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Số lần đi muộn</td>
              <td class="right">{{ timekeeping_data.late_total.count}}</td>
              <td class="right">{{ timekeeping_data.late_total.shift }}</td>
              <td class="right">{{ formatCurrency(timekeeping_data.late_total.money)}}</td>
              <td class="right">{{ joinDays(timekeeping_data.late_total.date) }}</td>
            </tr>
            <tr>
              <td>Đi muộn từ 30p đến 60p</td>
              <td class="right">{{ timekeeping_data.late_30m.count}}</td>
              <td class="right">{{ timekeeping_data.late_30m.shift }}</td>
              <td class="right">{{ formatCurrency(timekeeping_data.late_30m.money)}}</td>
              <td class="right">{{ joinDays(timekeeping_data.late_30m.date) }}</td>
            </tr>
            <tr>
              <td>Đi muộn > 60p</td>
              <td class="right">{{ timekeeping_data.late_1h.count}}</td>
              <td class="right">{{ timekeeping_data.late_1h.shift }}</td>
              <td class="right">{{ formatCurrency(timekeeping_data.late_1h.money)}}</td>
              <td class="right">{{ joinDays(timekeeping_data.late_1h.date) }}</td>
            </tr>
            <tr>
              <td>Số lần về sớm</td>
              <td class="right">{{ timekeeping_data.early_total.count}}</td>
              <td class="right">{{ timekeeping_data.early_total.shift }}</td>
              <td class="right">{{ formatCurrency(timekeeping_data.early_total.money)}}</td>
              <td class="right">{{ joinDays(timekeeping_data.early_total.date) }}</td>
            </tr>
            <tr>
              <td>Về sớm > 60p <br> hoặc không xin phép về sớm</td>
              <td class="right">{{ timekeeping_data.early_1h.count}}</td>
              <td class="right">{{ timekeeping_data.early_1h.shift }}</td>
              <td class="right">{{ formatCurrency(timekeeping_data.early_1h.money)}}</td>
              <td class="right">{{ joinDays(timekeeping_data.early_1h.date) }}</td>
            </tr>
            <tr v-if="timekeeping_data.miss_checkout">
              <td>Quên chấm công</td>
              <td class="right">{{ timekeeping_data.miss_checkout.count }}</td>
              <td class="right">{{ timekeeping_data.miss_checkout.shift }}</td>
              <td class="right">{{ formatCurrency(timekeeping_data.miss_checkout.money)}}</td>
              <td class="right">{{ joinDays(timekeeping_data.miss_checkout.date) }}</td>
            </tr>
            <tr v-if="timekeeping_data.leave_invalid">
              <td>Nghỉ không phép</td>
              <td class="right">{{ timekeeping_data.leave_invalid.count }}</td>
              <td class="right">{{ timekeeping_data.leave_invalid.shift }}</td>
              <td class="right">{{ formatCurrency(timekeeping_data.leave_invalid.money)}}</td>
              <td class="right">{{ joinDays(timekeeping_data.leave_invalid.date) }}</td>
            </tr>
          </tbody>
        </table>
      </md-card-content>

      <md-dialog-actions>
        <button class="btn-stroke md-button" @click="$modal.hide('show-detail-timekeeping-modal')">Đóng</button>
      </md-dialog-actions>
    </modal>
  </div>
</template>

<script>
 import {helpers} from "../../../helper/helpers";
// import TableEasy from "../../Workplace/Pages/Table/TableEasy.vue";
export default {
  components: {
    // TableEasy
  },
  mounted() {
  },
  data: () => ({
    timekeeping_data: {
      late_total : {},
      late_30m : {},
      late_1h : {},
      early_total : {},
      early_1h: {},
      miss_checkout: {},
      leave_invalid: {},
    }
  }),
  methods: {
    beforeOpened(event) {
      this.timekeeping_data = event.params.data;
    },

    formatCurrency(amount){
      return helpers.formatCurrency(amount);
    },
    joinDays(days) {
      return days  === undefined ? "" : days.join(", ");
    }
  }
}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
  .loading-ex{
    position: unset !important;
  }
}
.statistical-layout{
  margin: 0 !important;
}
</style>
