<template>
  <div class="time-off-content-container bg-layout">
    <div class="header mb-20px mt-15px">
      <div class="heading-600">DANH SÁCH ĐỀ XUẤT</div>
      <div class="btn btn-blue" @click="dropDownList">
        <span>Tạo đề xuất<md-icon style="color: white">arrow_drop_down</md-icon></span>
        <!--  -->
        <transition name="fade">
          <div class="dropdown-list" v-if="this.selectedModal.show" v-click-outside="dropDownList">
            <div class="btn-item" @click="dropDownList(LEAVE_TYPE.LEAVE_APPLICATION)">Đơn xin nghỉ</div>
            <div class="btn-item" @click="dropDownList(LEAVE_TYPE.LEAVE_EARLY)">Xin về sớm</div>
            <div class="btn-item" @click="dropDownList(LEAVE_TYPE.ADJUSTMENT_OF_ATTENDANCE)">Kháng cáo bảng công</div>
            <div class="btn-item" @click="dropDownList(LEAVE_TYPE.VALUABLE_VACATION)">Nghỉ có công</div>
          </div>
        </transition>
      </div>
    </div>
    <div class="body-wrapper">
      <!-- mode-type- -->
      <div class="mode-layout">
        <div class="btn" :class="{'btn-active-type': filterParams.type === null}"
           @click="changeType(null)">
          <span>Tất cả đề xuất</span>
        </div>
        <div class="btn" :class="{'btn-active-type': filterParams.type === LEAVE_TYPE.LEAVE_APPLICATION}"
           @click="changeType(LEAVE_TYPE.LEAVE_APPLICATION)">
          <span>Đơn xin nghỉ</span>
        </div>
        <div class="btn" :class="{'btn-active-type': filterParams.type === LEAVE_TYPE.LEAVE_EARLY}"
           @click="changeType(LEAVE_TYPE.LEAVE_EARLY)">
          <span>Xin về sớm</span>
        </div>
        <div class="btn" :class="{'btn-active-type': filterParams.type === LEAVE_TYPE.ADJUSTMENT_OF_ATTENDANCE}"
           @click="changeType(LEAVE_TYPE.ADJUSTMENT_OF_ATTENDANCE)">
          <span>Kháng cáo bảng công</span>
        </div>
        <div class="btn" :class="{'btn-active-type': filterParams.type === LEAVE_TYPE.VALUABLE_VACATION}"
           @click="changeType(LEAVE_TYPE.VALUABLE_VACATION)">
          <span>Nghỉ có công</span>
        </div>
      </div>
      <div class="side-layout">
        <div>
          <div class="d-flex-center mb-5px d-none">
            <div class="text-400-medium">Loại phê duyệt</div>
            <div class="status-filter-wrapper -rd ml-10px">
              <md-radio v-model="filterParams.decision_mode" :value="3">Gửi đi từ tôi</md-radio>
              <md-radio v-model="filterParams.decision_mode" :value="2">Gửi đến tôi</md-radio>
            </div>
          </div>
          <div class="d-flex-center">
            <div class="text-400-medium">Trạng thái</div>
            <div class="status-filter-wrapper -rd ml-10px">
              <md-radio v-model="filterParams.status" :value="''">Tất cả</md-radio>
              <md-radio v-model="filterParams.status" :value="'1'">Đã duyệt</md-radio>
              <md-radio v-model="filterParams.status" :value="'2'">Từ chối</md-radio>
              <md-radio v-model="filterParams.status" :value="'3'">Đang chờ duyệt</md-radio>
              <md-radio v-model="filterParams.status" :value="'4'">Chờ điều chỉnh</md-radio>
            </div>
          </div>
        </div>
        <div class="datepicker">
          <date-picker
              v-model="dateRange"
              value-type="format" format="YYYY-MM-DD"
              type="date"
              range placeholder="Chọn khoảng thời gian"
              :lang="lang" @change="changeDate"></date-picker>
        </div>
      </div>
      <div class="main-layout">
        <div class="table-wrapper">
          <table>
            <thead class="">
            <tr>
              <td class = "stt">
                <span> #</span>
              </td>
              <td class = "" style="width: 220px">
                <span>Tiêu đề</span>
              </td>
              <td class = "" style="width: 140px">
                <span>Loại đề xuất</span>
              </td>
              <td class = "" style="width: 120px">
                <span>Ngày tạo phiếu</span>
              </td>
              <td class = "employee" style="width: 150px">
                <span>Người đề xuất</span>
              </td>
              <td class = "right" style="text-align: right; min-width: 250px">
                <span>Thời gian</span>
              </td>
              <td class = "" style="width: 100px">
                <span class=""> Trạng thái</span>
              </td>
              <td class = "">
                <span class="">Mô tả phê duyệt</span>
              </td>
              <td class = "" v-if="filterParams.decision_mode === 2">
                <span class=""> Hành động</span>
              </td>
            </tr>
            </thead>
            <tbody>
            <tr class="" v-for="(item, index) in leaveRequestList" :key="'leave-request-' + item.id"  @click="openLeaveRequestDetail(item, $event)">
              <td class="stt">{{++index}}</td>
              <td class="">
                <div class="">
                  <div class="text-400-medium mb-2px hover-link text-2-line" >{{item.name}}</div>
                  <div class="text-300-default neutral_2 text-1-line" >{{item.reason}}</div>
                </div>
              </td>
              <td class="">
                <div class="">{{item.ts_leave_category.name}}</div>
              </td>
              <td>
                <div class="">{{ formatMoment(item.created_at, 'HH:mm') }}</div>
                <div class="">{{ formatMoment(item.created_at, 'DD/MM/YYYY') }}</div>
              </td>
              <td class="">
                <div class="employee">
                  <img class="avatar" :src="item.created_by.avatar">
                  <div class="user-text">
                    <div class="text-400-medium">{{item.created_by.fullname}}</div>
                    <div class="text-300-default neutral_2">{{item.created_by.role.display_name}}</div>
                  </div>
                </div>
              </td>
              <td class="right datetime-show">
                <template v-if="filterParams.type === LEAVE_TYPE.LEAVE_EARLY || item.type === LEAVE_TYPE.LEAVE_EARLY">
                  <div class="text-400-medium">{{ formatMoment(item.request_data.time, 'HH:mm') }}</div>
                  <div class="text-400-medium">{{ formatMoment(item.request_data.time, 'dddd, DD/MM/YYYY') }}</div>
                </template>
                <template v-else-if="filterParams.type === LEAVE_TYPE.ADJUSTMENT_OF_ATTENDANCE || item.type === LEAVE_TYPE.ADJUSTMENT_OF_ATTENDANCE">

                </template>
                <template v-else>
                  <div class="mb-2px">Từ <span class="text-400-medium">{{renderRequestDateTitle(item.request_data.shift_from, item.request_data.date_from)}}</span></div>
                  <div class="">đến <span class="text-400-medium">{{renderRequestDateTitle(item.request_data.shift_to, item.request_data.date_to)}}</span></div>
                </template>
              </td>
              <td class="">
                <div class="badge" :class="'status-background-' + item.status" style="width: 100%;">{{getStatusName(item.status)}} </div>
              </td>
              <td>
                <div class="secondary_b400">{{getApproveDescription(item.ts_user_leave_decisions)}}</div>
              </td>
              <td class="d-flex-center" v-if="filterParams.decision_mode === 2 && item.status !== 1">
                <div class="btn btn-green mr-10px " data-approve-leave="1" style="width: 100%;">Phê duyệt</div>
                <div class="btn btn-red" data-approve-leave="2" style="width: 100%;">Từ chối</div>
              </td>
            </tr>
            </tbody>

          </table>
        </div>
        <div class="w_100 mt-15px">
          <pagination-template :params="pagingParams" @change-params="changePagingParam" />
        </div>
      </div>
    </div>
    <choosing-request-group-modal @leaveCategories="leaveItemCategories" />
    <create-leave-request-modal @saved="getLeaveRequest(true)" />
    <leave-request-detail-modal @approve-request="getLeaveRequest()"/>
    <approval-reason-modal @approve-leave-request="getLeaveRequest()"/>
  </div>
</template>

<script>
import moment from 'moment';
import ChoosingRequestGroupModal from "@/pages/Workplace/Pages/TimeOff/ChoosingRequestGroupModal";
import CreateLeaveRequestModal from "@/pages/Workplace/Pages/TimeOff/CreateLeaveRequestModal";
import TimeSheetService from "@/store/services/TimeSheetService";
import PaginationTemplate from "@/components/PaginationTemplate";
import LeaveRequestDetailModal from "@/pages/Workplace/Pages/TimeOff/LeaveRequestDetailModal";
import DatePicker from "vue2-datepicker";
import vue2DatePicker from "@/mixins/config-vue2-datepicker";
import {LEAVE_TYPE} from "../../../../const/const";
import ApprovalReasonModal from "./ApprovalReasonModal";

moment.locale('vi');
export default {
  name: 'time-off',
  components: {
    ApprovalReasonModal,
    ChoosingRequestGroupModal,
    CreateLeaveRequestModal,
    PaginationTemplate,
    LeaveRequestDetailModal,
    DatePicker,
  },
  mixins: [vue2DatePicker],
  data() {
    return {
      LEAVE_TYPE: LEAVE_TYPE,
      leaveRequestList: [],
      dateRange: [],
      pagingParams: {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      },
      filterParams: {
        status: '', // Tất cả
        decision_mode: 3, // 1: all, 2: request gửi đến, 3: request của mình tạo
        leave_start_date: '',
        leave_end_date: '',
        type: null 
      },
      selectedModal: {
        show: false,
        selected: null
      },
      leave_categories: []
    };
  },
  watch: {
    'filterParams.status'(newV) {
      this.getLeaveRequest(true);
    },
    'filterParams.decision_mode'(newV) {
      this.getLeaveRequest(true);
    },
  },
  computed: {},
  created() {
    this.getLeaveRequest();
  },
  methods: {
    changeDate(dateRange) {
      this.filterParams.leave_start_date = dateRange[0];
      this.filterParams.leave_end_date = dateRange[1];
      this.getLeaveRequest(true);
    },
    openLeaveRequestDetail(item, event) {
     if(typeof event.target.dataset.approveLeave !== 'undefined'){
      this.approveLeaveRequest(item, parseInt(event.target.dataset.approveLeave));
      return;
     }

      item.label_type = '';
      if(item.type === LEAVE_TYPE.LEAVE_APPLICATION){
        item.label_type = 'Đơn xin nghỉ';
      } else if(item.type === LEAVE_TYPE.LEAVE_EARLY){
        item.label_type = 'Xin về sớm';
      } else if(item.type === LEAVE_TYPE.ADJUSTMENT_OF_ATTENDANCE){
        item.label_type = 'Kháng cáo bảng công';
      } else if(item.type === LEAVE_TYPE.VALUABLE_VACATION){
        item.label_type = 'Nghỉ có công';
      }
      this.$modal.show('leave-request-detail-modal', {leaveRequest: item})
    },
    getLeaveRequest(refreshPaging = false) {
      if (refreshPaging) {
        this.refreshPagingParams();
      }
      let paging = {
        page: this.pagingParams.page,
        per_page: this.pagingParams.perPage
      };
      TimeSheetService.getLeaves({...this.filterParams, ...paging}).then(res => {
        if (res && res.data) {
          this.leaveRequestList = res.data.filter((item) => this.filterParams.type ? item.type === this.filterParams.type : item);
          this.pagingParams.perPage = res.meta.per_page;
          this.pagingParams.total = res.meta.total;
        }
      });
    },
    renderRequestDateTitle(shift, date) {
      if(!date){
        return '-----';
      }

      const [year, month, day] = date.split('-');
      const mDate = new Date(year, month - 1, day);
      const options = {
        weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'
      };
      let dateStr = mDate.toLocaleDateString('vi-VN', options);
      return `Ca ${shift}, ${dateStr}`;
    },
    getApproveDescription(decisions) {
      let decision = decisions.find((item) => (item.status === 1 || item.status === 2) && item.description);
      return decision ? `[${decision?.user?.fullname ?? ''}] ${decision.description}` : '';
    },
    getStatusName(status) {
      let name = '';
      switch (status) {
        case 1:
          name = 'Đã duyệt';
          break;
          case 2:
            name = 'Từ chối';
            break
        case 3:
          name = 'Chờ duyệt';
          break
        case 4:
          name = 'Chờ điều chỉnh';
          break
      }
      return name;
    },
    refreshPagingParams() {
      this.pagingParams = {
        page: 1,
        perPage: 50,
        lastPage: 1,
        total: 0,
      };
    },
    changePagingParam(value) {
      this.pagingParams = value;
      this.getLeaveRequest();
    },

    dropDownList(selected = null){
     
      switch (selected) {
        case LEAVE_TYPE.LEAVE_APPLICATION:
        case LEAVE_TYPE.LEAVE_EARLY:
        case LEAVE_TYPE.ADJUSTMENT_OF_ATTENDANCE:
        case LEAVE_TYPE.VALUABLE_VACATION:
          let category = this.leave_categories.filter((item) => item.type === selected);
          if(category.length === 1){
            this.$modal.show('create-leave-request-modal', {requestGroup: category[0], type: selected})
          }else {
            this.$modal.show('choosing-request-group-modal', {type: selected});
          }
          break;
      }
      this.selectedModal.selected = selected;
      this.selectedModal.show = !this.selectedModal.show;
    },

    changeType(type){
      this.filterParams.type = type;
      this.getLeaveRequest();
    },
    approveLeaveRequest(item, status){
      console.log(item)
      // let textByType = status === 1 ? 'PHÊ DUYỆT' : 'TỪ CHỐI';
      // this.$modal.show('dialog', {
      //     title: textByType,
      //     text: `Bạn có chắc muốn ${textByType} đề xuất ${item.name}?`,
      //     buttons: [
      //         {
      //             title: 'Đóng',
      //             handler: () => {
      //                 this.$modal.hide('dialog')
      //             }
      //         },
      //         {
      //             title: 'Đồng ý',
      //             handler: () => {
      //               TimeSheetService.approveLeaveRequest({leave_id: item.id, status: status}).then(res => {
      //                   if (res && res.data) {
      //                     this.getLeaveRequest();
      //                   }
      //                 });
      //                 this.$modal.hide('dialog')
      //             }
      //         }
      //     ]
      // })
      this.$modal.show("approval-reason-modal", {leaveRequestData: item, status: status});
      
    },
    formatMoment(valueFormat, format = 'YYYY-MM-DD'){
      moment.locale('vi');
      return moment(valueFormat).format(format);
    },
    leaveItemCategories(item){
      this.leave_categories = item;
    }
  }
};
</script>

<style lang="scss" scoped>
.time-off-content-container {
  height: 100%;

  .header{
    .dropdown-list {
      position: absolute;
      top: 40px;
      right: 0;
      background: #fff;
      border: 1px solid #f9f9f9;
      color: #000;
      width: max-content;
      border-radius: 5px;
      line-height: 25px;
      text-align: left;
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
      z-index: 10;
      .btn-item{
        padding: 5px 15px;
        &:hover{
          cursor: pointer;
          background-color: #d8d8d8;
        }
      }
    }
  }
  .body-wrapper {
    position: relative;
    display: block;

    .mode-layout{
      margin-bottom: 10px;
      .btn{
        color: #000;
        margin-right: 10px;
      }
      .btn-active-type{
        border: 1px solid #E3E3E3;
        color: #09c705;
      }
    }
    .side-layout {
      margin-bottom: 15px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .md-radio {
        font-size: 14px !important;
        margin: 0 16px 0 0;
        .md-radio-label {
          color: #1d1d1d !important;
        }
      }
    }

    .main-layout {
      table {
        tbody tr {
          cursor: pointer;
        }
      }
      .status-background-1 {
        background-color: #14cc3f;
        color: #fff;
      }

      .status-background-2 {
        background-color:#ff1a1a;
        color:#fff
      }

      .status-background-3 {
        background-color:#FCAF17;
        color:#fff;
      }

      .status-background-4 {
        background-color:#FCAF17;
        color:#fff;
      }

      .datetime-show{
        text-transform: capitalize;
      }
    }
  }

  .base-pagination {
    position: relative;
    font-size: 13px;
    color: #999;
    padding: 20px;
  }
}
</style>
  