<template>
    <div class="md-gutter pr-reward-program-layout">
        <div class="tab-pr">
            <div class="pr-content comming cursor-pointer">
                <span :class="{'tab-active': type == CONST.ONGOING}" @click="filterData(CONST.ONGOING)">Đang diễn ra</span>
            </div>
            <div class="pr-content incoming cursor-pointer">
                 <span :class="{'tab-active': type == CONST.UPGOING}" @click="filterData(CONST.UPGOING)">Sắp diễn ra</span>
            </div>
            <div class="pr-content close cursor-pointer">
                 <span :class="{'tab-active': type == CONST.EXPIRED}" @click="filterData(CONST.EXPIRED)">Đã hết hạn</span>
            </div>
        </div>
        <div class="table-pr">
            <vue-element-loading :active="isLoading" spinner="bar-fade-scale" />
            <template v-if="tableData.total !== 0">
                <ve-table
                :border-x="true"
                :border-y="true"
                :border-around="true"
                row-key-field-name="rowKey"
                :columns="columns"
                :table-data="tableData.campaigns"
                :event-custom-option="eventCustomOption"
                :sort-option="sortOption"
                :cell-style-option="cellStyleOption"
                :checkbox-option="checkboxOption"
                :cell-span-option="cellSpanOption"
             />
            </template>
            <template v-else>
                <div class="content-not-empty">
                    Không có dữ liệu
                </div>
            </template>
            <div class="load-more-pr" v-if="this.tableData.hasMore" @click="loadMore()">
                <span>Xem thêm</span>
            </div>
        </div>
        <PrRewardProgramDetailModal/>
    </div>
  </template>
  
  <script>
  import {helpers} from "../../../../helper/helpers";
  import moment from "moment";
  import DashboardService from "@/store/services/DashboardService";
  import PrRewardProgramDetailModal from "@/pages/Components/Modal/PrRewardProgramDetailModal"

  export default {
    name: "PrRewardProgram",
    components: {
        PrRewardProgramDetailModal
    },
    data() {
      return {
        CONST: {
            ONGOING: 1,
            UPGOING: 2,
            EXPIRED: 3
        },
        tableData: {
            campaigns: [],
            total: 0,
            hasMore: false
        },
        columns: [],
        eventCustomOption: {},
        sortOption: {},
        cellStyleOption: {},
        checkboxOption: {},
        cellSpanOption: {},
        dataItems: {
            total: 0
        },
        params: {
            date_from: null
        },
        type: 1,
        isLoading: false,
        currentOffset: 0,
        limit: 3,
        allCampaigns: [],
      };
    },
    created(){
        this.columns = [
            { 
                field: "title",
                key: "a",
                title: "Tiêu đề",
                align: "left"
            },
            { 
                field: "teamid",
                key: "aa",
                title: "Phạm vi",
                align: "left",
                renderBodyCell: ({ row, column, rowIndex }, h) => {
                    let teamName = 'Toàn công ty';
                    if (row.team && row.team.id) {
                        teamName = row.team.name;
                    }
                    return teamName
                }
            },
            { 
                field: "date",
                key: "b",
                title: "Thời gian diễn ra",
                align: "left",
                renderBodyCell: ({ row, column, rowIndex }, h) => {
                    return (
                        <div>
                            {moment(row.date_from).format('DD/MM/YYYY')} - {moment(row.date_to).format('DD/MM/YYYY')}
                        </div>
                    );
                }
            },
            { 
                field: "ctthuong",
                key: "c",
                title: "CT thưởng",
                align: "left",
                renderBodyCell: ({ row, column, rowIndex }, h) => {
                    let ct_t = '';
                    if (row.daily_multiple > 1) {
                        ct_t += 'X' + row.daily_multiple + ' Daily Deals';
                    }
                    if (row.product_multiple > 1) {
                        if (ct_t !== '') {
                            ct_t += ', ';
                        }
                        ct_t += 'X' + row.product_multiple + ' - SP mới';
                    }
                    return (
                        <div>{ct_t}</div>
                    );
                }
            },
            { 
                field: "ads_percent",
                key: "d",
                title: "%CPQC hợp lệ",
                align: "center",
                width: 100
            },
            { 
                field: "note",
                key: "e",
                title: "Ghi chú",
                align: "left",
                renderBodyCell: ({ row, column, rowIndex }, h) => {
                    let max_reward_amount = '';
                    let ignore = '';
                    if (row.max_reward_amount) {
                        max_reward_amount = 'Tối đa ' + helpers.formatCurrency(row.max_reward_amount) + 'đ';
                    }
                    if (row.product_ignore == 1) {
                        ignore += 'Không tính thưởng SP mới';
                    }
                    if (row.daily_ignore == 1) {
                        if (ignore !== '') {
                            ignore += ' , ';
                        }
                        ignore += 'không tính thưởng Daily Deals';
                    }
                    return (
                        <div>{max_reward_amount + (ignore ? ', ' + ignore : '')}</div>
                    );
                }
            },
            {
                field: "action",
                key: "cc",
                title: "",
                align: "center",
                width: 50,
                renderBodyCell: ({ row, column, rowIndex }, h) => {
                    return (
                        <div class="show-detail cursor-pointer" on-click={() => this.$modal.show('pr-reward-program-detail', {data: row})}>
                            <md-icon>info</md-icon>
                        </div>
                    );
                }
            }
        ];

        this.getPrProgram();
    },
    methods: {
        async getPrProgram(){
            this.isLoading = true;
            await DashboardService.getPrProgram(this.params).then(res => {
                if(res){
                    this.allCampaigns = res.data;
                    let items = [1, 2, 3];
                    for (let index = 0; index < items.length; index++) {
                        const element = items[index];
                        this.filterCampaigns(this.allCampaigns, element);
                        if(this.tableData.campaigns.length > 0){
                            this.type = element;
                            break;
                        }
                    }
                }
            });

            this.isLoading = false;
        },
        filterData(type){
            this.currentOffset = 0;
            this.filterCampaigns(this.allCampaigns, type);
            this.type = type;
        },

        filterCampaigns(data, type = null, offset = 0) {
            const currentDate = new Date();
            const campaigns = data.filter(campaign => {
                const dateFrom = new Date(campaign.date_from);
                const dateTo = new Date(campaign.date_to);


              // Đang diễn ra
                if (currentDate >= dateFrom && currentDate <= dateTo) {
                    campaign.status = this.CONST.ONGOING;
                }
                // Sắp diễn ra
                else if (currentDate < dateFrom) {
                    campaign.status = this.CONST.UPGOING;
                }
                // Đã hết hạn
                else {
                    campaign.status = this.CONST.EXPIRED;
                }
                
                return true;
            });
            
            let filteredCampaigns;
   
            if (type !== null) {
                filteredCampaigns = campaigns.filter(campaign => campaign.status === type);
            } else {
                const ongoing = campaigns.filter(campaign => campaign.status === this.CONST.ONGOING);
                if (ongoing.length > 0) {
                    filteredCampaigns = ongoing;
                } else {
                    const upcoming = campaigns.filter(campaign => campaign.status === this.CONST.UPGOING);
                    if (upcoming.length > 0) {
                        filteredCampaigns = upcoming;
                    } else {
                        filteredCampaigns = campaigns.filter(campaign => campaign.status === this.CONST.EXPIRED);
                    }
                }
            }

            const paginatedCampaigns = filteredCampaigns.slice(offset, offset + this.limit);
            this.tableData = {
                campaigns: paginatedCampaigns,
                hasMore: offset + this.limit < filteredCampaigns.length,
                total: filteredCampaigns.length
            };
        },
        loadMore() {
            this.currentOffset += this.limit;
            this.filterCampaigns(this.allCampaigns, this.type, this.currentOffset);
        }
    }
  }
  </script>
  
  <style lang="scss">
  .pr-reward-program-layout {
    padding: 10px 5px 0px !important;
    background-image: url(https://d1fee2zp1b6u2o.cloudfront.net/assets/media/pastel-blue-banner-background-1048-11857_1.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: 1px solid rgba(0, 149, 255, 0.24);
    .ve-table-header-th{
        background-color: #fff !important;
    }
    .pr-reward-program-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .pr-reward-program-title {
            text-transform: uppercase;
        }
        .pr-reward-program-see-detail {
            a{
                color: rgba(23, 101, 252, 1) !important;
            font-size: 14px;
            }
            .md-icon{
                color: rgba(23, 101, 252, 1) !important;
                font-size: 18px !important;
            }
        }
    }
    .vue-table-root{
        width: 100%;
        .ve-table-body-td{
            color: #000;
            font-weight: 500;
        }
    }
    .product-return{
        display: flex;
        grid-gap: 10px;
        align-items: center;
        .image-product{
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            img{
                border-radius: 4px;
            }
        }
        .title-product {
            font-size: 13px;
            font-weight: 500;
            color: rgba(16, 24, 64, 1);
        }
    }
    .load-more-pr {
        &:hover{
            cursor: pointer;
        }
        width: 100%;
        padding: 5px;
        font-size: 14px;
        text-align: center;
        border-left: 0.5px solid #EEEEEE;
        border-right: 0.5px solid #EEEEEE;
        border-bottom: 0.5px solid #EEEEEE;
        color: #000;
        font-weight: 500;
        background: #fff;
    }
    .table-pr{
        min-height: 240px;
        position: relative;
        .content-not-empty {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .pr-content{
        position: relative;
        padding: 5px;
        color: #000;
        font-size: 15px;
      .tab-active {
        font-weight: 600;
        color: #ff7b00;
        &:after {
            background: #ff7b00;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 2px;
            border-radius: 4px;
            content: "";
            left: 0;
        }
      }
    }
    .tab-pr {
        display: flex;
        grid-gap: 10rem;
        justify-content: center;
        margin-bottom: 10px;
    }
}
  </style>