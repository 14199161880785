export default {
    state: {
        posts: [],
        showCreateGroupModal: false,
    },

    getters: {
        getPost(state) {
            return state.posts;
        },
        getShowCreateGroupModal(state) {
            return state.showCreateGroupModal;
        }
    },

    mutations: {
        updatePost(state, payload) {
            state.posts = payload.data;
        },
        updateShowCreateGroupModal(state, payload) {
            state.showCreateGroupModal = payload;
        }
    },

    actions: {
        // getPost({commit, dispatch}) {
        //     return PostService.getPosts().then(res => {
        //         if (res && res.status) {
        //             context.commit("updatePost", res);
        //         }
        //     });
        // },
    }
};
