// import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";
import ContentLayout from "@/pages/Layout/ContentLayout.vue";

// Components pages


//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

// // Dashboard pages
// // Profile
import UserProfile from "@/pages/Dashboard/Examples/UserProfile.vue";
// // User Management
import ListUserPage from "@/pages/Dashboard/Examples/UserManagement/ListUserPage.vue";

//Auth
import Login from "@/pages/Dashboard/Pages/Login.vue";

// Workplace Pages
import NewFeeds from "@/pages/Workplace/Pages/NewFeeds.vue";
// import Group from "@/pages/Workplace/Pages/Group.vue";
// import Category from "@/pages/Workplace/Pages/Category.vue";
// import News from "@/pages/Workplace/Pages/News.vue";
// Admin Pages
import Dashboard from "@/pages/Workplace/Pages/Dashboard/Dashboard";
import StatisticalContent from "@/pages/Workplace/Pages/Statistical/StatisticalContent";
import TimeKeepingContent from "@/pages/Workplace/Pages/Timekeeping/TimeKeepingContent";
import Personal from "@/pages/Workplace/Pages/Personal";
import TimeOffContent from "../pages/Workplace/Pages/TimeOff/TimeOffContent";
// TableList pages
// import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";

let componentsMenu = {
  // path: "/components",
  // component: DashboardLayout,
  // redirect: "/components/notification",
  // name: "Components",
  // children: [
  //   {
  //     path: "table",
  //     name: "Table",
  //     components: { default: RegularTables },
  //     meta: { middleware: auth }
  //   },
  //   {
  //     path: "typography",
  //     name: "Typography",
  //     components: { default: Typography },
  //     meta: { middleware: auth }
  //   },
  //   {
  //     path: "icons",
  //     name: "Icons",
  //     components: { default: Icons },
  //     meta: { middleware: auth }
  //   },
  //   {
  //     path: "notifications",
  //     name: "Notifications",
  //     components: { default: Notifications },
  //     meta: { middleware: auth }
  //   },
  // ]
};
let examplesMenu = {
  path: "/examples",
  component: ContentLayout,
  name: "Examples",
  children: [
    {
      path: "user-profile",
      name: "User Profile",
      components: {default: UserProfile},
      meta: {middleware: auth}
    },
    {
      path: "user-management/list-users",
      name: "List Users",
      components: {default: ListUserPage},
      meta: {middleware: auth}
    }
  ]
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {middleware: guest}
    },
  ]
};

let workplacePages = {
  path: "/",
  component: ContentLayout,
  meta: {middleware: auth},
  children: [
    {
      path: "dashboard",
      name: "Dashboard",
      components: {default: Dashboard},
      meta: {middleware: auth, tab: 'dashboard'}
    },
    // {
    //   path: "thong-ke",
    //   name: "Thống kê",
    //   components: {default: StatisticalContent},
    //   meta: {middleware: auth, tab: 'thong-ke'}
    // },
    {
      path: "cham-cong",
      name: "Chấm công",
      components: {default: TimeKeepingContent},
      meta: {middleware: auth, tab: 'cham-cong'}
    },
    // {
    //   path: "thanh-tich",
    //   name: "Thành tích",
    //   components: {default: Dashboard},
    //   meta: {middleware: auth, tab: 'thanh-tich'}
    // },
    {
      path: "timeoff",
      name: "Đề xuất",
      components: {default: TimeOffContent},
      meta: {middleware: auth, tab: 'timeoff'}
    },
    {
      path: "ca-nhan",
      name: "Cá nhân",
      components: {default: Personal},
      meta: {middleware: auth, tab: 'ca-nhan'}
    },
  ]
};

// let adminPages = {
//   path: "/dashboard",
//   component: ContentLayout,
//   meta: {middleware: auth},
//   children: [
//     {
//       path: "dashboard",
//       name: "Dashboard",
//       components: {default: Dashboard},
//       meta: {middleware: auth}
//     }
//   ]
// };

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Dashboard",
    // components: {default: Dashboard},
    // meta: {middleware: auth}
  },
  authPages,
  workplacePages,
  // adminPages,
];

export default routes;
