<template>
  <div class="full-page" :class="{ 'nav-open': $sidebar.showSidebar }">
<!--    <md-toolbar md-elevation="0" class="md-transparent md-toolbar-absolute">-->
<!--      <div class="md-toolbar-row md-offset">-->
<!--        <div class="md-toolbar-section-start">-->
<!--          <h3 class="md-title">{{ $route.name }}</h3>-->
<!--        </div>-->
<!--        <div class="md-toolbar-section-end">-->
<!--          <md-button-->
<!--            class="md-just-icon md-simple md-round md-toolbar-toggle"-->
<!--            :class="{ toggled: $sidebar.showSidebar }"-->
<!--            @click="toggleSidebar"-->
<!--          >-->
<!--            <span class="icon-bar"></span>-->
<!--            <span class="icon-bar"></span>-->
<!--            <span class="icon-bar"></span>-->
<!--          </md-button>-->

<!--          <div-->
<!--            class="md-collapse"-->
<!--            :class="{ 'off-canvas-sidebar': responsive }"-->
<!--          >-->
<!--            <md-list>-->
<!--&lt;!&ndash;              <md-list-item href="/register" @click="linkClick">&ndash;&gt;-->
<!--&lt;!&ndash;                <md-icon>person_add</md-icon>&ndash;&gt;-->
<!--&lt;!&ndash;                Register&ndash;&gt;-->
<!--&lt;!&ndash;              </md-list-item>&ndash;&gt;-->
<!--              <md-list-item href="/login" @click="linkClick" v-if="!$store.getters['isAuthenticated']">-->
<!--                <md-icon>fingerprint</md-icon>-->
<!--                Login-->
<!--              </md-list-item>-->
<!--            </md-list>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </md-toolbar>-->
    <div class="wrapper wrapper-full-page" @click="toggleSidebarPage">
      <notifications/>
      <div
        class="page-header header-filter"
        :class="setPageClass"
        filter-color="black"
        :style="setBgImage"
      >
        <div class="container md-offset">
          <zoom-center-transition
            :duration="pageTransitionDuration"
            mode="out-in"
          >
            <router-view></router-view>
          </zoom-center-transition>
        </div>
<!--        <footer class="footer">-->
<!--          <div class="container md-offset">-->
<!--            <nav>-->
<!--              <ul>-->
<!--                <li>-->
<!--                  <a href="https://www.creative-tim.com" target="_blank">-->
<!--                    Creative Tim-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="https://www.updivision.com/" target="_blank">-->
<!--                    UPDIVISION-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="https://creative-tim.com/presentation" target="_blank">-->
<!--                    About Us-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="http://blog.creative-tim.com" target="_blank">-->
<!--                    Blog-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <a href="https://www.creative-tim.com/license" target="_blank">-->
<!--                    Licenses-->
<!--                  </a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </nav>-->
<!--            <div class="copyright text-center">-->
<!--              &copy; {{ new Date().getFullYear() }},-->
<!--              made with <i class="fa fa-heart heart"></i>-->
<!--              by-->
<!--              <a href="https://www.creative-tim.com/?ref=mdp-vuejs-api" target="_blank">Creative Tim</a>-->
<!--              and-->
<!--              <a href="https://www.updivision.com/?ref=mdp-vuejs-api" target="_blank">UPDIVISION</a>-->
<!--              for a better web.-->
<!--            </div>-->
<!--          </div>-->
<!--        </footer>-->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: "black"
    }
  },
  inject: {
    autoClose: {
      default: true
    }
  },
  data() {
    return {
      responsive: false,
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 300,
      year: new Date().getFullYear()
    };
  },
  computed: {
    setBgImage() {
      let images = {
        Login: process.env.VUE_APP_BASE_URL + "/img/login.jpg",
        Register: process.env.VUE_APP_BASE_URL + "/img/register.jpg",
      };
      return {
        backgroundImage: `url(${images[this.$route.name]})`
      };
    },
    setPageClass() {
      return `${this.$route.name}-page`.toLowerCase();
    }
  },
  methods: {
    toggleSidebarPage() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    linkClick() {
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    }
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    this.closeMenu();
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  }
};
</script>
<style lang="scss" scoped>
  .header-filter .container {
    z-index: 20;
    position: relative;
    height: 100vh;
  }
$scaleSize: 0.1;
$zoomOutStart: 0.7;
$zoomOutEnd: 0.46;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}
.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}
@keyframes zoomOut8 {
  from {
    opacity: 1;
    transform: scale3d($zoomOutStart, $zoomOutStart, $zoomOutStart);
  }
  to {
    opacity: 0;
    transform: scale3d($zoomOutEnd, $zoomOutEnd, $zoomOutEnd);
  }
}
.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
.full-page {
    background: #6994b8 url(/img/login2.jpg);
    background-size: cover;
}
</style>
