export const LATE_COUNT_PENALTY = {
    0 : [0, 0],
    1 : [0, 0],
    2 : [0, 0],
    3 : [0.5, 0],
    4 : [1, 0],
    5 : [3, 1000000],
    6 : [5, 1000000],
    7 : [7, 1000000],
    8 : [9, 1000000],
    9 : [11, 1000000],
    10 : [13, 1000000],
    11 : [15, 1000000],
    12 : [17, 1000000],
    13 : [19, 1000000],
    14 : [21, 1000000],
    15 : [23, 1000000],
    16 : [25, 1000000],
    17 : [27, 1000000],
    18 : [29, 1000000],
    19 : [29, 1000000],
    20 : [29, 1000000],
    21 : [29, 1000000],
    22 : [29, 1000000],
    23 : [29, 1000000],
    24 : [29, 1000000],
    25 : [29, 1000000],
    26 : [29, 1000000],
    27 : [29, 1000000],
    28 : [29, 1000000],
    29 : [29, 1000000],
    30 : [29, 1000000],
    31 : [29, 1000000],
};
export const EARLY_COUNT_PENALTY = {
    0 : [0, 0],
    1 : [0, 0],
    2 : [0, 0],
    3 : [0, 100000],
    4 : [0, 200000],
    5 : [0, 400000],
    6 : [0, 600000],
    7 : [0, 800000],
    8 : [0, 1000000],
    9 : [0, 1200000],
    10 : [0, 1400000],
    11 : [0, 1600000],
    12 : [0, 1800000],
    13 : [0, 2000000],
    14 : [0, 2200000],
    15 : [0, 2400000],
    16 : [0, 2600000],
    17 : [0, 2800000],
    18 : [0, 3000000],
    19 : [0, 3200000],
    20 : [0, 3400000],
    21 : [0, 3600000],
    22 : [0, 3800000],
    23 : [0, 4000000],
    24 : [0, 4200000],
    25 : [0, 4400000],
    26 : [0, 4600000],
    27 : [0, 4800000],
    28 : [0, 5000000],
    29 : [0, 5200000],
    30 : [0, 5400000],
    31 : [0, 5600000],
};

export const MISS_CHECKIN_COUNT_PENALTY = {
    0 : [0, 0],
    1 : [0.5, 0],
    2 : [1.5, 0],
    3 : [3.5, 0],
    4 : [5.5, 0],
    5 : [7.5, 1000000],
    6 : [9.5, 1000000],
    7 : [11.5, 1000000],
    8 : [13.5, 1000000],
    9 : [15.5, 1000000],
    10 : [17.5, 1000000],
    11 : [19.5, 1000000],
    12 : [21.5, 1000000],
    13 : [23.5, 1000000],
    14 : [25.5, 1000000],
    15 : [27.5, 1000000],
    16 : [29.5, 1000000],
    17 : [31.5, 1000000],
    18 : [33.5, 1000000],
    19 : [35.5, 1000000],
    20 : [37.5, 1000000],
    21 : [39.5, 1000000],
    22 : [41.5, 1000000],
    23 : [43.5, 1000000],
    24 : [45.5, 1000000],
    25 : [47.5, 1000000],
    26 : [49.5, 1000000],
    27 : [51.5, 1000000],
    28 : [53.5, 1000000],
    29 : [55.5, 1000000],
    30 : [57.5, 1000000],
    31 : [59.5, 1000000],
};

export const LEAVE_INVALID_COUNT_PENALTY = {
    0: [0, 0],
    1: [0, 0],
    2: [0, 1000000],
    3: [0, 1000000],
    4: [0, 1000000],
    5: [0, 1000000],
    6: [0, 1000000],
    7: [0, 1000000],
    8: [0, 1000000],
    9: [0, 1000000],
    10: [0, 1000000],
    11: [0, 1000000],
    12: [0, 1000000],
    13: [0, 1000000],
    14: [0, 1000000],
    15: [0, 1000000],
    16: [0, 1000000],
    17: [0, 1000000],
    18: [0, 1000000],
    19: [0, 1000000],
    20: [0, 1000000],
    21: [0, 1000000],
    22: [0, 1000000],
    23: [0, 1000000],
    24: [0, 1000000],
    25: [0, 1000000],
    26: [0, 1000000],
    27: [0, 1000000],
    28: [0, 1000000],
    29: [0, 1000000],
    30: [0, 1000000],
    31: [0, 1000000],
};