<template>
    <div class="md-layout md-gutter statistical-layout">
        <div class="statistical-header">
            <div class="statistical-title">
                <span>CHI TIẾT THƯỞNG CÁ NHÂN </span>
            </div>
            <div class="statistical-see-detail">
                <span class="label-title cursor-pointer" @click="$modal.show('reward-regulation-modal')">Xem quy chế thưởng</span>
            </div>
        </div>
        <ve-table border-y
            row-key-field-name="rowKey"
            :cellSelectionOption="cellSelectionOption"
            :cell-style-option="cellStyleOption"
            :columns="columns"
            :table-data="tableData"
            max-height="calc(100vh - 120px)"
        />
        <RewardRegulationModal/>
    </div>
  </template>
  
  <script>
  import RewardRegulationModal from "../../../Components/Modal/RewardRegulationModal";
  export default {
    name: "StatisticalTable",
    components: {RewardRegulationModal},
    props: {
      tableData: {},
      columns: {
        type: Array,
        default: () => [],
      },
      tab: {
        type: Number,
        default: 1
      },
    },
    data() {
      return {
        cellSelectionOption: {
          // disble cell selection
          enable: false,
        },
        cellStyleOption: {
            bodyCellClass: ({ row, column, rowIndex }) => {
                if (column.field === "pecent_cpqc") {
                    return "table-body-pecent-cpqc";
                }
            },
        },
      };
    },
    mounted(){
    },
    created(){
    },
    methods: {}
  }
  </script>
  
  <style lang="scss">
  .statistical-layout {
    padding: 0 !important;
    .statistical-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .statistical-title {
            font-weight: 600;
            text-transform: uppercase;
            color: rgba(16, 24, 64, 1);
        }
        .statistical-see-detail {
            a{
                color: rgba(23, 101, 252, 1) !important;
            font-size: 14px;
            }
            .md-icon{
                color: rgba(23, 101, 252, 1) !important;
                font-size: 18px !important;
            }
            .label-title{
                text-transform: uppercase;
                text-decoration: underline;
                color: #F46777;
            }
        }
    }
    .vue-table-root{
        width: 100%;
        // .ve-table-container{
        //     &::-webkit-scrollbar {
        //         width: 6px;
        //         height: 6px;
        //     }
        //     &::-webkit-scrollbar-track {
        //         background: #f1f1f1;
        //     }
        //     &::-webkit-scrollbar-thumb {
        //         background: #8888887a;
        //         border-radius: 5px;
        //         &:hover {
        //         background: #555;
        //         }
        //     }
        // }
        .ve-table-header-tr {
            &:nth-child(2){
                th {
                  &:nth-child(5), &:nth-child(6), &:nth-child(7){
                        border-right: 1px solid #eee;
                    }
                    background-color: #fff !important;
                    border-right: none;
                }
            }
        }
        .achieved-amout{
            color: #00b11c;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.05000000074505806px;
            text-align: right;
        }
        .not-reached{
            color: rgba(16, 24, 64, 1);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.05000000074505806px;
            text-align: right;
            text-decoration: line-through;
        }
        .total-header-revenue{
            color: rgba(36, 0, 255, 1);
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.05000000074505806px;
        }
        .total-daily-label{
            color: rgba(16, 24, 64, 1);
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.05000000074505806px;
            text-align: right;

        }
        .duplicate-new-product{
            color: #ab3000;
            font-weight: 400;
        }
        .pecent-cpqc-lg{
            color: #d14343;
            height: 100%;
            display: flex;
            justify-content: end;
            align-items: center;
        }
    }
    .reward-name {
        word-break: break-all;
    }
    .total-reward{
        .reward-blue{
            color: rgba(36, 0, 255, 1);
            font-weight: 600;
        }
        .reward-red{
            color: rgba(255, 79, 79, 1);
            font-weight: 600;
            text-decoration: line-through;
        }
    }
}
  </style>