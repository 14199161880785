<template>
  <div class="dashboard-container">
    <div class="switch-mode-panel">
                <div class="switch-button">
                  <div class="switch-button-label-span" :class="{ 'switch-button-label-span-active': tabActive === 1 }"
                    @click="switchButton(1)">Doanh số ước tính</div>
                  <div class="switch-button-label-span" :class="{ 'switch-button-label-span-active': tabActive === 2 }"
                    @click="switchButton(2)">Doanh số thực tế</div>
                </div>
        </div>
        <!-- datepicker -->
        <div class="header-work-filter">
          <template v-if="[1,2,119].includes(user.id)">
            <div class="fitler-users cursor-pointer" @click="$modal.show('member-list-modal')">
              <div class="filter-info-user">
                <div class="avatar-filter">
                  <img :src="filterMember.avatar">
                </div>
                <div class="text-400-default text-1-line">
                  {{ filterMember.fullname }} {{ filterMember.id === user.id ? '(Bạn)' : '' }}
                </div>
              </div>
              <div class="drop-filter-icon">
                <md-icon>arrow_drop_down</md-icon>
              </div>
            </div>
          </template>
          <div class="datepicker cursor-pointer">
          <date-picker
            class="datetime-picked"
            placeholder="Select date range"
            v-model="timeRange"
            range-separator=" - "
            format="DD/MM/YYYY"
            type="month"
            :clearable="false"
            :confirm="true"
            :confirm-text="'Xác nhận thời gian tìm kiếm'"
            :lang="lang"
            @change="changeDate"
            :range="false"
            :disabled-date="disabledBeforeTodayAndAfterMonth"
          >
          <template v-slot:input>
            <input v-model="dateSelection" type="text" name="date"
            readonly
            autocomplete="off"
            placeholder="Chọn thời gian"
            class="mx-input" />
            </template>
            <template v-slot:icon-calendar>
              <md-icon>arrow_drop_down</md-icon>
            </template>
          </date-picker>
        </div>
        </div>
    <template v-if="isLoading">
      <div class="Loading-container">
        <vue-element-loading :active="isLoading" spinner="bar-fade-scale" />
      </div>
    </template>
    <template v-else>
      <div class="content-container">
        <div class="layout-container">
   
     <!-- thu nhập / lương / Tổng thưởng -->
     <div class="md-layout md-gutter md-alignment-space-between-center revenue-layout">
       <div class="md-layout-item">
         <div class="layout-money income">
           <div class="title-money">
             <span>Tổng thưởng</span>
           </div>
           <div class="total-money">
             <span>
               {{ formatCurrency(totalHeaderTable.daily_reward_total + totalHeaderTable.product_reward_total)
               }}
             </span>
           </div>
         </div>
       </div>
       <div class="md-layout-item">
         <div class="layout-money salary">
           <div class="title-money">
             <span>Thưởng Daily Deals</span>
           </div>
           <div class="total-money">
             <span>
               {{ formatCurrency(totalHeaderTable.daily_reward_total)
               }}
             </span>
           </div>
         </div>
       </div>
       <div class="md-layout-item">
         <div class="layout-money total-reward">
           <div class="title-money">
             <span>Thưởng sản phẩm mới</span>
           </div>
           <div class="total-money">
             <span>{{ formatCurrency(totalHeaderTable.product_reward_total) }}</span>
           </div>
         </div>
       </div>
     </div>
     <div class="md-layout md-gutter">
       <div class="layout-item md-layout-item md-size-63 md-xsmall-size-100 md-medium-size-100">
       <div>
         <!-- chỉ số đơn hàng - doanh số -->
         <div class="md-layout md-gutter md-layout-item md-size-100 md-alignment-space-between-center revenue-index-layout">
           <div class="md-layout-item">
             <div class="revenue-index__title">
               <span>Tổng đơn / Hoàn thành</span>
             </div>
             <div class="revenue-index__number">
               <span v-if="revenueOverView && revenueOverView.total && revenueOverView.total.total_order">
                 {{ revenueOverView.total.total_order }} / {{ revenueOverView.total.done_order }}
               </span>
               <span v-else>0</span>
             </div>
           </div>
           <div class="md-layout-item">
             <div class="revenue-index__title">
               <span>Doanh thu</span>
             </div>
             <div class="revenue-index__number">
               <span v-if="revenueOverView && revenueOverView.total && revenueOverView.total.revenue">
                 {{ formatCurrency(revenueOverView.total.revenue) }}
               </span>
               <span v-else>0</span>
             </div>
           </div>
           <div class="md-layout-item">
             <div class="revenue-index__title">
               <span>CPQC</span>
             </div>
             <div class="revenue-index__number">
               <span v-if="revenueOverView && revenueOverView.total && revenueOverView.total.ads_cost">
                 {{ formatCurrency(revenueOverView.total.ads_cost) }}
               </span>
               <span v-else>0</span>
             </div>
           </div>
           <div class="md-layout-item">
             <div class="revenue-index__title">
               <span>%CPQC</span>
             </div>
             <div class="revenue-index__number">
               <span v-if="revenueOverView && revenueOverView.total && revenueOverView.total.ads_cost">
                 {{ revenueOverView.total.revenue === 0 ? 0 : ((revenueOverView.total.ads_cost / revenueOverView.total.revenue) * 100).toFixed(2) }}
               </span>
               <span v-else>0</span>
             </div>
           </div>
         </div>
         <!-- chart -->
         <div class="md-layout md-gutter chart-layout">
           <LineChartData :chartData="lineChart.chartData" 
           :chartOptions="lineChart.chartOptions" 
           :styles="{ height: '310px' }"
           />
         </div>
       </div>

       <!-- Sản phẩm Không tính tỉ lệ hoàn -->
       <!-- <ReturnableProduct /> -->
       </div>
       <!-- Right Layout -->
       <div class="layout-right md-layout-item md-size-30  md-medium-size-40 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
         <!-- Top Rank Aza -->
         <div class="layout-right-child top-rank-member">
           <div class="right-title d-flex-center-between">
             <div class="head-text">AZA LEADERBOARD <span class="live-button">Live</span></div>
             <div class="head-detail d-flex-center-between">
             <div class="rank-week title-popular" :class="{ 'tab-active': tabTopRankActive === 1 }" @click="switchTopRank(1)">
                 <p class="button-switch-top">Tuần</p>
             </div>
             <div class="rank-month title-popular" :class="{ 'tab-active': tabTopRankActive === 2 }" @click="switchTopRank(2)">
                 <p class="button-switch-top">Tháng</p>
             </div>
             </div>
           </div>
           <div class="content-right">
             <md-list class="md-double-line member-top-list">
               <vue-element-loading :active="isLoadingRank" spinner="bar-fade-scale" />
               <div class="loading-top-rank" v-if="!isLoadingRank && topRevenue.list.length === 0">
                 Không có dữ liệu
               </div>
               <md-list-item class="rank-top-list" v-for="(item, index) in topRevenue.list" :key="index">
                 <md-avatar>
                   <img :src="item.marketing.avatar ? item.marketing.avatar : '/logo_128.png'" alt="achievements">
                 </md-avatar>
                 <div class="md-list-item-text">
                   <span class="rank-member-title title-popular" style="margin-bottom: 2px">{{ item.marketing.fullname }}</span>
                   <span class="text-200-default neutral_2" style="margin-bottom: 2px">{{ item.marketing.team.agency }}</span>
                   <span class="text-200-default neutral_2" style="margin-bottom: 2px">{{ item.marketing.team.department }}</span>
                   <span class="text-200-default neutral_2">{{ item.marketing.team.team }}</span>
                 </div>
                 <div class="md-list-action">
                   <span class="member-rank-money">{{ convertToText(item.revenue) }}</span>
                 </div>
               </md-list-item>
               <!-- <md-list-item>
               <md-avatar>
                 <img src="img/new_logo.png" alt="achievements">
               </md-avatar>
               <div class="md-list-item-text">
                 <span class="rank-member-title title-popular">HOÀNG THU HÀ</span>
                 <span>UNIQUE TEAM</span>
               </div>
               <div class="md-list-action">
                 <span class="member-rank-money">142.812.451</span>
               </div>
             </md-list-item>
             <md-list-item>
               <md-avatar>
                 <img src="img/new_logo.png" alt="achievements">
               </md-avatar>
               <div class="md-list-item-text">
                 <span class="rank-member-title title-popular">ĐĂNG DUY KHOA</span>
                 <span>UNIQUE TEAM</span>
               </div>
               <div class="md-list-action">
                 <span class="member-rank-money">122.958.564</span>
               </div>
             </md-list-item> -->
             </md-list>
           </div>
         </div>
         <!-- END TOP RANK -->
         <div class="layout-right-child d-none">
           <div class="right-title d-flex-center-between">
             <div class="head-text">Thành tích cá nhân</div>
             <div class="head-detail">
               <a href="#" class="link-href">Xem chi tiết <md-icon>chevron_right</md-icon></a>
             </div>
           </div>
           <div class="content-right">
             <md-list class="md-double-line layout-top-member" v-if="rewardDaily.length > 0">
               <md-subheader>Thưởng Daily</md-subheader>
               <!-- Thưởng daily -->
               <md-list-item v-for="(item, index) in rewardDaily" :key="index">
                 <md-avatar>
                   <img src="img/dashboard/logo-achievements.png" alt="achievements">
                 </md-avatar>
                 <div class="md-list-item-text">
                   <span class="bonus-money-title">{{ item.pr_reward.name }}</span>
                   <div class="bonus-total-money">
                     <span class="bonus-sale-total">
                       Doanh số: {{ formatCurrency(item.pr_reward.revenue) }}
                     </span>
                     <span class="bonus-sale-total-condition">
                       > {{ formatCurrency(item.pr_reward.revenue) }}
                     </span>
                   </div>
                   <div class="bonus-total-money bonus-pecent">
                     <span class="bonus-sale-total">
                       %CPQC: {{ item.final_cost_percent }}%
                     </span>
                     <span class="bonus-sale-total-condition">
                       &lt; {{ item.pr_reward.cost_percent }}%
                     </span>
                   </div>
                 </div>
                 <div class="md-list-action">
                   <span class="bonus_money">+{{ formatCurrency(item.pr_reward.amount) }}</span>
                 </div>
               </md-list-item>
             </md-list>
             <!-- Thưởng sản phẩm mới -->
             <md-list class="md-double-line layout-top-member" v-if="rewardProduct.length > 0">
               <md-subheader>THƯỞNG SẢN PHẨM MỚI</md-subheader>
               <md-list-item v-for="(item, index) in rewardProduct" :key="index">
                 <md-avatar>
                   <img src="img/dashboard/logo-achievements.png" alt="achievements">
                 </md-avatar>
                 <div class="md-list-item-text">
                   <span class="bonus-money-title">{{ item.pr_reward.name }}</span>
                   <div class="bonus-total-money">
                     <span class="bonus-sale-total">
                       Doanh số: {{ formatCurrency(item.pr_reward.revenue) }}
                     </span>
                     <span class="bonus-sale-total-condition">
                       > {{ formatCurrency(item.pr_reward.revenue) }}
                     </span>
                   </div>
                   <div class="bonus-total-money bonus-pecent">
                     <span class="bonus-sale-total">
                       %CPQC: {{ item.final_cost_percent }}%
                     </span>
                     <span class="bonus-sale-total-condition">
                       &lt; {{ item.pr_reward.cost_percent }}%
                     </span>
                   </div>
                 </div>
                 <div class="md-list-action">
                   <span class="bonus_money">+{{ formatCurrency(item.pr_reward.amount) }}</span>
                 </div>
               </md-list-item>
             </md-list>
           </div>
         </div>
         <!--  -->
         <div class="layout-right-child career-path d-none">
           <div class="right-title d-flex-center-between">
             <div class="head-text">Lộ trình thăng tiến</div>
             <div class="head-detail">
               <a href="#" class="link-href">Xem chi tiết <md-icon>chevron_right</md-icon></a>
             </div>
           </div>
           <div class="content-right">
             <div class="timeline-container">
               <div class="timeline__event animated fadeInUp" 
                 v-for="(item, index) in roadMapPromotion" 
                 :key="index"
                 :class="{ 'timeline__event_first': index === 0 }"
               >
                 <div class="timeline__event__icon ">
                   <div class="timeline__event__date">
                   {{ item.apply_date }}
                   </div>
                 </div>
                 <div class="timeline__event__content ">
                   <div class="timeline__event__title">
                     {{ item.role.display_name }}
                   </div>
                   <div class="timeline__event__description">
                     {{ item.team.department ? `${item.team.agency} > ` : `${item.team.agency}` }}
                     {{ item.team.team ? `${item.team.department} > ` : `${item.team.department}` }}
                     {{ item.team.team }}
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
    <!--  -->
    <div class="mt-15px mb-20px">
      <PrRewardProgram />
    </div>
    <!--  -->
    <StatisticalTable :tableData="revenueOverView.daily" :columns="columnsTable" :tab="tabActive" />
  </div>
  </template>
  <member-list-modal @filterByUser="filterByUser" ></member-list-modal>
  <ConfirmDownLoadImageModal></ConfirmDownLoadImageModal>
  </div>
</template>
<script>
import StatisticalTable from "./StatisticalTable"
// import ReturnableProduct from "./ReturnableProduct"
import LineChartData from "../Chart/LineChartData"
import DatePicker from 'vue2-datepicker';
import moment from "moment";
import vue2DatePicker from "@/mixins/config-vue2-datepicker";
import DashboardService from "@/store/services/DashboardService";
import EmployeeService from "@/store/services/EmployeeService";
import {helpers} from "../../../../helper/helpers";
import MemberListModal from "../../../Components/Modal/MemberListModal.vue";
import ConfirmDownLoadImageModal from "../../../Components/Modal/ConfirmDownLoadImageModal"
import PrRewardProgram from "./PrRewardProgram";
export default {
  components: {
    StatisticalTable,
    // ReturnableProduct,
    LineChartData,
    DatePicker,
    MemberListModal,
    ConfirmDownLoadImageModal,
    PrRewardProgram
  },
  mixins: [vue2DatePicker],
  mounted() {
  },
  
  watch: {
  },

  data() {
    const date = new Date();
    return {
      user: this.$store.state.auth.user,
      tabActive: 1,
      isLoading: false,
      isLoadingRank: false,
      tabTopRankActive: 1,
      tab: this.$route.meta.tab,
      fromDate: '--:--',
      toDate: '--:--',
      dateSelection: '',
      timeRange: [
        new Date(date.getFullYear(), date.getMonth(), 1),// ngày tháng đầu tiên của tháng
        new Date() // giờ hiện tại
      ],
      lineChart: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Tổng đơn',
              data: [],
              borderColor: '#F765A3',
              backgroundColor: '#F765A3',
              borderWidth: 2,
              lineTension: 0.5,
              fill: 1,
              tension: 0.5,
              yAxisID: 'y',
            },
            {
              label: 'Doanh số',
              data: [],
              fill: false,
              borderColor: '#16BFD6',
              backgroundColor: '#16BFD6',
              borderWidth: 2,
              lineTension: 0.5,
              yAxisID: 'y1',
            }
          ]
        },
        chartOptions: {
          legend: {
            display: true
          },
          plugins: {
            legend: {
              align : 'end',
              paddingBottom: 30
            },
            datalabels: {
              display: false, 
            },
          },
          interaction: {
            mode: 'index',
            intersect: false,
          },
          stacked: false,
          scales: {
            y: {
              type: 'linear',
              display: true,
              position: 'left',
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              ticks: {
                callback: (value, index, values) => {
                  return helpers.convertToText(value);
                }
              },
              // grid line settings
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      },
      topRevenue: {
        params: {
          start_date: moment().startOf('week').format('YYYY-MM-DD'),
          end_date: moment().endOf('week').format('YYYY-MM-DD'),
          revenue_type: 1, // 1 - DSUT , 2 - DSTT
          department_type: null, // 1 - Chi nhánh, 2- Phòng, 3 - Team, null - Toàn công ty
          limit: 4
        },
        list: []
      },
      revenueOverviewOfUser: {
        params: {
          start_date: moment().startOf('month').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          revenue_type: 1, // 1 - DSUT , 2 - DSTT
          limit: 3,
          good_ids: [],
          country: [],
          marketing_platform: [],
          user_id : this.$store.state.auth.user.id
        }
      },
      revenueOverView: {
        daily: [],
        salary: [],
        total: [],
      },
      roadMapPromotion: [],
      rewardDaily: [],
      rewardProduct: [],
      columnsTable: [
        {
          field: "date",
          key: "a",
          title: "Ngày",
          align: "center",
          children: [
            {
              field: "date",
              key: "aa",
              title: "",
              width: 60,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>{moment(row.date).format('DD/MM')}</div>
                );
              }
            }
          ],
        },
        {
          field: "number_order",
          key: "b",
          title: "Tổng đơn",
          align: "right",
          children: [
            {
              field: "number_order",
              key: "bb",
              title: "",
              align: "right",
              width: 70,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="total-revenue">
                    {helpers.formatCurrency(row.number_order ?? 0)}
                  </div>
                );
              }
            }
          ],
        },
        {
          field: "ads_cost",
          key: "d",
          title: "CP Ads",
          align: "right",
          children: [
            {
              field: "ads_cost",
              key: "dd",
              title: "",
              align: "right",
              width: 110,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="total-revenue">
                    <span>
                      {helpers.formatCurrency(row.ads_cost ?? 0)}
                    </span>
                  </div>
                );
              }
            }
          ],
        },
        {
          field: "revenue",
          key: "c",
          title: "DSUT",
          align: "right",
          children: [
            {
              field: "revenue",
              key: "cc",
              title: "",
              align: "right",
              width: 120,
              renderHeaderCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="total-daily-label">
                    Tổng
                  </div>
                );
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="total-revenue">
                    {helpers.formatCurrency(Math.ceil(row.revenue) ?? 0)}
                  </div>
                );
              }
            }
          ],
        },
        {
          field: "pecent_cpqc",
          title: "%CP Ads UT",
          align: "right",
          children: [
            {
              field: "pecent_cpqc",
              key: "pdl",
              title: "",
              align: "right",
              width: 80,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                let percent_qc = (row.ads_cost / row.revenue) * 100;
                percent_qc = Number.isFinite(percent_qc) ? percent_qc.toFixed(2) : 0;

                let className = 'pecent-cpqc';
                let cost_percent = 26;
                if (row.program) {
                  cost_percent = row.program.ads_percent
                } else if (row.isDailyReward && row.daily_reward.pr_reward) {
                  cost_percent = row.daily_reward.pr_reward.cost_percent;
                } else if (row.isProductReward && row.product_reward.pr_reward) {
                  cost_percent = row.product_reward.pr_reward.cost_percent;
                }

                if(percent_qc > cost_percent){
                  className += ' pecent-cpqc-lg'
                }
                
                return (
                  <div class={className}>
                    {helpers.formatCurrency(percent_qc)}
                  </div>
                );
              }
            }
          ],
        },
        {
          field: "pecent_cpqc",
          title: "%CP tham chiếu",
          align: "right",
          children: [
            {
              field: "pecent_cpqc",
              key: "pdl_tc",
              title: "",
              align: "right",
              width: 65,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                let cost_percent = 26;
                if (row.program) {
                  cost_percent = row.program.ads_percent
                } else if (row.isDailyReward && row.daily_reward.pr_reward) {
                  cost_percent = row.daily_reward.pr_reward.cost_percent;
                } else if (row.isProductReward && row.product_reward.pr_reward) {
                  cost_percent = row.product_reward.pr_reward.cost_percent;
                }

                return (
                  <div>
                  {helpers.formatCurrency(cost_percent)}
                  </div>
              );
              }
            }
          ],
        },
        {
          field: "daily_reward", key: "f", title: "Thưởng Daily Deals", align: "right",
          children: [
            {
              field: "daily_reward",
              key: "ff",
              title: "",
              align: "right",
              width: 130,
              renderHeaderCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="total-header-revenue">
                    {helpers.formatCurrency(this.totalHeaderTable.daily_reward_total)}
                  </div>
                );
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                let classAmount = '';
                let amount = helpers.formatCurrency(row.daily_reward_final);
                if (row.daily_reward_final > 0) {
                  classAmount = row.isDailyReward ? 'achieved-amout' : 'not-reached';
                }

                return (
                  <div class="total-reward">
                    <span class={classAmount}>
                      {amount}
                    </span>
                  </div>
                );
              }
            }
          ],
        },
        {
          field: "product_reward", key: "g", title: "Thưởng SP mới", align: "right",
          children: [
            {
              field: "product_reward",
              key: "gg",
              align: "right",
              width: 130,
              renderHeaderCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div class="total-header-revenue">
                     {helpers.formatCurrency(this.totalHeaderTable.product_reward_total)}
                  </div>
                );
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                let classAmount = '';
                let amount = helpers.formatCurrency(row.product_reward_final);
                if (row.product_reward_final > 0) {
                  classAmount = row.isProductReward ? 'achieved-amout' : 'not-reached';
                }

                return (
                  <div class="total-reward">
                    <span class={classAmount}>
                      {amount}
                    </span>
                  </div>
                );
              }
            }
          ],
        },
        {
          field: "image_reward", key: "l", title: "Ảnh chào thưởng", align: "center",
          children: [
            {
              field: "image_reward",
              key: "ll",
              align: "center",
              width: 100,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    {
                    (row.isDailyReward || row.isProductReward) ?
                          <div class="download_image cursor-pointer" onclick={() => this.exportImage(row)}>
                          <img src="/img/svg/Download.svg" />
                        </div>
                        : ''
                    }
                  </div>
                );
              }
            }
          ],
        },
        {
          field: "name", key: "h", title: "Mô tả", width: 300, align: "left",
          children: [
            {
              field: "name",
              key: "hh",
              align: "left",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                let name = '';
                if (row.program && row.program.title) {
                  name = '[' + row.program.title + '] ';
                }
                
                if(row.isProductReward){
                  name += row.product_reward?.pr_reward?.name ?? '';
                } else if (row.isDailyReward) {
                  name += row.daily_reward?.pr_reward?.name ?? '';
                }

                if(row.comment && name != ''){
                  name += ' ';
                }
                return (
                  <div class="reward-name">
                    { name }
                    {
                      row.comment !== ''
                      ? <span class="duplicate-new-product">{row.comment}</span> 
                      : ''
                    }
                  </div>
                );
              }
            }
          ],
        }
      ],
      totalHeaderTable: {
        daily_reward_total: 0,
        product_reward_total: 0
      },
      filterMember: this.$store.state.auth.user
    }
  },

  async created() {
    this.isLoading = true;
    // this.$store.dispatch("logout");
    await this.getTopRevenue();
    await this.getRevenueOverviewOfUser();
    // await this.getRoadMapPromotion();
    await this.getRewardListOfUser();
    this.dateSelection = moment().startOf('month').format('DD/MM/YYYY') + ' - ' + moment().format('DD/MM/YYYY');
    this.isLoading = false;
  },

  methods: {
    switchButton(tabActive) {
      if (tabActive === this.tabActive) return;
      this.tabActive = tabActive;
      let columns = this.columnsTable.map((column) => {
        if(column.field === 'number_order'){
          column.title = tabActive === 1 ? "Tổng đơn" : "Đơn done";
        }
        if(column.field === 'revenue'){
          column.title = tabActive === 1 ? "DSUT" : "DSTT";
        }
        if(column.field === 'pecent_cpqc'){
          column.title = tabActive === 1 ? "%CP Ads UT" : "%CP Ads TT";
        }
        return column;
        });
      this.columnsTable = columns
      // this.revenueOverviewOfUser.params.start_date = moment(this.timeRange[0]).format('YYYY-MM-DD');
      // this.revenueOverviewOfUser.params.end_date = moment(this.timeRange[1]).format('YYYY-MM-DD');
      this.revenueOverviewOfUser.params.revenue_type = this.tabActive;
      this.getTopRevenue();
      this.getRevenueOverviewOfUser();
    },
    changeDate(value){

      let start_date = moment(value).startOf('month');
      let end_date = moment(value).endOf('month');

      
      if(moment(value).isSame(moment(), 'month')){
        end_date = moment();
      }

      this.dateSelection = start_date.format('DD/MM/YYYY') + ' - ' + end_date.format('DD/MM/YYYY');
      this.revenueOverviewOfUser.params.start_date = start_date.format('YYYY-MM-DD');
      this.revenueOverviewOfUser.params.end_date = end_date.format('YYYY-MM-DD');
      this.revenueOverviewOfUser.params.revenue_type = this.tabActive;
      this.getRevenueOverviewOfUser();
      // =====================getTopRevenue================ //
    },
    async getTopRevenue(){
      this.isLoading = true;
      this.topRevenue.params.revenue_type = this.tabActive;
      DashboardService.getTopRevenue(this.topRevenue.params).then(res => {
        if(res.status){
          this.topRevenue.list = res.data;
        }
      });
      this.isLoading = false;
    },
    async getRevenueOverviewOfUser(){
      this.isLoading = true;
      this.revenueOverView = [];
      await DashboardService.getRevenueOverviewOfUser(this.revenueOverviewOfUser.params)
      .then(res => {
        if(res.status){
          let revenueOverView = res.data;

          let daily_reward_total = 0;
          let product_reward_total = 0;

          this.lineChart.chartData.labels = [];
          this.lineChart.chartData.datasets[0].data = [];
          this.lineChart.chartData.datasets[1].data = [];

          for (const item of revenueOverView.daily) {
            item.comment = '';

            item.daily_reward_final = item.daily_reward ? item.daily_reward.final_amount : 0;
            item.product_reward_final = item.product_reward ? item.product_reward.final_amount : 0;

            if (item.isDailyReward) {
              daily_reward_total += item.daily_reward_final;
            } else if (item.isProductReward) {
              product_reward_total += item.product_reward_final;
            }

            // chart set data
            this.lineChart.chartData.labels.push(moment(item.date).format('DD-MM'));
            this.lineChart.chartData.datasets[0].data.push(item.number_order) // Tổng đơn
            this.lineChart.chartData.datasets[1].data.push(item.revenue) // Doanh số
          }

          this.totalHeaderTable = {
            daily_reward_total : daily_reward_total,
            product_reward_total : product_reward_total,
          };
          this.revenueOverView = revenueOverView;
        }
      });
      this.isLoading = false;
    },
    getRoadMapPromotion(){
      DashboardService.getRoadMapPromotion({}).then(res => {
        if (res) {
          this.roadMapPromotion = res.data;
        }
      });
    },

    async switchTopRank(index){
      this.tabTopRankActive = index;
      this.isLoadingRank = true;

      let start_date = '';
      let end_date = '';
      if(index === 1){
        start_date = moment().startOf('week').format('YYYY-MM-DD');
        end_date = moment().endOf('week').format('YYYY-MM-DD');
      }else{
        start_date = moment().startOf('month').format('YYYY-MM-DD');
        end_date = moment().endOf('month').format('YYYY-MM-DD');
      }
      this.topRevenue.params.start_date = start_date;
      this.topRevenue.params.end_date = end_date;
      
      await this.getTopRevenue();
      this.isLoadingRank = false;
    },

    getRewardListOfUser(){
      const params = {
        type: [
          2, // daily
          3 // product
        ]
      };
      DashboardService.getRewardListOfUser(params).then(res => {
        if (res) {
          for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index];
            if(element.pr_reward.type === 2){
              this.rewardDaily.push(element);
            } else {
              this.rewardProduct.push(element);
            }
          }
        }
      });
    },

    formatCurrency(currency){
      if(currency == null){
        currency = 0;
      }
      currency = Math.round(currency);
      return helpers.formatCurrency(currency);
    },

    convertToText(currency){
      return helpers.convertToText(Math.round(currency));
    },

    exportImage(data){

      let type = data.isDailyReward ? 'daily_reward' : 'product_reward';

      let percent_qc = (data.ads_cost / data.revenue) * 100;
      percent_qc = Number.isFinite(percent_qc) ? percent_qc.toFixed(2) : 0;
      
      const params = {
        name: `${this.filterMember.gender == 1 ? "Mr. " : "Ms. "}` + this.filterMember.fullname,
        text: `Đã đạt mốc doanh số ${this.formatCurrency(data[type].pr_reward.revenue)} ₫ ${data[type].pr_reward.type === 2 ? 'Daily Deals' : 'Sản phẩm mới' } với %CPQC < ${data[type].pr_reward.cost_percent ?? 0}% ngày ${moment(data.date).format('DD-MM')}`,
        money: helpers.formatCurrency(data[type].pr_reward.amount) + ' ₫',
        sales: helpers.formatCurrency(Math.ceil(data.revenue)) + ' ₫',
        cpqc: helpers.formatCurrency(Math.ceil(data.ads_cost)) + ' ₫',
        per_cpqc: helpers.formatCurrency(percent_qc) + '%',
        user_id: this.filterMember.id
      }

      this.$modal.show('confirm-download-image-modal', {
        data: params,
        content: `Bạn có muốn xuất bản và tải ảnh thưởng doanh số ngày ${moment(data.date).format('DD-MM')} không?`
      });
    },

    filterByUser(member){
      this.$modal.hide('member-list-modal');
      if(member){
        this.revenueOverviewOfUser.params.user_id = member.id;
        this.filterMember = member
        this.getRevenueOverviewOfUser();
        return;
      }

      this.$store.dispatch("alerts/error", "Đã có xảy ra. Vui lòng chọn lại!");
      
    },

    disabledBeforeTodayAndAfterMonth(e){
      return !moment(moment(e).format('YYYY-MM-DD')).isBetween(
        moment().subtract(3, 'months'), // 3 tháng trước
        moment().format('YYYY-MM-DD')
      );
      
    },
  }
};
</script>
<style lang="scss">
  @import "./dashboard.scss";
</style>