<template>
  <div>
    <modal name="pr-reward-program-detail"
           :click-to-close="false"
           width="85%"
           height="auto"
           class="create-group-modal modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
           @before-close="beforeClosed"
           >

      <md-card class="modal-header-custom">
        <md-dialog-title>
          <span class="neutral_1 heading-500">{{ dataDetail.title }}</span>
          <span class="material-icons float-right cursor-pointer"
                @click="$modal.hide('pr-reward-program-detail')">close</span>
        </md-dialog-title>
      </md-card>

      <md-card-content>
        <div class="program-infos">
          <div class="time-comming info-content">
            <div class="title">Thời gian diễn ra</div>
            <div class="content-detail">{{ dataDetail.date_from }} đến {{ dataDetail.date_to }}</div>
          </div>
          <div class="time-comming info-content">
            <div class="title">Ghi chú</div>
            <div class="content-detail">
              Tối đa {{ helpers.formatCurrency(dataDetail.max_reward_amount) }}
              <template v-if="dataDetail.product_ignore || dataDetail.product_ignore">
                <template v-if="dataDetail.product_ignore == 1">
                  , Không tính thưởng SP mới
                </template>
                <template v-if="dataDetail.daily_ignore == 1">
                  , Không tính thưởng SP mới
                </template>
              </template>
            </div>
          </div>
        </div>
        <!-- daily deals -->
        <div class="daily-deals-content" v-if="dailyDealsData.length > 0">
          <ve-table
              :border-x="true"
              :border-y="true"
              :border-around="true"
              row-key-field-name="rowKey"
              :columns="columns"
              :table-data="dailyDealsData"
            />
        </div>
        <!-- daily deals -->
        <!-- product new -->
        <div class="product-new-content" v-if="productNewData.length > 0">
          <ve-table
              :border-x="true"
              :border-y="true"
              :border-around="true"
              row-key-field-name="rowKey"
              :columns="columns"
              :table-data="productNewData"
            />
        </div>
        <!-- product new -->
      </md-card-content>
      <md-dialog-actions>
        <button class="btn-stroke md-button" @click="closeModal">Đồng ý</button>
      </md-dialog-actions>
    </modal>
  </div>
</template>

<script>
import DashboardService from "../../../store/services/DashboardService";
import {helpers} from "../../../helper/helpers";
import moment from "moment";
import { create } from "lodash";

export default {
  name: 'pr-reward-program-detail',
  data(){
    return {
      dailyDealsData: [],
      productNewData: [],
      columns: [
        { 
            field: "stt",
            key: "a",
            title: "STT",
            align: "center",
            width: 50
        },
        { 
          field: "letter_type",
          key: "b",
          title: "Loại thưởng",
          align: "right",
        },
        { 
          field: "sale",
          key: "c",
          title: "Doanh số",
          align: "right",
        },
        { 
          field: "letter_percent",
          key: "d",
          title: "%CPQC",
          align: "right",
          width: 100,
        },
        { 
          field: "letter_by_program",
          key: "e",
          title: "Thưởng theo chương trình",
          align: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            let text = 'Không áp dụng';
            if(typeof row.letter_by_program === 'number'){
              text = <div class="price-red">{helpers.formatCurrency(row.letter_by_program)}</div>
            }
             return (
              <div>
                {text}
              </div>
             )
          }
        },
        { 
          field: "letter_normal",
          key: "f",
          title: "Thưởng mặc định",
          align: "right",
        },
      ],
      isLoading: false,
      dataDetail: {
        date_from: null,
        date_to: null,
        daily_multiple : null,
        product_multiple: null,
        daily_ignore: null,
        product_ignore: null,
        max_reward_amount: 0,
        title: "",
        ads_percent: 0
      },
      helpers: helpers
    }
  },
  created(){
    window.enableScrollBody();
  },
  computed: {
    show() {
      return this.showDialog;
    }
  },
  methods: {
    beforeOpened(params){
      this.dataDetail = params.params.data;
      this.dataDetail.date_from = moment(this.dataDetail.date_from).format('DD/MM/YYYY');
      this.dataDetail.date_to = moment(this.dataDetail.date_to).format('DD/MM/YYYY');

      if(this.dataDetail.product_ignore == 1){
        this.getCompanyRewardList(2);
      }
      if(this.dataDetail.daily_ignore == 1){
        this.getCompanyRewardList(3);
      }
      window.disableScrollBody();
    },
    beforeClosed(){
      window.enableScrollBody();
    },

    async getCompanyRewardList(type){
      this.isLoading = true;
      await DashboardService.getCompanyRewardList({type: type}).then(res => {
        // this.tableData 
        let data = res.data;
        let items = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          console.log(element);
          
          let by_mutiple = type == 2 ? this.dataDetail.daily_multiple : this.dataDetail.product_multiple;
          let letter_by_program_price = element.amount * by_mutiple;

          if(letter_by_program_price > this.dataDetail.max_reward_amount){
            letter_by_program_price = 'Không áp dụng'
          }

          items.push({
            stt: index + 1,
            letter_type: type == 2 ? 'Daily Deals' : 'SP mới',
            sale: helpers.formatCurrency(element.revenue),
            letter_percent: this.dataDetail.ads_percent,
            letter_by_program: letter_by_program_price,
            letter_normal: helpers.formatCurrency(element.amount),
          });
        }
        if(type == 2){
          this.dailyDealsData = items;
        } else {
          this.productNewData = items;
        }
      });
      this.isLoading = false;
    },

    closeModal(){
      this.$modal.hide('pr-reward-program-detail')
    }
  }
}
</script>

<style lang="scss">
.md-dialog {
  .md-dialog-container {
    width: 515px !important;
  }
}

.info-content{
  display: flex;
  align-items: center;
  grid-gap: 50px;
  margin-bottom: 20px;
  .title{
    width: 120px;
  }
}
.daily-deals-content{
  padding-bottom: 10px;
}
.price-red{
  color: red;
  font-weight: 700;
}

</style>
