<template>
  <div class="md-layout md-gutter statistical-content-container">
    <div class="layout-item md-layout-item md-size-63 md-xsmall-size-100 md-medium-size-100">
        <div class="mode-datetime">
            <div class="switch-mode-panel">
            <div class="switch-button">
              <div class="switch-button-label-span" :class="{ 'switch-button-label-span-active': tabActive === 1 }"
                @click="switchButton(1)">Doanh số ước tính</div>
              <div class="switch-button-label-span" :class="{ 'switch-button-label-span-active': tabActive === 2 }"
                @click="switchButton(2)">Doanh số thực tế</div>
            </div>
          </div>
          <!-- datepicker -->
          <div class="datepicker">
            <date-picker 
              class="datetime-picked" 
              placeholder="Select date range"
              v-model="timeRange" 
              range-separator=" - " 
              format="DD/MM/YYYY"
              type="month"
              :clearable="false"
              :confirm="true"
              :confirm-text="'Xác nhận thời gian tìm kiếm'"
              :lang="lang"
              :not-before="disabledBefore"
              @change="changeDate"
              :range="false"
            >
            <template v-slot:input>
              <input v-model="dateSelection" type="text" name="date" 
              readonly 
              autocomplete="off" 
              placeholder="Chọn thời gian" 
              class="mx-input" />
              </template>
              <template v-slot:icon-calendar>
                <md-icon>arrow_drop_down</md-icon>
              </template>
            </date-picker>
          </div>
        </div>
      <div class="layout-container">
        <!-- thu nhập / lương / Tổng thưởng -->
        <div class="md-layout md-gutter md-alignment-space-between-center revenue-layout">
          <div class="md-layout-item">
            <div class="layout-money income">
              <div class="title-money">
                <span>Thu nhập</span>
              </div>
              <div class="total-money">
                <span>17.700.000</span>
              </div>
            </div>
          </div>
          <div class="md-layout-item">
            <div class="layout-money salary">
              <div class="title-money">
                <span>Lương</span>
              </div>
              <div class="total-money">
                <span>16.500.000</span>
              </div>
            </div>
          </div>
          <div class="md-layout-item">
            <div class="layout-money total-reward">
              <div class="title-money">
                <span>Tổng thưởng</span>
              </div>
              <div class="total-money">
                <span>1.200.000</span>
              </div>
            </div>
          </div>
        </div>
        <!-- chỉ số đơn hàng - doanh số -->
        <div class="md-layout md-gutter md-layout-item md-size-80 md-alignment-space-between-center revenue-index-layout">
          <div class="md-layout-item">
            <div class="revenue-index__title">
              <span>Tổng đơn / Hoàn thành</span>
            </div>
            <div class="revenue-index__number">
              <span>122 / 23</span>
            </div>
          </div>
          <div class="md-layout-item">
            <div class="revenue-index__title">
              <span>Doanh thu</span>
            </div>
            <div class="revenue-index__number">
              <span>234.200.000</span>
            </div>
          </div>
          <div class="md-layout-item">
            <div class="revenue-index__title">
              <span>CPQC</span>
            </div>
            <div class="revenue-index__number">
              <span>34.565.252</span>
            </div>
          </div>
          <div class="md-layout-item">
            <div class="revenue-index__title">
              <span>%CPQC</span>
            </div>
            <div class="revenue-index__number">
              <span>23.12</span>
            </div>
          </div>
        </div>
        <!-- chart -->
        <div class="md-layout md-gutter chart-layout">
          <div class="md-layout-item">
            <div class="chart-header-title">
              <div class="chart-title">
                <div class="head-title">
                  DOANH SỐ THEO THỊ TRƯỜNG
                </div>
                <div class="help-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clip-path="url(#clip0_4453_36921)">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
                        fill="black" />
                      <path
                        d="M8.92995 6.58801L6.63995 6.87501L6.55795 7.25501L7.00795 7.33801C7.30195 7.40801 7.35995 7.51401 7.29595 7.80701L6.55795 11.275C6.36395 12.172 6.66295 12.594 7.36595 12.594C7.91095 12.594 8.54395 12.342 8.83095 11.996L8.91895 11.58C8.71895 11.756 8.42695 11.826 8.23295 11.826C7.95795 11.826 7.85795 11.633 7.92895 11.293L8.92995 6.58801Z"
                        fill="black" />
                      <path
                        d="M8 5.5C8.55228 5.5 9 5.05228 9 4.5C9 3.94772 8.55228 3.5 8 3.5C7.44772 3.5 7 3.94772 7 4.5C7 5.05228 7.44772 5.5 8 5.5Z"
                        fill="black" />
                    </g>
                    <defs>
                      <clipPath id="clip0_4453_36921">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div class="chart-subhead">
                Đơn vị: triệu VNĐ
              </div>
            </div>
            <DoughnutChart :chartData="doughnutChart.chartData" :chartOptions="doughnutChart.chartOptions"
              :styles="{ height: '180px' }" />
          </div>
          <div class="md-layout-item">
            <div class="chart-header-title">
              <div class="chart-title">
                <div class="head-title">
                  DOANH SỐ THEO SẢN PHẨM
                </div>
                <div class="help-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clip-path="url(#clip0_4453_36921)">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
                        fill="black" />
                      <path
                        d="M8.92995 6.58801L6.63995 6.87501L6.55795 7.25501L7.00795 7.33801C7.30195 7.40801 7.35995 7.51401 7.29595 7.80701L6.55795 11.275C6.36395 12.172 6.66295 12.594 7.36595 12.594C7.91095 12.594 8.54395 12.342 8.83095 11.996L8.91895 11.58C8.71895 11.756 8.42695 11.826 8.23295 11.826C7.95795 11.826 7.85795 11.633 7.92895 11.293L8.92995 6.58801Z"
                        fill="black" />
                      <path
                        d="M8 5.5C8.55228 5.5 9 5.05228 9 4.5C9 3.94772 8.55228 3.5 8 3.5C7.44772 3.5 7 3.94772 7 4.5C7 5.05228 7.44772 5.5 8 5.5Z"
                        fill="black" />
                    </g>
                    <defs>
                      <clipPath id="clip0_4453_36921">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div class="chart-subhead">
                Đơn vị: triệu VNĐ
              </div>
            </div>
            <BarChartContent :chartData="barChart.chartData" :chartOptions="barChart.chartOptions"
              :styles="{ height: '180px' }" />
          </div>
          <div class="md-layout-item">
            <div class="chart-header-title">
              <div class="chart-title">
                <div class="head-title">CHI PHÍ QUẢNG CÁO</div>
                <div class="help-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clip-path="url(#clip0_4453_36921)">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
                        fill="black" />
                      <path
                        d="M8.92995 6.58801L6.63995 6.87501L6.55795 7.25501L7.00795 7.33801C7.30195 7.40801 7.35995 7.51401 7.29595 7.80701L6.55795 11.275C6.36395 12.172 6.66295 12.594 7.36595 12.594C7.91095 12.594 8.54395 12.342 8.83095 11.996L8.91895 11.58C8.71895 11.756 8.42695 11.826 8.23295 11.826C7.95795 11.826 7.85795 11.633 7.92895 11.293L8.92995 6.58801Z"
                        fill="black" />
                      <path
                        d="M8 5.5C8.55228 5.5 9 5.05228 9 4.5C9 3.94772 8.55228 3.5 8 3.5C7.44772 3.5 7 3.94772 7 4.5C7 5.05228 7.44772 5.5 8 5.5Z"
                        fill="black" />
                    </g>
                    <defs>
                      <clipPath id="clip0_4453_36921">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div class="chart-subhead">
                Đơn vị: triệu VNĐ
              </div>
            </div>
            <div class="line-chart-area" style="height: 180px;">
              <LineChartData :chartData="lineChart.chartData" :chartOptions="lineChart.chartOptions"
                :styles="{ height: '180px' }" />
            </div>
          </div>
        </div>
        <!-- detail-layout -->
        <div class="detail-layout">
          <TableEasy :titleTable="'CHI TIẾT THEO NGÀY'" :columns="detailTable.columns" :tableData="detailTable.data" />
          <!--  -->
          <div class="md-layout md-gutter order-link-ctkm">
            <div class="md-layout-item">
              <TableEasy :titleTable="'Link Trong Đơn'" :columns="linkInOrderTable.columns"
                :tableData="linkInOrderTable.data" />
            </div>
            <div class="md-layout-item">
              <TableEasy :titleTable="'DANH SÁCH CTKM'" :columns="ctkmTable.columns" :tableData="ctkmTable.data" />
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
    import DoughnutChart from "../Chart/DoughnutChart"
    import BarChartContent from "../Chart/BarChart"
    import LineChartData from "../Chart/LineChartData"
    import TableEasy from "../Table/TableEasy.vue";
    import DatePicker from 'vue2-datepicker';
    import moment from "moment";
    import vue2DatePicker from "@/mixins/config-vue2-datepicker";

    export default {
    components: {
      DoughnutChart,
      BarChartContent,
      LineChartData,
      TableEasy,
      DatePicker
    },
    mixins: [vue2DatePicker],
    data() {
      const date = new Date();
      return {
        tabActive: 1,
        disabledBefore: new Date(2023, 10),
        timeRange: [
          new Date(date.getFullYear(), date.getMonth(), 1),// ngày tháng đầu tiên của tháng
          new Date() // giờ hiện tại
        ],
        dateSelection: '',
        lineChart: {
          chartData: {
            labels: ["1", "2", "3", "4", "5", "6", "7", "8", "8", "10"],
            datasets: [
              {
                label: 'Tổng',
                data: [5, 10, 7.5, 10.5, 11, 13, 15, 12.5, 13.1],
                fill: false,
                borderColor: '#F94144',
                backgroundColor: '#F94144',
              }
            ]
          },
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                display: false, 
              }
            }
          },
        },
        barChart: {
          chartData: {
          labels: [
            'MY. SMARTWATCH 8 ULTRA',
            'PREMIUM GENUINE LEATHER WALLET',
            'ID. JAPANESE LOTUS CHIA SEEDS CEREAL',
            'Khác'
          ],
          datasets: [
            {
              label: '',
              backgroundColor: '#f87979',
              data: [400, 310, 190, 10]
            }
          ]
          },
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            indexAxis: 'y', 
            scales: {
              y: {
                beginAtZero: true
              }
            },
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                display: false, 
              }
            }
          }
        },
        doughnutChart: {
          chartData: {
            labels: ['Philippines', 'India', 'Cambodia'],
            datasets: [
                {
                backgroundColor: ['#F8961E', '#F3722C', '#F94144'],
                data: [25, 50, 25]
                }
            ]
          },
          chartOptions: {
              plugins: {
                  legend: {
                    position  : 'left',
                    labels: {
                      usePointStyle: true,
                      pointeStyle: 'circle'
                    }
                  },
                  datalabels: {
                    formatter: (value, ctx) => {
                      return value + '%';
                    },
                    color: '#fff',
                    display: true, 
                  }
              },
              responsive: true,
              maintainAspectRatio: false
          }
        },
        detailTable:{
          columns: [
            { field: "day", key: "a", title: "Ngày", align: "center" },
            { field: "order_all", key: "b", title: "Tổng đơn", align: "right" },
            { field: "order_done", key: "c", title: "Đơn done", align: "right" },
            { field: "sales", key: "d", title: "Doanh số", align: "right" },
            { field: "cpqc", key: "e", title: "CPQC", align: "right" },
            { field: "pecent_cpqc", key: "f", title: "%CPQC", align: "right" },
            { field: "daily", key: "g1", title: "Thưởng Daily", align: "right" },
            { field: "product_new", key: "h", title: "Thưởng SP mới", align: "right" },
            { field: "other", key: "i", title: "Thưởng khác", align: "right" },
            { field: "total_reward", key: "k", title: "Tổng thưởng", align: "right" ,
            renderBodyCell: ({row, column, rowIndex}, h) => {
                      return (
                          <div class="total-reward">
                              <span class={row.total_reward === '300.000' ? 'reward-red' : 'reward-blue'}>{row.total_reward}</span>
                          </div>
                      );
                  }
              },
            { field: "note", key: "g", title: "Ghi chú", align: "left" }
          ],
          data: [
            {
              day: "01/09",
              order_all:"20",
              order_done:"20",
              sales: "18.000.000",
              cpqc: "3.000.000",
              pecent_cpqc: "22.32",
              daily:"200.000",
              product_new:"0",
              total_reward: "200.000",
              note: "Thưởng Daily Deals",
            },
            {
              day: "02/09",
              order_all:"20",
              order_done:"20",
              sales: "18.000.000",
              cpqc: "3.000.000",
              pecent_cpqc: "22.32",
              daily:"200.000",
              product_new:"0",
              total_reward: "300.000",
              note: "Thưởng Daily Deals",
            },
            {
              day: "02/09",
              order_all:"20",
              order_done:"20",
              sales: "18.000.000",
              cpqc: "3.000.000",
              pecent_cpqc: "22.32",
              daily:"200.000",
              product_new:"0",
              total_reward: "0",
              note: "",
            },
            {
              day: "02/09",
              order_all:"20",
              order_done:"20",
              sales: "18.000.000",
              cpqc: "3.000.000",
              pecent_cpqc: "22.32",
              daily:"200.000",
              product_new:"0",
              total_reward: "0",
              note: "",
            },
            {
              day: "02/09",
              order_all:"20",
              order_done:"20",
              sales: "18.000.000",
              cpqc: "3.000.000",
              pecent_cpqc: "22.32",
              daily:"200.000",
              product_new:"0",
              total_reward: "0",
              note: "",
            }
          ]
        },
        linkInOrderTable: {
          columns: [
            { field: "stt", key: "a", title: "STT", align: "center" },
            { field: "link", key: "b", title: "Link", align: "left" },
            { field: "order_number", key: "c", title: "Số đơn", align: "right" }
          ],
          data: [
            {
              stt: "1",
              link:"www.powermaccenter-ph.asia/gt-20",
              order_number:"214"
            },
            {
              stt: "2",
              link:"www.siriustore.asia/camerax5.ph",
              order_number:"23"
            },
            {
              stt: "3",
              link:"www.doc-willieong.website/gojitea.saleoff.50",
              order_number:"6"
            },
            {
              stt: "4",
              link:"www.richardlee-cosmetics.click/RetinolCream.id",
              order_number:"1"
            }
          ]
        },
        ctkmTable: {
          columns: [
            { field: "stt", key: "a", title: "STT", align: "center" },
            { field: "link", key: "b", title: "CTKM", align: "left" },
            { field: "order_number", key: "c", title: "Số đơn", align: "right" }
          ],
          data: [
            {
              stt: "1",
              link:"BELI 1 HITAM: RM109 PENGHANTARAN PERCUMA #400-BLACK",
              order_number:"214"
            },
            {
              stt: "2",
              link:"BUY 2 (PHP 2,199 - FREE SHIPPING)",
              order_number:"23"
            },
            {
              stt: "3",
              link:"BUY 1 FREE 1 PRO 4 HEADPHONES (PHP 1,599 - FREE SHIPPING)",
              order_number:"6"
            },
            {
              stt: "4",
              link:"BUY 1 : RM69 FREESHIPPING #336",
              order_number:"1"
            }
          ]
        },
        
      };
    },
  
    methods: {
      changeDate(value){

        let start_date = moment(value).startOf('month');
        let end_date = moment(value).endOf('month');


        if(moment(value).isSame(moment(), 'month')){
          end_date = moment();
        }

        this.dateSelection = start_date.format('DD/MM/YYYY') + ' - ' + end_date.format('DD/MM/YYYY');
      },
      switchButton(tabActive) {
        if (tabActive === this.tabActive) return;
        this.tabActive = tabActive;
      }
    }
  };
  </script>
<style lang="scss" scoped>
$color-text: rgba(16, 24, 64, 1);
.statistical-content-container {
  margin: 0 !important;
  // padding: 20px;
  grid-gap: 20px;
  height: 100%;
  .md-layout {
    margin: 35px 0 10px !important;
    padding: 0 20px;
    grid-gap: 20px;
  }
  .order-link-ctkm{
    padding: 0 !important;
    margin: 0 0 10px !important;
    .md-layout-item{
      padding: 0;
    }
  }

  .layout-item {
    padding: 0 !important;
  }

  .datepicker {
    width: max-content;
    margin: auto;
    // .datetime-picked{
    //   input{
    //     height: 25px !important;
    //   }
    // }
    .mx-datepicker-range{
      width: 200px !important;
      height: 25px;
    }
  }

  .revenue-index-layout {
    border: 0.5px solid #C1C1C1;
    border-radius: 8px;
    max-height: 60px;
    text-align: center;
    margin: auto !important;
    min-height: max-content;
    .md-layout-item{
      min-height: 100%;
      margin: 5px;
      position: relative;
      &:not(:nth-last-child(4n+1)):after{
        position: absolute;
        content: "";
        width: 0.5px;
        height: 40px;
        background: #D8DAE5;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
      }
      .revenue-index__title{
        color: $color-text;
        font-size: 14px;
        font-weight: 500;
      }
      .revenue-index__number{
        color: rgba(209, 67, 67, 1);
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
  .revenue-layout {
    margin-bottom: 20px !important;
    .md-layout-item {
      min-height: 60px;
      padding: 0;
      border: none;
      max-width: 180px;

      .income {
        background: rgba(255, 138, 0, 1);
      }

      .total-reward {
        background: rgba(164, 82, 228, 1);
      }

      .revenue {
        background: rgba(228, 94, 82, 1);
      }

      .cpqc {
        background: rgba(0, 203, 106, 1);
      }

      .salary{
        background: rgba(14, 173, 135, 1);
      }

      .layout-money {
        height: 60px;
        text-align: center;
        padding-top: 5px;
        color: #fff;
        border-radius: 8px;

        .title-money {
          font-size: 14px;
          font-weight: 400;
        }

        .total-money {
          font-size: 20px;
          font-weight: 600;
        }
      }

      &:after {
        width: 100%;
        height: 100%;
        display: block;
        background: md-get-palette-color(red, 200);
        content: " ";
      }
    }
  }

  .chart-layout {
    width: 100%;
    min-height: 250px;
    padding: 0;
    canvas{
      height: 100% !important;
    }
    .md-layout-item{
      padding: 0;
    }
    .line-chart-area{
      height: 100%;
    }
    .chart-header-title{
      margin-bottom: 10px;
      .chart-title{
        color: #101840;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.05px;
        display: flex;
        align-items: center;
        grid-gap: 15px;
        .help-icon{
          width: 16px;
          height: 16px;
          &:hover{
            cursor: pointer;
          }
        }
      }
      .chart-subhead{
        color: #52576F;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 160% */
      }
    }
  }

  .switch-mode-panel {
  width: max-content;
  margin: auto;

  .switch-button {
    width: max-content;
    margin: 10px 0;
    padding: 2px 4px;
    display: flex;
    grid-gap: 20px;
    background: rgba(0, 0, 0, 0.1019607843);
    align-items: center;
    border-radius: 10px;
    justify-content: space-between;

    .switch-button-label-span {
      &:hover {
        cursor: pointer;
      }
      font-size: 14px;
      padding: 0 15px;
    }

    .switch-button-label-span-active {
      border-radius: 8px;
      background: #fff;
      padding: 0 10px;
    }
  }

}
.mode-datetime {
    margin-bottom: 30px;
}
.layout-container {
  background: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  padding: 0 20px;
}
.chart-data{
      height: 100%;
      #line-chart{
        height: 180px !important;
      }
    }
}


</style>
  