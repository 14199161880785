import Vue from "vue";
import axios from "axios";
// Import Bootstrap and BootstrapVue CSS files (order is important)

// Plugins
import App from "./App.vue";
// import Chartist from "chartist";
import VueAxios from "vue-axios";
import DashboardPlugin from "./material-dashboard";
import Popover from 'vue-js-popover';
import "vue-select/dist/vue-select.css";
import VueJSModal from 'vue-js-modal';
import "vue-easytable/libs/theme-default/index.css"; // import style
import VueEasytable from "vue-easytable"; // import library
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'vue2-datepicker/index.css';
import VueElementLoading from 'vue-element-loading';
import {helpers} from "./helper/helpers";
import Cookies from 'js-cookie';
import Multiselect from "vue-multiselect";
import 'vue-datetime/dist/vue-datetime.css';
import {Settings} from 'luxon';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  LinearScale,
  CategoryScale,
  PointElement,
  ArcElement,
} from 'chart.js'
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  LinearScale,
  CategoryScale,
  PointElement,
  ArcElement,
  ChartDataLabels
)

Settings.defaultLocale = 'vi';

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(Popover,{ tooltip: true });
Vue.use(VueAxios, axios);
Vue.use(VueJSModal, { dialog: true, dynamic: true, injectModalsContainer: true });
Vue.use(VueJSModal);
Vue.use(VueEasytable);
Vue.component('VueElementLoading', VueElementLoading);
Vue.component('multiselect', Multiselect);

// router & store setup
import router from "./router";
import store from "./store";
import {Datetime} from "vue-datetime";

const accessToken = localStorage.getItem("access_token");
if(accessToken !== null){
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

let accessTokenLocal = localStorage.getItem("access_token");
let userIdLocal = localStorage.getItem("user_id");
let accessTokenCookie = helpers.getCookie("access_token");
let userIdCookie = helpers.getCookie("access_token");

let userId = userIdLocal;
if (accessTokenLocal == null) {
    localStorage.setItem("access_token", accessTokenCookie);
}

if (userIdLocal == null) {
    userId = userIdCookie;
    localStorage.setItem("user_id", userIdCookie);
}

if((!Cookies.get('access_token') || !Cookies.get('user_id')) && window.location.pathname !== '/login'){
  store.dispatch("logout");
} 

window.disableScrollBody = () => {
  document.body.classList.add('remove-scroll-body');
}

window.enableScrollBody = () => {
  document.body.classList.remove('remove-scroll-body');
}

/* eslint-disable no-new */
const app = new Vue({
  router: router,
  store: store,
  el: "#app",
  render: h => h(App)
});

store.$app = app;
window.app = app;
