import BaseService from "./BaseService";

export default class GroupService extends BaseService {

  static async getGroups(params = {}) {
    const res = await this.get(`/groups`, params);
    return res.data;
  }

  static async createGroup(params = {}) {
    const res = await this.post(`/groups`, params);
    return res.data;
  }
  //
  // static async updatePost(params = {}) {
  //   const res = await this.get(`/login`, params);
  //   return res.data;
  // }



}

