<template>
  <div class="create-leave-request-modal__container scroll-modal">
    <modal name="create-leave-request-modal"
           :click-to-close="false"
           width='900px'
           height="auto"
           class="modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
    >
      <div class="modal-content">
        <md-card class="modal-header-custom">
          <md-dialog-title>
            <span class="heading-500">TẠO ĐỀ XUẤT</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('create-leave-request-modal')">close</span>
          </md-dialog-title>
        </md-card>
        <md-card-content>
          <div class="request-group-info" v-if="requestGroup.description">
            <div v-html="requestGroup.description"></div>
          </div>
          <div class="leave-request__form custom-scroll-bar">
            <!-- ================================================= -->
            <template v-if="[2,3].includes(leaveRequest.type)">
              <div class="item-row">
                <div class="label">
                  <label class="required">Tiêu đề</label>
                </div>
                <div class="input input-fake">
                    {{ userNameWithTeam }}
                  </div>
              </div>
                <div class="item-row">
                  <div class="label">
                    <label class="required">Loại đề xuất</label>
                  </div>
                  <div class="input input-fake">
                    {{ typeLabel[leaveRequest.type] }}
                  </div>
                </div>
                <div class="item-row">
                  <div class="label">
                    <label class="required">Chế độ duyệt</label>
                  </div>
                  <div class="input input-fake">
                    <template v-if="requestGroup.decision_mode">
                      {{ requestGroup.decision_mode === 1 ? 'SINGLE APPROVE' : 'DUYỆT ĐỒNG THỜI' }}
                    </template>
                  </div>
                </div>
                <div class="item-row">
                  <div class="label">
                    <label class="required">Lý do cụ thể</label>
                    <div class="sub-label">Nhân viên ghi lý do xin sớm cụ thể, ngắn gọn</div>
                  </div>
                  <textarea v-model="leaveRequest.reason" placeholder="Lý do cụ thể"></textarea>
                </div>
  <!--              <div class="item-row">-->
  <!--                <div class="label">-->
  <!--                  <label class="required">Người phê duyệt</label>-->
  <!--                  <div class="sub-label">Quản lý trực tiếp của bạn</div>-->
  <!--                </div>-->
  <!--                <div style="width: 500px">-->
  <!--                  <multiselect v-model="customer.value"-->
  <!--                              deselect-label="Can't remove this value"-->
  <!--                              track-by="id"-->
  <!--                              placeholder="Chọn người phê duyệt"-->
  <!--                              :options="customer.list"-->
  <!--                              :allow-empty="false"-->
  <!--                              :selectLabel="''"-->
  <!--                              :deselectLabel="''"-->
  <!--                              :selectedLabel="''"-->
  <!--                              :searchable="true"-->
  <!--                              :custom-label="getNameForUser"-->
  <!--                              :multiple="true"-->
  <!--                              @search-change="getEmployees($event)"-->
  <!--                  >-->
  <!--                    <template slot="option" slot-scope="props">-->
  <!--                      <div class="option__desc"><span class="option__title">{{ getNameForUser(props.option) }}</span></div>-->
  <!--                    </template>-->
  <!--                  </multiselect>-->
  <!--                </div>-->
  <!--              </div>-->
                <div class="item-row">
                  <div class="label">
                    <label class="">Đối tượng duyệt</label>
                    <div class="sub-label">Đối tượng phê duyệt mặc định</div>
                  </div>
                  <div class="target-accept">
                    <div class="members input">
                      <div class="target-user btn btn-yellow mr-10px">Nhân sự</div>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div class="item-row" v-if="leaveRequest.type === LEAVE_TYPE.LEAVE_EARLY">
                  <div class="label">
                    <label class="required">Thời gian checkout</label>
                    <div class="sub-label">Thời gian mà bạn dự tính sẽ checkout trên máy chấm công</div>
                  </div>
                  <div class="input">
                    <div class="input-wrapper">
                      <md-icon>calendar_month</md-icon>
                      <datetime class="border-none"
                        type="datetime"
                        format="HH:mm, EEEE, dd/MM/yyyy"
                        v-model="requestDataRarlyTime"
                        title="Nhập thời gian checkout">dd/MM/yyyy</datetime>
                    </div>
                  </div>
                </div>
                <!--  -->
                <template v-if="leaveRequest.type === LEAVE_TYPE.ADJUSTMENT_OF_ATTENDANCE">
                </template>
            </template>
            <!-- ================================================= -->
            <template v-else>
              <div class="item-row">
                <div class="label">
                  <label class="required">Tiêu đề</label>
  <!--                <div class="sub-label">Tiêu đề</div>-->
                </div>
                <div class="input input-fake">
                  {{ userNameWithTeam }}
                </div>
                <!-- <input v-model="leaveRequest.name" class="input" type="text" placeholder="Tiêu đề"> -->
              </div>
              <div class="item-row">
                <div class="label">
                  <label class="required">Nhóm</label>
  <!--                <div class="sub-label">Nhóm</div>-->
                </div>
                <div class="input input-fake">
                  {{requestGroup.name}}
                </div>
              </div>

              <div class="item-row">
                <div class="label">
                  <label class="required">Lý do cụ thể</label>
                  <div class="sub-label">Nhân viên ghi lý do xin sớm cụ thể, ngắn gọn</div>
                </div>
                <textarea v-model="leaveRequest.reason" placeholder="Lý do cụ thể"></textarea>
              </div>

  <!--            <div class="item-row">-->
  <!--              <div class="label">-->
  <!--                <label class="required">Người phê duyệt</label>-->
  <!--                <div class="sub-label line-height-initial">Bạn phải thông báo cho người quản lý trực tiếp của mình về đề xuất này</div>-->
  <!--              </div>-->
  <!--              <div style="width: 500px">-->
  <!--                <multiselect v-model="customer.value"-->
  <!--                            deselect-label="Can't remove this value"-->
  <!--                            track-by="id"-->
  <!--                            placeholder="Chọn người phê duyệt"-->
  <!--                            :options="customer.list"-->
  <!--                            :allow-empty="false"-->
  <!--                            :selectLabel="''"-->
  <!--                            :deselectLabel="''"-->
  <!--                            :selectedLabel="''"-->
  <!--                            :searchable="true"-->
  <!--                            :custom-label="getNameForUser"-->
  <!--                            :multiple="true"-->
  <!--                            @search-change="getEmployees($event)"-->
  <!--                >-->
  <!--                  <template slot="option" slot-scope="props">-->
  <!--                    <div class="option__desc"><span class="option__title">{{ getNameForUser(props.option) }}</span></div>-->
  <!--                  </template>-->
  <!--                </multiselect>-->
  <!--              </div>-->
  <!--            </div>-->
              <div class="item-row">
                <div class="label">
                  <label class="">Đối tượng duyệt</label>
                  <div class="sub-label">Đối tượng phê duyệt mặc định</div>
                </div>
                <div class="target-accept">
                  <div class="members input">
                    <div class="target-user btn btn-yellow mr-10px">Nhân sự</div>
                  </div>
                </div>
              </div>
              <template v-if="leaveRequest.type === LEAVE_TYPE.VALUABLE_VACATION">
                <div class="item-row">
                <div class="label">
                  <label class="required">Chọn ngày</label>
                </div>
                <div class="input item-row__leave">
                  <div class="input-wrapper">
                    <md-icon>calendar_month</md-icon>
                    <datetime type="date" format="EEEE, dd/MM/yyyy" v-model="dateTime" title="Nhập ngày" class="border-none">dd/MM/yyyy</datetime>
                  </div>
                </div>
              </div>
              </template>
              <template v-else>
                <div class="item-row">
                <div class="label">
                  <label class="required">Bắt đầu</label>
                </div>
                <div class="d-flex-center item-row__leave">
                  <multiselect v-model="shiftData.shiftFrom"
                              track-by="id"
                              label="name"
                              :options="shiftData.shiftList"
                              :searchable="false"
                              :close-on-select="true"
                              :show-labels="false"
                              :allow-empty="false"
                              placeholder="Chọn ca">
                    <template slot="singleLabel" slot-scope="{ option }">
                      <span>{{ option.name }}</span>
                    </template>
                  </multiselect>
                  <div class="input-wrapper ml-20px">
                    <md-icon>calendar_month</md-icon>
                    <datetime type="date" format="EEEE, dd/MM/yyyy" v-model="shiftData.dateFrom" title="Nhập ngày" class="border-none">dd/MM/yyyy</datetime>
                  </div>
                </div>
              </div>

              <div class="item-row">
                <div class="label">
                  <label class="required">Kêt thúc</label>
                </div>
                <div class="d-flex-center item-row__leave">
                  <multiselect v-model="shiftData.shiftTo"
                              track-by="id"
                              label="name"
                              :options="shiftData.shiftList"
                              :searchable="false"
                              :close-on-select="true"
                              :show-labels="false"
                              :allow-empty="false"
                              placeholder="Chọn ca">
                    <template slot="singleLabel" slot-scope="{ option }">
                      <span>{{ option.name }}</span>
                    </template>
                  </multiselect>
                  <div class="input-wrapper ml-20px">
                    <md-icon>calendar_month</md-icon>
                    <datetime type="date" format="EEEE, dd/MM/yyyy" v-model="shiftData.dateTo" title="Nhập ngày" class="border-none">dd/MM/yyyy</datetime>
                  </div>
                </div>
              </div>
              </template>
            </template>
            <!-- Upload file -->
            <div class="item-row">
                <div class="label">
                  <label class="required">Tài liệu đính kèm</label>
                  <div class="sub-label">Ảnh chụp email đã được quản lý xác nhận hoặc các tài liệu liên quan khác</div>
                </div>
                <div class="input upload-file">
                  <input ref="fileUpload" @change="ChangeInputFile($event)" type="file" multiple hidden>
                  <div class="btn btn-blue float-right" @click="() => $refs.fileUpload.click()">Tải lên tệp tin</div>
                  <!-- <md-field class="d-none2">
                    <md-file :ref="uploadfile" readonly v-model="fileMedia" multiple @md-change="ChangeInputFile" placeholder="Tải lên tập tin" />
                  </md-field> -->
                </div>
              </div>
              <div class="item-row-media" v-if="mediaFiles.length > 0">
                <div class="file-preview" v-for="(item, index) in mediaFiles" :key="index">
                  <!-- <pre>{{ item }}</pre> -->
                    <div class="icon-remove-file" title="Xóa file" @click="removeFile(item, index)">
                      <md-icon>close</md-icon>
                    </div>
                    <template v-if="item.media_type === 'image' && item.is_image">
                    <img class="image-file" :src="item.thumbnails.small_thumb" alt="" />
                  </template>
                  <template v-else-if="item.media_type === 'video'" >
                    <video class="file-img" style="margin: auto">
                      <source :src="item.path">
                      Sorry, your browser doesn't support embedded videos.
                    </video>
                  </template>
                  <template v-else>
                    <div class="fileData">
                      <div class="file-thumb">
                        <md-icon>description</md-icon>
                      </div>
                      <div class="file-content">
                        <div class="heading-400 filename">{{ item.filename }}</div>
                      </div>
                    </div>
                  </template>
                  </div>
              </div>
            <!-- Upload file -->
          </div>
        </md-card-content>
        <div class="button-wrapper">
          <div class="button btn-cancel" @click="$modal.hide('create-leave-request-modal')">
            Hủy bỏ
          </div>
          <div class="button btn-success" @click="createLeaveRequest">
            <vue-element-loading :active="isLoadingBtn" spinner="bar-fade-scale"/>
            Lưu lại
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import {Datetime} from "vue-datetime";
import {helpers} from "@/helper/helpers";
import moment from "moment";
import EmployeeService from "@/store/services/EmployeeService";
import TimeSheetService from "@/store/services/TimeSheetService";
import FileMediaService from "@/store/services/FileMediaService";

import {LEAVE_TYPE} from "../../../../const/const";

export default {
  name: 'create-leave-request-modal',
  computed: {
    userNameWithTeam() {
      return this.getNameUserWithTeam();
    }
  },
  components: {datetime: Datetime},
  data: () => ({
    isLoadingBtn: false,
    leaveRequest: {
      name: '',
      reason: '',
      ts_leave_category_id: '',
      use_decision_ids: [],
      request_data: {},
      type: null,
      status: 3,
      role: [14, 7],
      file_ids: []
    },
    fileMedia: null,
    requestGroup: {},
    shiftData: {
      shiftFrom: '',
      dateFrom: '',
      shiftTo: '',
      dateTo: '',
      shiftList: [{id: 1, name: 'Ca sáng'}, {id: 2, name: 'Ca chiều'}]
    },
    dateTime: null,
    customer: {
      value: null,
      list: []
    },
    typeLabel: {
      1: 'Đơn nghỉ phép',
      2: 'Xin về sớm',
      3: 'Kháng cáo bảng công',
      4: 'Nghỉ có công',
    },
    shifts: {
      value: '',
      list: [
        {id: 1, name: 'Giờ checkin'},
        {id: 2, name: 'Giờ checkout'},
      ]
    },
    LEAVE_TYPE: LEAVE_TYPE,
    roles: [],
    requestDataRarlyTime: '',
    currentTime: '',
    requestTime: '',
    mediaFiles: [],
    waitingUploadFile: false
  }),
  mounted() {
    this.getEmployees();
    this.getRoles();
  },
  created() {
  },
  watch: {
    // Chỉ áp dụng cho tạo đơn đề xuất (nghỉ có công)
    'dateTime'(newV){
      if(this.leaveRequest.type === LEAVE_TYPE.VALUABLE_VACATION){
        this.shiftData.dateFrom = newV;
        this.shiftData.dateTo = newV;

        this.shiftData.shiftFrom = this.shiftData.shiftList[0];
        this.shiftData.shiftTo = this.shiftData.shiftList[1];
      }
    }
  },
  methods: {
    beforeOpened(event) {
      this.resetData();
      this.requestGroup = event.params.requestGroup;
      this.leaveRequest.ts_leave_category_id = this.requestGroup.id;
      this.leaveRequest.type = event.params.type;
      this.customer.value = null;
      this.addFieldRequestData();
      this.getNameUserWithTeam();
    },
    async createLeaveRequest() {
      // wait...
      if(this.isLoadingBtn){
        return;
      }
      this.isLoadingBtn = true;
      if (this.waitingUploadFile) {
        this.$store.dispatch("alerts/info", "Vui lòng đợi upload file hoàn thành");
        this.isLoadingBtn = false;
        return;
      }
      if (!this.validateForm()) {
        this.isLoadingBtn = false;
        return;
      }
      // this.leaveRequest.use_decision_ids = this.customer.value.map(item => item.id);
      this.leaveRequest.use_decision_ids = [806, 119];
      let params = {...this.leaveRequest};

      await TimeSheetService.createLeaveRequest(params).then(res => {
        if (res && res.status) {
          this.$store.dispatch("alerts/success", 'Bạn đã tạo đề xuất thành công');
          this.$emit("saved");
          this.$modal.hide('create-leave-request-modal')
        }
      });
      this.isLoadingBtn = false;
    },
    getRoles(){
      TimeSheetService.getRoles({}).then(res => {
        console.log('get roles', res);
      });
    },
    validateForm() {
      let isValid = true;
      // Đơn xin nghỉ
      if(this.leaveRequest.type === LEAVE_TYPE.LEAVE_APPLICATION || this.leaveRequest.type === LEAVE_TYPE.VALUABLE_VACATION){
        if (helpers.isEmpty(this.leaveRequest.name)) {
          this.$store.dispatch("alerts/error", 'Bạn chưa nhập tiêu đề');
          return false;
        }
        if (helpers.isEmpty(this.shiftData.shiftFrom)) {
          this.$store.dispatch("alerts/error", 'Bạn chưa chọn ca bắt đầu');
          return false;
        }
        this.leaveRequest.request_data.shift_from = this.shiftData.shiftFrom.id;
        if (helpers.isEmpty(this.shiftData.shiftTo)) {
          this.$store.dispatch("alerts/error", 'Bạn chưa chọn ca kết thúc');
          return false;
        }
        this.leaveRequest.request_data.shift_to = this.shiftData.shiftTo.id;

        if (helpers.isEmpty(this.shiftData.dateFrom)) {
          this.$store.dispatch("alerts/error", 'Bạn chưa chọn ngày bắt đầu');
          return false;
        }
        if (helpers.isEmpty(this.shiftData.dateTo)) {
          this.$store.dispatch("alerts/error", 'Bạn chưa chọn ngày kết thúc');
          return false;
        }
        if (!this.validateTwoDateRequest()) {
          this.$store.dispatch("alerts/error", 'Ngày kết thúc phải lớn hơn ngày bắt đầu');
          return false;
        }
        if(this.leaveRequest.type === LEAVE_TYPE.VALUABLE_VACATION && helpers.isEmpty(this.dateTime)) {
          this.$store.dispatch("alerts/error", 'Chưa chọn ngày');
          return false;
        }
      }

      // if (this.customer.value === null || this.customer.value.length === 0) {
      //   this.$store.dispatch("alerts/error", 'Bạn chưa chọn người phê duyệt');
      //   return false;
      // }

      if (helpers.isEmpty(this.leaveRequest.reason)) {
        this.$store.dispatch("alerts/error", 'Bạn chưa nhập Lý do cụ thể');
        return false;
      }

      if (this.leaveRequest.file_ids.size === 0) {
        this.$store.dispatch("alerts/error", 'Bạn phải upload tài liệu đính kèm');
        return false;
      }

      if(this.leaveRequest.type === LEAVE_TYPE.LEAVE_EARLY){
        if (helpers.isEmpty(this.requestDataRarlyTime)) {
          this.$store.dispatch("alerts/error", 'Bạn chưa nhập thời gian dự kiến checkout');
          return false;
        }
        this.leaveRequest.request_data.time = moment(this.requestDataRarlyTime).format('yyyy/MM/DD HH:mm:ss');
      }

      if(this.leaveRequest.type === LEAVE_TYPE.ADJUSTMENT_OF_ATTENDANCE){

      }


      return isValid;
    },
    validateTwoDateRequest() {
      let fromDate = new Date(this.shiftData.dateFrom);
      let toDate = new Date(this.shiftData.dateTo);
      // console.log(fromDate)
      this.leaveRequest.request_data.date_from = moment(fromDate).format('yyyy-MM-DD');
      this.leaveRequest.request_data.date_to = moment(toDate).format('yyyy-MM-DD');
      if (toDate < fromDate) {
        return false;
      }
      return true;
    },
    resetData() {
      this.isLoadingBtn = false;
      this.leaveRequest = {
        name: '',
        reason: '',
        ts_leave_category_id: '',
        use_decision_ids: [],
        request_data: {},
        type: null,
        status: 3,
        role: [14, 7],
        file_ids: []
      };
      this.shiftData = {
        shiftFrom: '',
        dateFrom: '',
        shiftTo: '',
        dateTo: '',
        shiftList: [{id: 1, name: 'Ca sáng'}, {id: 2, name: 'Ca chiều'}]
      };

      this.mediaFiles = [];
      this.leaveRequest.file_ids = [];
      this.waitingUploadFile = false;
    },
    async getEmployees(keyword = "") {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        let params = {
          page: 1,
          per_page: 20,
          keyword: keyword
        }
        let dataEmployee = new Set();
        EmployeeService.getEmployees(params).then(res => {
          if (res && res.data) {
            res.data.forEach(item => dataEmployee.add(item));
            this.customer.list = Array.from(dataEmployee);
          }
        });
      }, 800);
    },
    getNameForUser(user) {
      return user.display_name ? user.display_name : (user.fullname ? user.fullname : '');
    },
    removeCustomerSelected(index) {
      console.log(index);
      this.customer.value.slice(index, 1)
    },
    getNameUserWithTeam(){
      let mySelf = this.$store.state.auth.user;
      let name = mySelf.fullname;
      if(mySelf.team.team){
        name += ` - ${mySelf.team.team}`;
      } else if(mySelf.team.department){
        name += ` - ${mySelf.team.team}`;
      } else if(mySelf.team.agency){
        name += ` - ${mySelf.team.agency}`;
      }

      name += ` - ${this.typeLabel[this.leaveRequest.type]}`;
      this.leaveRequest.name = name;
      return name;
    },
    addFieldRequestData(){
      const typeRequest = this.leaveRequest.type;
      if(typeRequest === LEAVE_TYPE.LEAVE_APPLICATION || typeRequest === LEAVE_TYPE.VALUABLE_VACATION){
        this.leaveRequest.request_data = {
          shift_from: '',
          shift_to: '',
          date_from: '',
          date_to: '',
        };
        return;
      }

      if(typeRequest === LEAVE_TYPE.LEAVE_EARLY){
        this.leaveRequest.request_data = {
          time: '' // datetime
        };
        return;
      }

    },

    removeFile(item, index){
      this.mediaFiles.splice(index, 1);
      this.leaveRequest.file_ids.splice(index, 1);

      if(this.mediaFiles.length === 0){
        this.$refs.fileUpload.value = '';
      }
    },

    async ChangeInputFile(e)
    {
      let event = e.target.files;
      if(event.length === 0) return;

      const formFile = new FormData();
      this.waitingUploadFile = true;
      for (let index = 0; index < event.length; index++) {
        const elm = event[index];
        let imageTypes = ['image/jpeg', 'image/png', 'image/gif'];
        let videoTypes = ['video/mp4', 'video/mpeg', 'video/quicktime'];

        let is_image = false;
        let media_type = 'file';
        if (imageTypes.includes(elm.type) || ['jpg', 'jpeg', 'png', 'gif'].includes(elm.extension)) {
          is_image = true;
          media_type = 'image';
        } else if (videoTypes.includes(elm.type) || ['mp4', 'mpeg', 'mov'].includes(elm.extension)) {
          media_type = 'video';
        }
        const file_url = URL.createObjectURL(elm);
        this.mediaFiles.push(
          {
            "lastModified": elm.lastModified,
            "lastModifiedDate": elm.lastModifiedDate,
            "extension": elm.name.split('.').pop(),
            "mimetype": elm.type,
            "is_image": is_image,
            "filename": elm.name,
            "media_type": media_type,
            "url": file_url,
            "path" : file_url,
            "thumbnails": {
              "medium_thumb" : file_url,
              "small_thumb" : file_url,
            }
          }
        );

        formFile.append("files[]", elm);
      }

      await FileMediaService.uploadFile(formFile).then(res => {
        if(res && res.data){
          setTimeout(() => {
            this.mediaFiles = res.data;
            res.data.forEach((item) => this.leaveRequest.file_ids.push(item.id));
          }, 1000);
        }else{
          this.mediaFiles = [];
          this.leaveRequest.file_ids = [];
          this.fileMedia = null;
          this.$store.dispatch("alerts/error", 'Đã có lỗi xảy ra! Vui lòng thử lại!');
        }
      });
      formFile.delete("files[]");
      this.waitingUploadFile = false;
    }
  }
}
</script>

<style lang="scss">
.create-leave-request-modal__container {
  .required:after{
    content: ' *';
    //color: initial !important;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    background-color: white;
    height: 40px;
    border: 1px solid #d3d3d3;
    border-radius: 6px;
    font-size: 14px;
    input {
      border: none;
      height: 100%;
      width: 100%;
      background: transparent;
    }
    .md-icon {
      margin: 0 10px;
    }
  }

  .multiselect--above {
    .multiselect__content-wrapper {
      top: 100% !important;
      bottom: initial !important;
    }
  }

  .modal-content {
    padding-bottom: 20px;
  }

  .type-time-off-info {
    margin-top: 2px;
    background: rgba(255,152,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.05);
    padding: 15px 15px;
    font-size: 14px;
    color: #804b00;
    line-height: 20px;
    text-align: left;
  }

  .request-group-info {
    margin-top: 10px;
    padding: 15px 15px;
    background: rgba(66,184,20,0.1);
    border-top: 1px solid rgba(0,0,0,0.05);
    border-bottom: 1px solid rgba(0,0,0,0.05);
    font-size: 14px;
    color: #1f6105;
    line-height: 20px;
    text-align: left;
  }

  .leave-request__form {
    padding: 0 15px;
    height: 550px;
    overflow-y: auto;
    .label {
      font-weight: 500;
      font-size: 14px;
      width: 180px;
      padding: 0;
      margin-bottom: 4px;
      .sub-label {
        font-weight: normal;
        font-size: 12px;
        color: #999;
        text-align: justify;
        padding-top: 2px;
        line-height: normal;
      }
    }
    .item-row {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      margin-top: 20px;
      .input {
        width: 500px;
        max-height: 35px;
      }
      .input-fake {
        display: block;
        font-size: 14px;
        border: 1px solid #d3d3d3;
        padding: 6px 8px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        background-color: #eee;
      }
      .input-button {
        cursor: pointer;
      }
      .item-row__leave {
        .multiselect {
          width: 140px;
        }
      }
      input {
        border-top: 1px solid #d3d3d3;
        border-right: 1px solid #ccc;
        border-left: 1px solid #d3d3d3;
        border-bottom: 1px solid #ccc;
        border-radius: 3px;
        padding: 6px 8px;
        font-size: 15px;
      }
      textarea {
        overflow: hidden;
        overflow-wrap: break-word;
        height: 80px;
        display: block;
        border-top: 1px solid #d3d3d3;
        border-right: 1px solid #ccc;
        border-left: 1px solid #d3d3d3;
        border-bottom: 1px solid #ccc;
        padding: 6px 8px;
        border-radius: 3px;
        width: 500px;
        font-size: 15px;
        resize: none;
      }
      input:focus, textarea:focus {
        border-color: rgba(66,184,20,0.7);
      }
      .choosing-shift {
        font-size: 14px;
        color: #6d6e6f;
      }
      .day-time-adjustment-of-attendance{
        .input-wrapper{
          width: 100%;
        }
        .multiselect{
          width: 250px;
          .multiselect__tags{
            padding: 0 40px 0 8px;
            display: flex;
            align-items: center;
          }
          .multiselect__placeholder, .multiselect__single{
            margin: 0;
          }
        }
      }
      .upload-file{
        .md-file-icon{
          display: none;
        }
        .md-input, .md-field{
          margin: 0;
        }
      }
    }
    .item-row-media{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px;
      margin-top: 20px;
      .icon-remove-file{
        &:hover{
          cursor: pointer;
        }
        width: 24px;
        height: 24px;
        position: absolute;
        background: #b9b9b9;
        top: 5px;
        right: 5px;
        border-radius: 100%;
        font-size: 10px;
      }
      .file-preview {
        width: 100%;
        height: 150px;
        object-fit: contain;
        overflow: hidden;
        background: #f7f5f5;
        border-radius: 5px;
        text-align: center;
        border: 1px solid #e5e3e3;
        position: relative;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .fileData {
          height: 100%;
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }

  .ml-search-container {
    padding: 10px 15px 10px 18px;
    border-bottom: 2px solid #EDEFF5;

    input {
      border: none;
      font-size: 16px;
      margin-left: 5px;
      width: 90%;
    }

    .md-icon {
      margin: auto !important;
    }
  }

  .button-wrapper {
    padding-top: 30px;
    border-top: 1px dashed #ddd;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin: 20px 20px 0;
    .button {
      padding: 7px 0px;
      width: 48%;
      cursor: pointer;
      text-align: center;
      border-radius: 3px;
    }
    .btn-cancel {
      background-color: #ddd;
      color: #666;
    }
    .btn-success {
      background-color: #42b814;
      color: #fff;
    }
    .btn-success:hover {
      background-color: #3ba710;
    }
  }
}
</style>
