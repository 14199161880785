<template>
  <div class="choosing-request-group-modal__container">
    <modal name="choosing-request-group-modal"
           :click-to-close="false"
           width='740px'
           height="auto"
           class="modal-custom"
           transition="easeInOut"
           @before-open="beforeOpened"
    >
      <div class="modal-content">
        <md-card class="modal-header-custom">
          <md-dialog-title>
            <span class="heading-500">TÌM NHÓM ĐỀ XUẤT</span>
            <span class="material-icons float-right cursor-pointer"
                  @click="$modal.hide('choosing-request-group-modal')">close</span>
          </md-dialog-title>
        </md-card>

        <md-card-content>
          <div class="ml-search-container">
            <md-icon>search</md-icon>
            <input class="input-search-field" type="text" placeholder="Tìm nhanh" name="search-group" v-model="keyword" @keyup="searchLeaveCategory">
          </div>
          <div class="request-list">
            <div class="request-item" v-for="item in leaveCategoriesSearched" :key="'category_id' + item.id" @click="choseRequestGroup(item)">
              <div class="text-400-medium">
                {{item.name}}
              </div>
              <div class="text-300-default neutral_2" style="font-size: 13px !important;" v-html="item.description">
              </div>
            </div>
          </div>
        </md-card-content>
      </div>


    </modal>
  </div>
</template>

<script>

import TimeSheetManagementService from "@/store/services/TimeSheetManagementService";

export default {
  name: 'choosing-request-group-modal',
  computed: {},
  data: () => ({
    leaveCategoriesSearched: [],
    leaveCategories: [],
    keyword: '',
    type: null
  }),
  created() {
    this.getLeaveCategories();
  },
  methods: {
    beforeOpened(event) {
      this.type = event.params.type;
      this.leaveCategoriesSearched = this.leaveCategoriesSearched.filter((item) => item.type === this.type);
      this.keyword = '';
    },
    async getLeaveCategories() {
      await TimeSheetManagementService.getLeaveCategories().then(res => {
        this.leaveCategories = res.data;
        this.leaveCategoriesSearched = res.data
        this.$emit('leaveCategories', res.data);
      });
    },
    choseRequestGroup(group) {
      this.$modal.hide('choosing-request-group-modal');
      this.$modal.show('create-leave-request-modal', {requestGroup: group, type: this.type})
    },
    searchLeaveCategory() {
      if (this.keyword.length === 0) {
        this.leaveCategoriesSearched = this.leaveCategories;
      }
      this.leaveCategoriesSearched = this.leaveCategories.filter(item => item.type === this.type && item.name.toLowerCase().includes(this.keyword.toLowerCase()));
    }
  }
}
</script>

<style lang="scss">
.choosing-request-group-modal__container {
  .ml-search-container {
    padding: 10px 15px;
    border-bottom: 1px solid #EDEFF5;

    input {
      border: none;
      font-size: 16px;
      margin-left: 5px;
      width: 90%;
    }

    .md-icon {
      margin: auto !important;
    }
  }

  .request-list {
    max-height: 705px;
    overflow: auto;

    .request-item {
      padding: 10px 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      &:hover {
        background-color: #f6f6f6;
      }
    }
  }
}
</style>
